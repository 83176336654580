import { useCallback, useState } from "react"
import { GetPlanos } from '../services/Planos';
import { Planos } from '../interfaces/planos';


const Planoh = () => {
    const [planosLista, setPlanosLista] = useState<Planos[]>([]);
    const [ativosLista, setAtivosLista] = useState<Planos[]>([]);
    //const [ativosListaOrder, setAtivosListaOrder]
    
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetPlanos.getAll();
        const ordenado = data.sort(function(a,b){return a.codigoTipoVenda < b.codigoTipoVenda ? -1: a.codigoTipoVenda > b.codigoTipoVenda? 1:0})


        if(status !== 200) throw new Error();

        setPlanosLista(ordenado) 
    
    },[]);

    const getAllAtivos = useCallback(async () => {
        const { status, data } = await GetPlanos.getAllAtivos();
        const ordenado = data.sort(function(a,b){return a.codigoTipoVenda < b.codigoTipoVenda ? -1: a.codigoTipoVenda > b.codigoTipoVenda? 1:0})
        console.log('chegando 3m 321')
        console.log(ordenado)


        if(status !== 200) throw new Error();

        setAtivosLista(ordenado) 
    
    },[]);


    return {
        planosLista,
        ativosLista,
        getAll,
        getAllAtivos,

    }
}

export { Planoh }
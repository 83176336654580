import styled from "styled-components";

export const Container = styled.div`

   //width: 1400px;
   margin: none;
   padding: 24px;

   .teste{
       align-items: right;
   }

   @media screen and (max-width:512px) {

.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root{
    margin: 0 0;
    min-width: 35px;
    height: 35px;
    border-radius: 17.5px;
  }

}

   .CampoTipo{
     min-width: '200px'; 
     max-width: '250px'
   }

   .labelBem{
     margin-bottom: 30px;
   }

   .botaoPesquisar{
     display: flex;
     justify-content: flex-end;
     width: 100%;
     margin-bottom: 30px;
   }

   .btnPesquisar{
     background-color: var(--blue);
     width: 120px;
     height: 45px;
     font-size: 20px;
     margin-left: 15px;
   }


   .inputNovo{
     width: 100%
   }

   .tituloBemSel{
     width: 100%;
     margin-top: 90px;
     height: 40px;
     color: var(--blue);
     //background-color: var(--blue);
     text-align: center;
      align-items: center;
      vertical-align: middle;

      spam{

      }
   }

   .bemSelecionado{
     border: solid #ECECEC 0.8px;
     padding: 20px;
     margin-top: 60px;

   }

   .tituloBem{
     margin-top: 10px;
     margin-bottom: 20px;
   }

   .direita1{
     display: flex;
     //flex-direction: row;
     //align-items: right;
     float: right;
     align-content: flex-end;
    
   }


    .labelRenda{
      .inputRenda{
      border: 0;
      border-bottom: solid 0.5px #212121;
      font-size: 18px;
      margin-top: 18px;
      position: absolute;


      :focus{
        border: 0;
        border-bottom: solid 0.5px #212121;
        font-size: 18px;
        position: absolute;
      }
    }
    }

`;


export const Tabela = styled.div`


`;


export const Tabelas1 = styled.div`
    max-width: 1100px;
    font-size: 13px;
    //border-left: solid 0.5px;
    //border-left-color: #eeebeb;
    //border-right: solid 0.5px;
    //border-right-color: #eeebeb;
    //box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

   .title1{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

    .semSeguro{
        font-size: 10px;
        color: #b03232;
    }

    .MuiSvgIcon-Root {
        color: var(--blue);
      background-color: #b03232;
    }

    .comSeguro{
        font-size: 10px;
        color: var(--blue);
    }

    .meses{
        border: none;
    }

    .nome{
        max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    }

    th, td{
        border: none;

    }

    td, th{
       white-space: nowrap;
       padding: 0;

    }

    .quebraLinha{
        white-space: nowrap;
    }

    .ac{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       padding-right: 10px;
    }

    .ac1{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }

    .ac2{
       text-align: left;
       align-items: center;
       vertical-align: middle;
    }

    .tamanhoAC{
      //width: 100px;
       height: 40px;
       padding: none;
    }

   
   

   .tabela{
     vertical-align: center;
     
   }

   .celula{
      display: flex;
      align-items: center;

   }

   .del{
       cursor: pointer;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
      border-bottom: none;
   }

   .esquerda{
       padding-left: 60px;
   }


   .esquerda1{
       padding-left: 20px;
   }

   .mouse{
     cursor: pointer;
   }

   .bord{
      background-color: #f8f8f8;
      //color: red;
   }

   .marg{
     margin-bottom: 10px;
   }

   .tam1{
     width: 50px;
   }

   .tam1i{
     width: 100px;
   }

   .tam2{
     width: 200px;
   }

   .tam2i{
     width: 210px;
   }

   .tam3{
     width: 700px;
   }

   .fontTI{
     font-size: 12px;
   }

   .n{
    font-weight: bold;
    font-size: 14px;
   }

   .tam{
    font-size: 14px;
    
   }

   .redm{
     display: flex;

     justify-content: space-around;
   }

   .testea{
     display: flex;
     margin-top: 50px;
     justify-content: flex-end;
     margin-right: 10px;

   }

   .paginacao{
     display: flex;
     justify-content: center;
     margin-top: 5px;
     margin-bottom: 5px;
   }

   

`;
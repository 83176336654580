import api from './api';
import { CotaAssembleiaCard } from '../interfaces/cotaAssembleiaCard';
import { CotaAssembleia } from '../interfaces/cotaAssembleia';
import { FiltrosAssembleia } from '../interfaces/filtrosAssembleia';

const getAll = (filtros:FiltrosAssembleia) => api.get<CotaAssembleiaCard[]>(`/v2/lances-ofertados-manuais/filtro/cards?codigoGrupo=${filtros?.codigoGrupo||""}&dataAssembleiaInicial=${filtros.dataAssembleiaInicial}&dataAssembleiaFinal=${filtros.dataAssembleiaFinal}&somenteDataLiberada=${filtros.somenteDataLiberada}`)

const getAllItems = (dataAssembleia: string, liberado: boolean) => api.get<CotaAssembleia[]>(`/v2/lances-ofertados-manuais/filtro?somente_data_liberada=${liberado}&data_assembleia=${dataAssembleia}`)

const deleteCota = (id: number) => api.delete(`/v2/lances-ofertados-manuais/${id}`)

export const GetCotasAssembleia = {
    getAll,
    getAllItems,
    deleteCota,
}
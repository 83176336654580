import { useCallback, useState } from "react"
import { GetCotasAssembleia } from '../services/CotaAssembleia';
import { CotaAssembleiaCard } from '../interfaces/cotaAssembleiaCard';
import { CotaAssembleia } from "../interfaces/cotaAssembleia";
import { FiltrosAssembleia } from '../interfaces/filtrosAssembleia';

interface OrdenarGrupo
{	
    codigoGrupo: number,
		tiposLance: [
			{
				tipoLance: string,
				lances: [
					{
						id: number,
						dataAssembleia: string,
						percentualLance: number,
						codigoCota: number
					}
				]
			}
        ]
	}

    interface OrdenarPercentual
    {
        id: number,
        dataAssembleia: string,
        percentualLance: number,
        codigoCota: number
    }


const CotasAssembleia = () => {
    const [cotasAssembleiaListaCard, setCotasAssembleiaListaCard] = useState<CotaAssembleiaCard[]>([]);
    const [cotasAssembleiaLista, setCotasAssembleiaLista] = useState<CotaAssembleia[]>([]);

    function ordernarGrupo(dados:[OrdenarGrupo]) {
        dados.sort(function(a,b){return a.codigoGrupo < b.codigoGrupo ? -1: a.codigoGrupo > b.codigoGrupo? 1:0})
        console.log('Ordenar Grupo')
        return dados
       
      }

      function ordernarCota(dados:[OrdenarPercentual]) {
        console.log('Ordenar Cota')
        dados.sort(function(a,b){return a.percentualLance > b.percentualLance ? -1: a.percentualLance > b.percentualLance? 1:0})
        return dados
       
      }

      function ordernarTipoLance(dados:CotaAssembleia[]) {
		const sorteio = dados.filter(item => item.tipoLance==="SORTEIO")
		const livre = dados.filter(item => item.tipoLance==="LIVRE")
		const fixo = dados.filter(item => item.tipoLance==="FIXO")
		const livreOfertado = dados.filter(item => item.tipoLance==="LANCE_OFERTADO_LIVRE")
		const fixoOfertado = dados.filter(item => item.tipoLance==="LANCE_OFERTADO_FIXO")
		const ordenadoFinal = sorteio.concat(livre).concat(fixo).concat(livreOfertado).concat(fixoOfertado)
		console.log('dentro da função de ordenação tipo')
		console.log(ordenadoFinal)

return ordenadoFinal

}

    

    const getAll = useCallback(async (filtros: FiltrosAssembleia) => {
        const { status, data } = await GetCotasAssembleia.getAll(filtros);
        const ordenado = data.sort(function(a,b){return a.dataAssembleia > b.dataAssembleia ? -1: a.dataAssembleia < b.dataAssembleia? 1:0})
        ordenado.map(item => 
            ordernarGrupo(item.grupos).map(itemGrupo => 
                itemGrupo.tiposLance.map(itemCotaLance => 
                    ordernarCota(itemCotaLance.lances)
                )
            ))
        
            console.log(ordenado)

        if(status !== 200) throw new Error();

        setCotasAssembleiaListaCard(ordenado) 
        console.log('verificar ordenacao')
        console.log(ordenado)
    
    },[]);

    const getAllItems = useCallback(async (dataAssembleia: string, liberado: boolean) => {
        const { status, data } = await GetCotasAssembleia.getAllItems(dataAssembleia, liberado);
        const ordenado = data.sort(function(a,b){return a.percentualLance > b.percentualLance ? -1: a.percentualLance < b.percentualLance? 1:0})
        ordenado.sort(function(a,b){return a.codigoGrupo < b.codigoGrupo ? -1: a.codigoGrupo > b.codigoGrupo? 1:0})
        const teste = ordernarTipoLance(ordenado)
        


        if(status !== 200) throw new Error();

        setCotasAssembleiaLista(teste) 
    
    },[]);

    const deleteCotas = useCallback(async (id: number) => {
        const { status, data } = await GetCotasAssembleia.deleteCota(id);
        if(status !== 204) throw new Error();
        return status
    },[])

    return {
        cotasAssembleiaListaCard,
        cotasAssembleiaLista,
        deleteCotas,
        getAll,
        getAllItems
    }
}

export { CotasAssembleia }
import React, { ChangeEvent, useState, useEffect, useCallback } from "react";
import { Form, Button, Col, Table } from 'react-bootstrap';
import { Container, Tabelas1 } from './styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputLabel from '@mui/material/InputLabel';
import { ConsultaUsuario } from '../../../interfaces/usuarioConsulta';
import { Subordinados } from '../../../interfaces/subordinados';
import { ClienteNewconSituacaoCobranca } from "../../../interfaces/clienteNewconSituacaoCobranca";
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import { DocumentosBloqueado } from '../../../interfaces/documentosBloqueados';
import { BensVendedor } from '../../../interfaces/bensVendedor';
import { BensVendaDetalhe } from '../../../interfaces/bensVendaDetalhe'
import Pagination from '@mui/material/Pagination';
import usePagination from '../../../components/PaginationBensVenda';
import { BensVenda } from '../../../interfaces/bensVenda'
import Modal from '@mui/material/Modal';
import { FiArrowDown, FiArrowUp, FiX, FiEdit, FiEdit2, FiEdit3 } from 'react-icons/fi';
import { VendaBemDetalhe } from "../../../interfaces/vendaBemDetalhe";
import { RendaNewcon } from "../../../interfaces/rendaNewcon";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import * as CurrencyFormat from 'react-currency-format';
import NumberFormat from "react-number-format";
import { KeyboardEvent } from "react";
import { dadosPreVenda } from '../../../mock/dadosPreVenda';
import { PreVenda } from '../../../interfaces/preVenda';
import { SequenciaCard } from '../../../interfaces/sequenciaCard';
import { Tabelas } from '../../../hooks/tabelas';
import { Row } from "../styles";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';


const numero = require('numero-por-extenso');

interface bemSimples {
  codigoGrupo: number,
  codigoGrupoBem: number,
  credito: number,
  dataAssembleia: string,
  descricaoBem: string,
  idBem: number,
  idTabelaVendas: number,
  mediaLance: number,
  numeroMaximoCotas: number,
  parcela: number,
  parcelaSeguro: number,
  planoBasico: number,
  prazo: number,
  taxa: number,
}

interface tipoBem {
  codigoGrupoBem: number;
  descricao: string;
}

const styleBox = {
  // position: 'absolute' as 'absolute',
  overflow: 'scroll',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  alignSelf: 'center',
  maxWidth: 800,
  width: '100%',
  maxHeight: 500,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 2,
  p: 2,

  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
};

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}

      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator

      prefix={"R$ "}
      format={currencyFormatter}
    />
  );
}

function currencyFormatter(value: any) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return amount

}





function valuetext(value: number) {
  return `${value}asdfasdfsdf`;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 9,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 'none',


        }}
      >
        <Typography
          variant="caption"
          component="div"

          color="var(--blue)"
          fontSize={9}
        >{`${(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const fisica = {
  nomePrincipal: 'Nome',
  nomeSecundario: 'Sobrenome',
  data: 'Data de Nascimento',
  documentoPrincipal: 'CPF',
  mascaraPessoa: '999.999.999-99',
  documentoSecundario: 'RG',
  renda: "Renda Mensal",
  mascaraDinheiro: '#9.999,99'
}

const juridica = {
  nomePrincipal: 'Razão Social',
  nomeSecundario: 'Nome Fantasia',
  data: 'Data Constituição da Empresa',
  documentoPrincipal: 'CNPJ',
  mascaraPessoa: '99.999.999/9999-99',
  documentoSecundario: 'Inscrição Municipal ou Estadual',
  renda: "Faturamento Mensal",
  mascaraDinheiro: '999'
}

export function Etapa1_Plano(props: any) {

  const { addToast } = useToast();
  const setDuplicados = new Set();
  const nome = localStorage.getItem('@Gscon:user')
  const [usuarios, setUsuarios] = useState<ConsultaUsuario[]>([]);
  const [usuario, setUsuario] = useState<ConsultaUsuario>();
  const [subordinados, setSubordinados] = useState<Subordinados[]>([]);
  const { DadosVenda, setAlteraDados, setAlteraPagina } = props
  const [bensPorVendedor, setBensPorVendedor] = useState<BensVendedor[]>([]);
  const [bensPorVendedor1, setBensPorVendedor1] = useState<BensVenda[]>([]);
  const [bensPorVendedorfiltro, setBensPorVendedorfiltro] = useState<BensVenda[]>([]);
  const [grupos, setGrupos] = useState<number[]>([]);
  const [valorBens, setValorBens] = useState<BensVenda[]>([])
  const [maiorValorBem, setMaiorValorBem] = useState<number>(0)
  const [menorValorBem, setMenorValorBem] = useState<number>(0)
  const [maiorPrazoBem, setMaiorPrazoBem] = useState<number>(0)
  const [menorPrazoBem, setMenorPrazoBem] = useState<number>(0)
  const [listaSearchBem, setListaSearchBem] = useState<BensVenda[]>(valorBens)
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchBem.length / PER_PAGE);
  const _DATA = usePagination(listaSearchBem, PER_PAGE);
  const [filtroTipo, setFiltroTipo] = useState<number>(0)
  const [filtroGrupo, setFiltroGrupo] = useState<number>(0)
  const [filtroBem, setFiltroBem] = useState<string>("")
  const [idBemSelecionado, setIdBemSelecionado] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);
  const handleClose = () => (setAlteraDados(
    { ...DadosVenda, reducao: 0 }), setOpen(false));
  const [reducaoModal, setReducaoModal] = useState<number[]>([])
  const [bemSelecionado, setBemSelecionado] = useState<BensVendaDetalhe[]>([])
  let listaGeral: BensVenda[];
  const [show, setShow] = React.useState(false);
  const container = React.useRef(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [valorReducao, setValorReducao] = useState<number>(0);
  const [mostrarModal, setMostrarModal] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false)
  const [rendaTodos, setRendaTodos] = useState<RendaNewcon[]>([])
  const [rendaAprovada, setRendaAprovada] = useState<boolean>(false);
  const [mostraMensagem, setMostraMensagem] = useState<boolean>(false);
  const [mensagemRenda, setMensagemRenda] = useState<string>();
  const [parcelaSelecionada, setParcelaSelecionada] = useState<number>(0);
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [selectedValue1, setSelectedValue1] = React.useState('a');
  const { getAllSequenciaCards, sequenciaCards } = Tabelas();
  const [listaCards, setListaCards] = useState<SequenciaCard[]>(sequenciaCards);
  const [ativo, setAtivo] = useState(false);


  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlteraDados({
      ...DadosVenda,
      renda: parseFloat(event.target.value) / 100,
      rendaReal: parseFloat(event.target.value),
      rendaFormatado: (parseFloat(event.target.value) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    })
  };

  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };


  const handleChangeOption1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue1(event.target.value);
  };



  useEffect(() => {
    buscaUsuarios();
    getAllSequenciaCards()

  }, [])

  useEffect(() => {
    buscaDadosUsuario();
  }, [usuarios])



  useEffect(() => {
    listaSubordinados();
    if (DadosVenda.codB > 0) {
      buscaDadosModal(DadosVenda.idTabela, DadosVenda.codB, DadosVenda.prazo)
      setSelectedValue1(DadosVenda.sequenciaAgrupamento)

    }
  }, [usuario])

  useEffect(() => {
    if (usuario !== null && usuario !== undefined) {
      listaBensVendedores();
    }
  }, [subordinados])

  /*useEffect(() => {
    carregaGrupos()
  }, [grupos])*/

  useEffect(() => {
    DadosFiltros()
  }, [valorBens])





  async function buscaUsuarios() {
    const { status, data } = await api.get('/v1/usuarios')
    setUsuarios(data)
  }

  const buscaDados = async () => {
    const { data } = await api.get(`/v1/pre-vendas/documento/${DadosVenda.documento}/ultima-efetivada`)
    console.log('verifica documento')
    console.log(data)
    
    const dadosUnicos: PreVenda[] = data
    const dadosFiltrados = dadosUnicos.filter(item => item.documento === DadosVenda.documento)
    const dadosOrdenados = dadosFiltrados.sort(function (a, b) {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    })

    if (dadosOrdenados.length > 0) {
      const dadoUnico = dadosOrdenados[dadosFiltrados.length - 1]
      const todosDados = JSON.parse(dadoUnico.conteudo)
      setAlteraDados({
        ...DadosVenda,
        nomeCliente: todosDados.nomeCliente,
        dataNascimento: todosDados.dataNascimento,
        sexo: todosDados.sexo,
        documento2: todosDados.documento2,
        orgEm: todosDados.orgEm,
        ufe: todosDados.ufe,
        dataExpedicao: todosDados.dataExpedicao,
        dataExped: todosDados.dataExped,
        nacionalidade: todosDados.nacionalidade,
        naturalUF: todosDados.naturalUF,
        naturalCidade: todosDados.naturalCidade,
        estadoCivil: todosDados.estadoCivil,
        profissao: todosDados.profissao,
        nomeMae: todosDados.nomeMae,
        nomePai: todosDados.nomePai,
        nomeFantasia: todosDados.nomeFantasia,
        capitalSocial: todosDados.capitalSocial,
        inscricaoEstadual: todosDados.inscricaoEstadual,
        cep: todosDados.cep,
        endereco: todosDados.endereco,
        numero: todosDados.numero,
        bairro: todosDados.bairro,
        apto: todosDados.apto,
        complemento: todosDados.complemento,
        uf: todosDados.uf,
        cidade: todosDados.cidade,
        telefone1: todosDados.telefone1,
        telefone2: todosDados.telefone2,
        telefone3: todosDados.telefone3,
        telefone4: todosDados.telefone4,
        email1: todosDados.email1,
        email2: todosDados.email2,
        email3: todosDados.email3,
        email4: todosDados.email4,
        nomeEmpresa: todosDados.nomeEmpresa,
        cepCom: todosDados.cepCom,
        enderecoCom: todosDados.enderecoCom,
        numeroCom: todosDados.numeroCom,
        bairroCom: todosDados.bairroCom,
        aptoCom: todosDados.aptoCom,
        complementoCom: todosDados.complementoCom,
        estadoCom: todosDados.estadoCom,
        cidadeCom: todosDados.cidadeCom,
        telefoneCom1: todosDados.telefoneCom1,
        telefoneCom2: todosDados.telefoneCom2,
        telefoneCom3: todosDados.telefoneCom3,
        telefoneCom4: todosDados.telefoneCom4,
        emailCom1: todosDados.emailCom1,
        emailCom2: todosDados.emailCom2,
        emailCom3: todosDados.emailCom3,
        emailCom4: todosDados.emailCom4,
        informaConta: todosDados.informaConta,
        tipoConta: todosDados.tipoConta,
        codBanco: todosDados.codBanco,
        agencia: todosDados.agencia,
        conta: todosDados.conta,
        digito: todosDados.digito,
        tipoPix: todosDados.tipoPix,
        chavePix: todosDados.chavePix,
        t_p1: todosDados.t_p1,
        t_p2: todosDados.t_p2,
        atividadeRemunerada: todosDados.atividadeRemunerada,
        t_p3: todosDados.t_p3,
        t_p4: todosDados.t_p4,
        t_p5: todosDados.t_p5,
        t_p6: todosDados.t_p6,
        t_p7: todosDados.t_p7,
        valorAluguelPrestacao: todosDados.valorAluguelPrestacao,
        valorA: todosDados.valorA,
        t_p8: todosDados.t_p8,
        t_p9: todosDados.t_p9,
        b1: todosDados.b1,
        b2: todosDados.b2,
        b3: todosDados.b3,
        b4: todosDados.b4,
        b5: todosDados.b5,
        b6: todosDados.b6,
        b7: todosDados.b7,
        b8: todosDados.b8,
        b9: todosDados.b9,
        t_p10: todosDados.t_p10,
        t_p11: todosDados.t_p11,
        t_p12: todosDados.t_p12,
        capitalSocialFormulario: todosDados.capitalSocialFormulario,
        capitalSocialFormularioF: todosDados.capitalSocialFormularioF,
        faturamento: todosDados.faturamento,
        faturamentoF: todosDados.faturamentoF,
        lucroMensal: todosDados.lucroMensal,
        lucroMensalF: todosDados.lucroMensalF,
        isentoChecked: todosDados.isentoChecked,
        age3: todosDados.age3,
        dataNascimentoF: todosDados.dataNascimentoF,
        dtEF: todosDados.dtEF,
        age1: todosDados.age1,
        age5: todosDados.age5,
        autonomo: todosDados.autonomo,
        tempoFuncao: todosDados.tempoFuncao,
        idade: todosDados.idade,
        banco: todosDados.banco,
        bloqueado: false
      })
    }
  }

  async function buscaDadosModal(idTabela: number, idBem: number, prazo: number) {
    let response = await api.get(`/v1/bens-tabela-venda/calculado?idTabela=${idTabela}&idBem=${idBem}&parcela=${prazo}`)
    let grupos: BensVendaDetalhe[] = response.data
    let alinhados = grupos.sort(function compare(a, b) {
      if (a.adesao < b.adesao) return -1;
      if (a.adesao > b.adesao) return 1;
      return 0
    })
    setBemSelecionado(alinhados)
    let reducao = grupos.map(item => item.reducao)
    let reducaoFiltro = reducao.filter(function (item, i) {
      return reducao.indexOf(item) === i;
    })
    setReducaoModal(reducaoFiltro)

    let dataD = Date.now()
    let dataF = new Date(dataD)
    let dataDiaf = (`${dataF.getFullYear()}-${dataF.getMonth() + 1}-${dataF.getDate()}`)
  }

  function buscaDadosUsuario() {
    if (usuarios) {
      const dadosUsuario = usuarios.find(item => item.nome === nome);
      setUsuario(dadosUsuario)
      let secundario = dadosUsuario?.dadoUsuario.tipoPessoa === "PESSOA_FISICA" ? dadosUsuario.dadoUsuario.nomeSecundario : "";
      setAlteraDados({ ...DadosVenda, idVendedor: dadosUsuario?.dadoUsuario.id, nomeVendedor: dadosUsuario?.dadoUsuario.nomePrincipal + ' ' + secundario })
    }
  }

  async function listaSubordinados() {
    if (usuario !== null && usuario !== undefined) {
      if (usuario?.dadoUsuario.cargo?.id !== 3) {
        let { data } = await api.get(`/v1/dados-usuario/${usuario?.dadoUsuario.id}/subordinados`)
        const retiraBrancos: Subordinados[] = data.map(function (item: Subordinados) { if (item.nomePrincipal) return (item) });
        const ordenado: Subordinados[] = retiraBrancos.sort(function (a: Subordinados, b: Subordinados) { return a.nomePrincipal?.toUpperCase() < b.nomePrincipal?.toUpperCase() ? -1 : a.nomePrincipal?.toUpperCase() > b.nomePrincipal?.toUpperCase() ? 1 : 0 })
        setSubordinados(ordenado)
      } else {
        let { data } = await api.get('/v1/dados-usuario/')
        const retiraBrancos: Subordinados[] = data.map(function (item: Subordinados) { if (item.nomePrincipal) return (item) })
        const ordenado: Subordinados[] = retiraBrancos.sort(function (a: Subordinados, b: Subordinados) { return a.nomePrincipal?.toUpperCase() < b.nomePrincipal?.toUpperCase() ? -1 : a.nomePrincipal?.toUpperCase() > b.nomePrincipal?.toUpperCase() ? 1 : 0 })
        setSubordinados(ordenado)
      }
    }
  }

  async function listaBensVendedores() {
    if (usuario !== null && usuario !== undefined) {
      let { data, status } = await api.get(`/v1/tabelas-venda-usuario/dado-usuario/${usuario?.dadoUsuario.id}/calculado`)
      setBensPorVendedor(data)

      const teste: BensVendedor[] = data
      if ((teste[0].bens || []).length > 0) {
        const unico = teste[0].bens.filter((item) => {
          const duplicados = setDuplicados.has(item.idBem)
          setDuplicados.add(item.idBem)
          return !duplicados
        })
        setBensPorVendedor(data)
        const unicoOrdem = unico.sort(function (a, b) {
          if (a.idBem > b.idBem) {
            return 1
          }
          if (a.idBem < b.idBem) {
            return -1
          }
          return 0;
        })
        //setBensPorVendedor(response1.data)
        setBensPorVendedor1(unicoOrdem)
        setBensPorVendedorfiltro(unicoOrdem)
        setValorBens(data[0].bens)
        setListaSearchBem(data[0].bens)
      }
    }
  }

  async function DadosFiltros() {
    let grupos = valorBens?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    
    let gruposOrdem = gruposFiltro.sort()
    setGrupos(gruposOrdem)


    let valoresBens = valorBens?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = valorBens?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault()
  }

  function handleSearchTodos() {

    setPage(1)
    _DATA.jump(1);
    setAlteraDados({ ...DadosVenda, visualizaBens: true })

    let listaGeral: BensVenda[];

    if (filtroTipo === 0) {
      listaGeral = valorBens;
      setListaSearchBem(listaGeral);
    } else {
      const lista: BensVenda[] = valorBens.filter(item => filtroTipo ? item.codigoGrupoBem === filtroTipo : item)
      listaGeral = lista
      setListaSearchBem(listaGeral);

    };

    if (filtroGrupo !== 0) {
      const lista: BensVenda[] = listaGeral.filter(item => filtroGrupo ? item.codigoGrupo === filtroGrupo : item)
      listaGeral = lista
      setListaSearchBem(listaGeral)

    };

    if (filtroBem !== "") {
      const lista: BensVenda[] = listaGeral.filter(item => filtroBem ? item.idBem === parseInt(filtroBem) : item)
      listaGeral = lista
      setListaSearchBem(listaGeral);
    };


    const lista: BensVenda[] = listaGeral.filter(item => value ? item.credito >= value[0] && item.credito <= value[1] : item)
    listaGeral = lista
    const lista2: BensVenda[] = listaGeral.filter(item => value1 ? item.prazo >= value1[0] && item.prazo <= value1[1] : item)
    listaGeral = lista2
    setListaSearchBem(listaGeral);


    setPage(1)

  }

  const [value, setValue] = React.useState<number[]>([20000, 1800000]);
  const [camposPessoa, setCamposPessoa] = React.useState(DadosVenda?.pessoa === "fisica" ? fisica : juridica);


  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const marks = [
    {
      value: { menorValorBem },
      label: { menorValorBem },
    },
    {
      value: { maiorValorBem },
      label: { maiorValorBem },
    },
  ];

  const [value1, setValue1] = React.useState<number[]>([20, 999]);

  const handleChange1 = (event: Event, newValue: number | number[]) => {
    setValue1(newValue as number[]);
  };

  const marks1 = [
    {
      value: { menorPrazoBem },
      label: { menorPrazoBem },
    },
    {
      value: { maiorPrazoBem },
      label: { maiorPrazoBem },
    },
  ];

  const [model, setModel] = useState({
    tipoPessoa: 'PESSOA_FISICA',
    vendedor: { id: 0 },
    documentoCliente: '',
    renda: 'Renda Mensal',
    valorRenda: 0
  })

  function handleChangeCheckPessoa(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === 'juridica') {
      setModel({ ...model, tipoPessoa: 'PESSOA_JURIDICA', renda: 'Faturamento Mensal', valorRenda: 0, documentoCliente: '' })
      setAlteraDados({ ...DadosVenda, pessoa: 'juridica', documento: "" })
      setMensagemRenda('')
      setCamposPessoa(juridica)
    } else {
      setCamposPessoa(fisica)
      setMensagemRenda('')
      setModel({ ...model, tipoPessoa: 'PESSOA_FISICA', renda: 'Renda', valorRenda: 0, documentoCliente: '' })
      //setAlteraDados('pessoa', 'fisica')
      setAlteraDados({ ...DadosVenda, pessoa: 'fisica', documento: "" })

    }
  }

  function handleChangeVendedor(e: ChangeEvent<HTMLSelectElement>) {
    let nomeVendedor: string = "";
    let nomeCompleto = "";

    if (usuario !== null && usuario !== undefined) {
      if (usuario?.dadoUsuario.cargo?.id === 3) {
        let vendedor = subordinados.find(item => item.id === parseInt(e.target.value))
        if (vendedor)
          nomeVendedor = vendedor.tipoPessoa === "PESSOA_FISICA" ? vendedor.nomePrincipal + " " + vendedor.nomeSecundario : vendedor.nomePrincipal;

      }
      else {
        let vendedor = subordinados.find(item => item.dadoUsuarioId === parseInt(e.target.value))
        if (vendedor)
          nomeVendedor = vendedor.tipoPessoa === "PESSOA_FISICA" ? vendedor.nomePrincipal + " " + vendedor.nomeSecundario : vendedor.nomePrincipal;
      }
    }


    if (parseInt(e.target.value) === usuario?.dadoUsuario.id)
      nomeCompleto = usuario.dadoUsuario.nomePrincipal + ' ' + usuario.dadoUsuario.tipoPessoa === "PESSOA_FISICA" ? usuario.dadoUsuario.nomeSecundario : "";
    setModel({
      ...model,
      vendedor: { id: parseInt(e.target.value) }
    })
    setAlteraDados({ ...DadosVenda, idVendedor: parseInt(e.target.value), nomeVendedor: nomeVendedor.substring(0, 50) || '' })

  }

  function handleChangeBem(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroBem(e.target.value)
  }

  function handleChangeGrupo(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroGrupo(parseInt(e.target.value))

    let benstotais = bensPorVendedor1.filter(item => item.codigoGrupo === parseInt(e.target.value))

    setBensPorVendedor1(benstotais)

    //bensPorVendedorfiltro

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
      setBensPorVendedor1(bensPorVendedorfiltro)
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.codigoGrupo === parseInt(e.target.value) : item)
      listaGeral = lista
      const bensFiltrados = bensPorVendedorfiltro.filter(item=> item.codigoGrupo === parseInt(e.target.value))
      setBensPorVendedor1(bensFiltrados)

    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    let gruposOrdem = gruposFiltro.sort()
    setGrupos(gruposOrdem)

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  function handleChangeTipo(e: ChangeEvent<HTMLSelectElement>) {
    setFiltroTipo(parseInt(e.target.value))

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
      setBensPorVendedor1(bensPorVendedorfiltro)
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.codigoGrupoBem === parseInt(e.target.value) : item)
      listaGeral = lista
      const bensFiltrados = bensPorVendedorfiltro.filter(item=> item.codigoGrupoBem === parseInt(e.target.value))
      setBensPorVendedor1(bensFiltrados)
    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    let gruposOrdem = gruposFiltro.sort()
    setGrupos(gruposOrdem)

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)
  }

  function handleChangeBemInput(e: ChangeEvent<HTMLInputElement>) {
    setFiltroBem(e.target.value)

    //bensPorVendedorfiltro

    let listaGeral: BensVenda[];
    if (parseInt(e.target.value) === 0) {
      listaGeral = valorBens;
      setBensPorVendedor1(bensPorVendedorfiltro)
    } else {
      const lista: BensVenda[] = valorBens.filter(item => parseInt(e.target.value) ? item.idBem === parseInt(e.target.value) : item)
      listaGeral = lista
      const bensFiltrados = bensPorVendedorfiltro.filter(item=> item.idBem === parseInt(e.target.value))
      setBensPorVendedor1(bensFiltrados)

    };

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    let gruposOrdem = gruposFiltro.sort()
    setGrupos(gruposOrdem)

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)
  }

  async function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    var resultado2 = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
    if (e.target.name === "documentoCliente" && resultado.length === 11 && e.target.id === "CPF") {
      const result = TestaCPF(resultado)
      if (!result) {
        addToast({
          type: 'error',
          title: 'CPF Inválido',
          description: 'Por favor insira um CPF Válido!'
        });
        return;
      }

      let response = await api.get(`/v1/parcelas-calculo-renda-newcon`)
      setRendaTodos(response.data)
    }

    if (e.target.name === "documentoCliente" && resultado.length === 14 && e.target.id === "CNPJ") {
      const result = validarCNPJ(resultado)
      if (!result) {
        addToast({
          type: 'error',
          title: 'CNPJ Inválido',
          description: 'Por favor insira um CNPJ Válido!'
        });
        return;
      }

      let response = await api.get(`/v1/parcelas-calculo-renda-newcon`)
      setRendaTodos(response.data)
    }

    setModel({
      ...model,
      [e.target.name]: resultado
    });
    const teste = await setAlteraDados('documentoFormatado', resultado2)
    setAlteraDados({ ...DadosVenda, documentoFormatado: resultado2, documento: resultado })



    //buscaRendaCPF


  }

  function validarCNPJ(cnpj: string) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999")
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros: string = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(0)))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != parseInt(digitos.charAt(1)))
      return false;

    return true;

  }

  async function handleChangeValor(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

    setAlteraDados({ ...DadosVenda, teste: resultado })

  }

  function TestaCPF(strCPF: string) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }


  async function confereCPF() {
    console.log('confereCPF')
    let newValue = new Date();
    let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
    if (model.documentoCliente.length > 9) {
      console.log('Primeiro IF')

      let { data } = await api.get(`/v1/documentos-bloqueados/documento/${model.documentoCliente}`)
      let bloqueados: DocumentosBloqueado[] = data
      const valido = bloqueados.map(item => item.status === "BLOQUEADO")
      if (valido.length > 0) {
        console.log('Segundo IF')
        setAlteraDados({ ...DadosVenda, bloqueado: true })
        addToast({
          type: 'error',
          title: 'Bloqueio por Situação',
          description: 'É necessário entrar em contato com a administradora!'
        });
        return
      }
      let response = await api.get(`/v1/contratos-newcon/documento/${model.documentoCliente}`)
      if (response.data.length > 0) {
        console.log('Chegou aqui dentro do terceiro if')
        let dados: ClienteNewconSituacaoCobranca[] = response.data;
        let situacaoCobranca = dados.filter(filtroPorSituacao)
        if (situacaoCobranca.length > 0) {
          const response = await api.post(`/v1/documentos-bloqueados`,
            {
              "documento": model.documentoCliente,
              "motivo": "Situação de Cobrança",
              "dataBloqueio": DataFormatada,
              "dataValidade": "",
              "status": "BLOQUEADO",

            })
          setAlteraDados({ ...DadosVenda, bloqueado: true })
          addToast({
            type: 'error',
            title: 'Bloqueio por Situação',
            description: 'É necessário entrar em contato com a administradora!'
          });


        }
      } 
        console.log('chegou no buscaDados')
        buscaDados()

     


    }
  }

  function filtroPorSituacao(x: ClienteNewconSituacaoCobranca) {
    if (x.codigoSituacao === 'J00' || x.codigoSituacao === 'J01' || x.codigoSituacao === 'JCO')
      return x
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangePagination = (event: object, e: number) => {
    setPage(e);
    _DATA.jump(e);
  };

  async function selecionaBem(bem: BensVenda) {
    setAlteraDados({ ...DadosVenda, bemSelecionado: { bem } })
    let indice = "IPCA"
    let tipoBemDescricao = "AUTO"
    if (bem.codigoGrupoBem === 9) {
      indice = "INCC"
      tipoBemDescricao = "IMÓVEL"
    } else if (bem.codigoGrupoBem === 1) {
      indice = "IPCA"
      tipoBemDescricao = "CAMINHÃO"
    } else {
      indice = "IPCA"
      tipoBemDescricao = "AUTO"
    }
    //setModelBem(bem)
    let response = await api.get(`/v1/bens-tabela-venda/calculado?idTabela=${bem.idTabelaVendas}&idBem=${bem.idBem}&parcela=${bem.prazo}`)
    let grupos: BensVendaDetalhe[] = response.data
    let alinhados = grupos.sort(function compare(a, b) {
      if (a.adesao < b.adesao) return -1;
      if (a.adesao > b.adesao) return 1;
      return 0
    })
    setBemSelecionado(alinhados)
    let reducao = grupos.map(item => item.reducao)
    let reducaoFiltro = reducao.filter(function (item, i) {
      return reducao.indexOf(item) === i;
    })
    setReducaoModal(reducaoFiltro)

    let dataD = Date.now()
    let dataF = new Date(dataD)
    let dataDiaf = (`${dataF.getFullYear()}-${dataF.getMonth() + 1}-${dataF.getDate()}`)

    let grupoS = bem.codigoGrupo.toString()
    let dataDiaff = dataDiaf.replace(/-/g, '/').replace('T', ' ');

    setAlteraDados(
      {
        ...DadosVenda,
        codB: bem.idBem,
        descricaoBem: bem.descricaoBem,
        valorCredito: bem.credito,
        valorCreditoF: bem.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        prazo: bem.prazo,
        indiceCorrecao: indice,
        tipoBem: bem.codigoGrupoBem,
        descTipo: tipoBemDescricao,
        grupo: grupoS,
        planoBasico: bem.planoBasico,
        numMax: bem.numeroMaximoCotas,
        numeroMax: bem.numeroMaximoCotas,
        numeroMaximoCotas: bem.numeroMaximoCotas,
        taxaAdministracao: bem.taxa,
        dataAdesaoF: dataDia(),
        dataAdesao: new Date(dataDiaff).toISOString(),
        primeiraAssF: formatData(bem.dataAssembleia),
        primeiraAssembleia: bem.dataAssembleia,
        mediaLance: bem.mediaLance,
        sequenciaAgrupamento: 0,
        idTabela: bem.idTabelaVendas,
      })

    setOpen(true);
  }

  async function selecionaBemFinal(bem: VendaBemDetalhe) {
    //const {data} = api.get('/v1/sequencias-agrupamento')
    let total = (bem.valorParcela) + (DadosVenda.valorCredito / 100 * bem.adesao)
    let adesaoX = ""
    let reducaoX = ""

    if (bem.valorParcela > 0) {
      reducaoX = "X"
    }

    if (bem.adesao > 0) {
      adesaoX = "X"
    }

  
    let grupoInauguracao = DadosVenda.grupo
    if(DadosVenda.grupo === "69")
    grupoInauguracao = "EM FORMAÇÃO"

    setAlteraDados({
      ...DadosVenda,
      grupo: grupoInauguracao,
      sequenciaAgrupamento: bem.sequenciaAgrupamento,
      valorAdesaoFormatado: (DadosVenda.valorCredito / 100 * bem.adesao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      valorAdesao: (DadosVenda.valorCredito / 100 * bem.adesao),
      reciboAdesao: ((DadosVenda.valorCredito / 100 * bem.adesao) * 100).toFixed(),
      reciboAdesaoFormatado: (DadosVenda.valorCredito / 100 * bem.adesao).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      valorParcelaFormatado: bem.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      valorParcela: bem.valorParcela,
      reciboPrimeiraParcela: bem.valorParcela * 100,
      reciboPrimeiraParcelaFormatado: bem.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      valorTotal: total,
      valorTotalF: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      adesaoX: adesaoX,
      reducaoX: reducaoX,
      valorTotalExtenso: numero.porExtenso((total > 0 ? total : 0), numero.estilo.monetario).toUpperCase(),
      valorParcelaComSeguroFormatado: bem.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      valorParcelaComSeguro: bem.valorParcelaComSeguro,
      adesao: bem.adesao,
      reducao: bem.reducao,
    })
  }


  async function handleChangeCheckReducao(e: ChangeEvent<HTMLInputElement>) {
    setValorReducao(parseInt(e.target.value))
    setAlteraDados({ ...DadosVenda, reducao: parseInt(e.target.value) })
    setBemSelecionado(bemSelecionado)

  }

  function Bem() {
    if (DadosVenda.sequenciaAgrupamento > 0) {
      setOpen(false)
    } else {
      addToast({
        type: 'error',
        title: 'Atenção',
        description: 'Selecione uma adesão'
      });
    }

  }

  function cancelaBem() {
    setAlteraDados({
      ...DadosVenda,
      sequenciaAgrupamento: 0,
      reducao: 0,
    })
    setValorReducao(0)
    setOpen(false)
    setMensagemRenda('')
    setSelectedValue('a')
    setSelectedValue1('a')

  }

  function fecharBem() {
    setAlteraDados({
      ...DadosVenda,
      sequenciaAgrupamento: 0,
      reducao: 0,
    })
    setValorReducao(0)
    setOpen(false)
    setMensagemRenda('')
  }


  function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function dataDia() {
    let dataDia = Date.now()
    let dataAss = new Date(dataDia)
    let DataFormatada = ((adicionaZero(dataAss.getDate())) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function mostrar(seq: number) {
    if (mostrarModal === seq) {
      setVisible(visible === true ? false : true)
    } else {
      setVisible(true)
      setMostrarModal(seq)
    }
  }


  function apenasNumeros(x: string) {
    /*var numsStr = x.replace(",",".");
    var y = numsStr.replace("R$ ", "");
    if(numsStr.length > 3){
      y = numsStr.slice(3,9999)
    }
   */
    //return parseFloat(x);
  }

  async function validaRenda() {
    if (DadosVenda.renda > 0) {
      const lista: RendaNewcon[] = rendaTodos.filter(item => model.documentoCliente ? item.cgcCpfCliente === model.documentoCliente : item)
      const valores = lista?.map(item => item.parcela)
      let totalNewcon = 0;
      /*if (valores.length > 0) {
        console.log('valores',valores)
        totalNewcon = valores?.reduce((total, numero) => total + numero)
      }*/

      const valor: number = DadosVenda.valorParcela || 0;
      let totalGeral = 0

      if (model.tipoPessoa === 'PESSOA_FISICA') {
        if (valor > 0) {
          totalGeral = ((totalNewcon + valor) * 3)
        }
      } else {
        if (valor > 0) {
          totalGeral = totalNewcon + valor;
        }
      }
      let mensagem = ""

      if (totalNewcon > 0) {
        mensagem = `cliente tem consórcio Groscon com parcelas no total de ${totalNewcon.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}.`
      }

      if (model.tipoPessoa === 'PESSOA_FISICA') {
        if (DadosVenda.renda > totalGeral) {
          setAlteraDados({ ...DadosVenda, rendaAprovada: true })
          addToast({
            type: 'success',
            title: 'Renda Aprovada',
            description: 'Cliente apto a fazer a cota!'
          });
          setMensagemRenda('')
        } else {
          console.log('valor da renda ',totalGeral)
          setMensagemRenda(`A renda tem que ser maior que ${totalGeral.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
      ${mensagem}
      `)
          addToast({
            type: 'error',
            title: 'Renda Inválida',
            description: `A renda tem que ser maior que ${totalGeral.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
        ${mensagem}
        `
          });
        }
      } else if (model.tipoPessoa === 'PESSOA_JURIDICA') {
        if ((DadosVenda.renda / 10) > totalGeral) {
          setAlteraDados({ ...DadosVenda, rendaAprovada: true })
          addToast({
            type: 'success',
            title: 'Renda Aprovada',
            description: 'Cliente apto a fazer o cota!'
          });
          setMensagemRenda('')
        } else {
          setMensagemRenda(`A renda tem que ser maior que ${(totalGeral * 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
        ${mensagem}
        `)
          addToast({
            type: 'error',
            title: 'Renda Inválida',
            description: `A renda tem que ser maior que ${(totalGeral * 10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}\n
          ${mensagem}
          `
          });
        }
      }
    } else {
      setMensagemRenda('')
      addToast({
        type: 'error',
        title: 'Renda Inválida',
        description: `Digite uma renda Válida`
      })
    }
  }

  function limparTodos() {
    setFiltroBem("")
    setFiltroGrupo(0)
    setFiltroTipo(0)
    setValue([0, 1800000])
    setValue1([0, 1800000])
    setListaSearchBem(valorBens)

    let listaGeral: BensVenda[];
      listaGeral = valorBens;
    

    let grupos = listaGeral?.map(item => item.codigoGrupo)
    let gruposFiltro = grupos.filter(function (item, i) {
      return grupos.indexOf(item) === i;
    })
    let gruposOrdem = gruposFiltro.sort()
    setGrupos(gruposOrdem)

    let valoresBens = listaGeral?.map(item => item.credito)
    let maiorValorBem = Math.max(...valoresBens)
    let menorValorBem = Math.min(...valoresBens)
    setMaiorValorBem(maiorValorBem)
    setMenorValorBem(menorValorBem)

    let mesesBens = listaGeral?.map(item => item.prazo)
    let maiorPrazoBem = Math.max(...mesesBens)
    let menorPrazoBem = Math.min(...mesesBens)
    setMaiorPrazoBem(maiorPrazoBem)
    setMenorPrazoBem(menorPrazoBem)

  }

  async function passaFase1() {
    if (DadosVenda.codB === 0) {
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      return
    }
    const tudo = JSON.stringify(DadosVenda)
    const teste = JSON.parse(tudo)


    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 1,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        nomeVendedor: DadosVenda.nomeVendedor.substring(0, 50),
        status: "PENDENTE",
        posVenda: "PENDENTE"
      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          etapa2: false
        })
      }

      setAlteraPagina(1)
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 1,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        nomeVendedor: DadosVenda.nomeVendedor.substring(0, 50),
        status: "PENDENTE",
        posVenda: "PENDENTE"
      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,
          idPreVenda: response.data[0].id,
          etapa2: false,

        })
        setAlteraPagina(1)
      }
    }
  }

  function teclaEnter() {
    validaRenda()
  }

  function handleteste(e: ChangeEvent<HTMLInputElement>) {
    var resultado = e.target.value;
    resultado = resultado.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("R$ ", "");
    resultado = resultado.replaceAll(",", ".")

    setAlteraDados({
      ...DadosVenda,
      teste: e.target.value
    })

  }

  return (
    <>
      <Container style={{ padding: '0' }}>
        <Typography>
          <Form className="form-border" onSubmit={onSubmit}>
            <Box>
              <Row>
                <Col style={{ minWidth: '220px', display: 'flex', alignItems: 'center' }}>
                  {/* <FormLabel id="demo-row-radio-buttons-group-label" style={{ marginLeft: 8, color: "#ffffff" }}>.</FormLabel> */}
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="tipoPessoaRadio"
                    onChange={handleChangeCheckPessoa}
                    value={DadosVenda.pessoa}
                  >
                    <FormControlLabel value="fisica" control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label="Fisíca" />
                    <FormControlLabel value="juridica" control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label="Jurídica" />
                  </RadioGroup>
                </Col>
                <Col style={{ minWidth: '220px', maxWidth: '470px' }}>
                  <div className="inputNovo">
                    <Form.Group className="select">
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Vendedor
                      </InputLabel>
                      <NativeSelect
                        style={{ width: "100%" }}
                        className="inputNovo"
                        defaultValue={30}
                        onChange={handleChangeVendedor}
                        value={DadosVenda.idVendedor}
                        inputProps={{
                          name: 'idVendedor',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option id="0" value={usuario?.dadoUsuario.id}>{usuario?.dadoUsuario.nomePrincipal.toUpperCase()} {usuario?.dadoUsuario.tipoPessoa === 'PESSOA_FISICA' ? usuario?.dadoUsuario.nomeSecundario.toUpperCase() : ""}</option>
                        {subordinados?.map(item => {
                          if (item?.id !== undefined) {
                            return (
                              <option id={item.id.toString()} value={item.id}>
                                {item.nomePrincipal ? item.nomePrincipal.toUpperCase() : ""} {item.tipoPessoa === 'PESSOA_FISICA' && item.nomeSecundario ? item.nomeSecundario.toUpperCase() : ""}
                              </option>
                            )
                          }
                          if (item?.dadoUsuarioId !== undefined) {
                            return (
                              <option id={item.dadoUsuarioId.toString()} value={item.dadoUsuarioId}>
                                {item.nomePrincipal ? item.nomePrincipal.toUpperCase() : ""} {item.tipoPessoa === 'PESSOA_FISICA' && item.nomeSecundario ? item.nomeSecundario.toUpperCase() : ""}
                              </option>
                            )
                          }
                        })
                        }
                      </NativeSelect>
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row between style={{ marginTop: 15 }}>
                <Col xs={12} sm={3}>
                  <FormControl className="inputNovo" variant="standard" >
                    <InputMask
                      mask={camposPessoa.mascaraPessoa}
                      onChange={handleChangeInput}
                      value={DadosVenda.documento}
                      onBlur={confereCPF}
                    >
                      {() =>
                        <TextField
                          className='inputNovo'
                          required
                          id={camposPessoa.documentoPrincipal}
                          label={camposPessoa.documentoPrincipal}
                          variant="standard"
                          name='documentoCliente'
                        />
                      }
                    </InputMask>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                {DadosVenda.bloqueado ?
                  <Stack sx={{ width: '50%' }} spacing={2} style={{ marginLeft: "-20px" }}>
                    <Alert severity="error" style={{ color: "red" }}>Documento Bloqueado</Alert>
                  </Stack>
                  : <></>}
              </Row>

              <Row between style={{ marginTop: 20 }}>
                <Col xs={7} sm={3}>
                  <div className="CampoTipo">
                    <Form.Group className="select">
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Tipo
                      </InputLabel>
                      <NativeSelect
                        style={{ width: "100%" }}
                        className="CampoTipo"
                        onChange={handleChangeTipo}
                        value={filtroTipo > 0 ? filtroTipo : '99'}
                        inputProps={{
                          name: 'nivel',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={0}>TODOS</option>
                        {bensPorVendedor?.map(function (item) {
                          let teste: tipoBem[] = item.tiposBem
                          if ((teste || []).length > 0) {
                            teste = (item.tiposBem.sort(function (a, b) {
                              if (a.descricao > b.descricao) {
                                return 1
                              }
                              if (a.descricao < b.descricao) {
                                return -1
                              }
                              return 0
                            }) || [])
                            return (
                              teste.map(function (item2) {
                                if((filtroTipo === item2.codigoGrupoBem)|| filtroTipo === 0)
                                return (
                                  <>
                                    <option id={item2.codigoGrupoBem.toString()} value={item2.codigoGrupoBem}>
                                      {item2.descricao}
                                    </option>
                                  </>
                                )
                              })
                            )
                          }
                        })
                        }
                      </NativeSelect>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={5} sm={2}>
                  <div className="inputNovo">
                    <Form.Group className="select">
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Grupo
                      </InputLabel>
                      <NativeSelect
                        style={{ width: "100%" }}
                        className="inputNovo"
                        defaultValue={30}
                        onChange={handleChangeGrupo}
                        value={filtroGrupo}
                        inputProps={{
                          name: 'nivel',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={0}>TODOS</option>
                        {grupos?.map(item => {
                          let mostraItem = item.toString()
                          return (
                            <option id={item.toString()} value={item}>
                              {mostraItem==='69'?"EM FORMAÇÃO":mostraItem}
                            </option>
                          )
                        }
                        )
                        }
                      </NativeSelect>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} sm={7}>
                  <Form.Group as={Col} controlId="formGridBem" >
                    <div className="labelBem"><InputLabel variant="standard" htmlFor="uncontrolled-native" >
                      Valor do bem
                    </InputLabel></div>
                    <Box sx={{ marginTop: 1, marginRight: 4, marginLeft: 4 }}>
                      <Slider
                        getAriaLabel={() => 'Valor bem'}
                        value={value}
                        min={menorValorBem}
                        max={maiorValorBem}
                        sx={{ color: 'var(--blue) ' }}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => <div style={{ fontSize: 12 }}>{value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>}
                        getAriaValueText={valuetext}
                      />
                    </Box>
                  </Form.Group>
                </Col>
              </Row>
              <Row between style={{ marginTop: 10 }}>
                <Col xs={4} sm={2}>
                  <div className="inputNovo">
                    <FormControl className="inputNovo" variant="standard" style={{ marginTop: 7 }} >
                      <TextField
                        className='inputNovo'
                        id="standard-required"
                        label="Cód. Bem"
                        variant="standard"
                        name='nomePrincipal'
                        value={filtroBem}
                        onChange={handleChangeBemInput}
                      />
                    </FormControl>
                  </div>
                </Col>
                <Col xs={8} sm={3}>
                  <div className="inputNovo">
                    <Form.Group className="select">
                      <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Bem
                      </InputLabel>
                      <NativeSelect
                        style={{ width: "100%" }}
                        className="inputNovo"
                        defaultValue={30}
                        onChange={handleChangeBem}
                        value={filtroBem}
                        inputProps={{
                          name: 'nivel',
                          id: 'uncontrolled-native',
                        }}
                      >
                        <option value={"0"}>TODOS</option>
                        {bensPorVendedor1?.map(function (item2) {

                          return (
                            <>
                              <option id={item2.idBem.toString()} value={item2.idBem}>
                                {item2.idBem} {item2.descricaoBem}
                              </option>
                            </>
                          )
                        }

                        )
                        }
                      </NativeSelect>
                    </Form.Group>
                  </div>
                </Col>
                <Col style={{ minWidth: '280px', maxWidth: '600px', marginTop: 20 }}>
                  <Form.Group as={Col} controlId="formGridBem" style={{ marginTop: -14 }}>
                    <div className="labelBem"><InputLabel variant="standard" htmlFor="uncontrolled-native" >
                      Quantidade de parcelas
                    </InputLabel></div>
                    <Box sx={{ marginTop: 1, marginRight: 4, marginLeft: 4 }}>
                      <Slider
                        getAriaLabel={() => 'Valor bem'}
                        value={value1}
                        min={menorPrazoBem}
                        max={maiorPrazoBem}
                        sx={{ color: 'var(--blue) ' }}
                        onChange={handleChange1}
                        valueLabelDisplay="on"
                        valueLabelFormat={value => <div style={{ fontSize: 12 }}>{value}</div>}
                        getAriaValueText={valuetext}
                      />
                    </Box>
                  </Form.Group>
                </Col>
              </Row>

              <div className="botaoPesquisar">
                <Button variant="primary" className="btnPesquisar" onClick={limparTodos} style={{ marginRight: "15" }}>
                  <Typography variant="button" gutterBottom>LIMPAR</Typography>
                </Button>
                <Button variant="primary" className="btnPesquisar" onMouseDown={handleSearchTodos} style={{ marginLeft: "15" }}>
                  <Typography variant="button" gutterBottom> Pesquisar</Typography>
                </Button>
              </div>
            </Box>
          </Form>


          {DadosVenda.visualizaBens === true ? <>
            {!DadosVenda.bloqueado ? <>

              {DadosVenda.sequenciaAgrupamento === 0 ?
                <Tabelas1>
                  <Table bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="ac tamanhoAC">Grupo</th>
                        <th className="ac">Bem</th>
                        <th className="ac">Valor Bem</th>
                        <th className="ac">Taxa Adm.</th>
                        <th className="ac">Próx. Assembleia</th>
                        <th className="ac">Prazo</th>
                        <th className="ac">Parcela</th>
                        <th className="ac">Parcela c/ Seg.</th>
                        <th className="ac">Média Lance</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      {_DATA.currentData().map(item => {
                        let grupo = (item.codigoGrupo).toString()
                        return (
                          <>
                            <tr className="ac tamanhoAC">
                              <td className="ac tamanhoAC">{grupo==='69'?'EM FORMAÇÃO':grupo}</td>
                              <td className="ac">{item.idBem + " - "}{item.descricaoBem}</td>
                              <td className="ac">{item.credito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                              <td className="ac">{item.taxa}%</td>
                              <td className="ac">{formatData(item.dataAssembleia)}</td>
                              <td className="ac">{item.prazo}</td>
                              <td className="ac">{item.parcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                              <td className="ac">{item.parcelaSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                              <td className="ac tamanhoAC"><CircularProgressWithLabel size={30} variant="determinate" value={parseFloat(item.mediaLance.toFixed(2))} /></td>
                              <td className="ac tamanhoAC"><Radio name="escolhaBem" size="small" onChange={handleChangeOption} checked={selectedValue === (item.idTabelaVendas + item.codigoGrupo + item.credito + item.prazo).toString()}
                                value={(item.idTabelaVendas + item.codigoGrupo + item.credito + item.prazo)}
                                sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} onClick={() => selecionaBem(item)} /></td>
                            </tr>
                          </>
                        )
                      })}

                      {/* <tr style={{ height: "60" }}>
                        <td colSpan={10} style={{ height: "60" }}>
                        </td>
                      </tr> */}

                    </tbody>
                  </Table>
                  <Pagination className="paginacao" count={count} page={page} onChange={handleChangePagination} size="large" style={{ marginTop: "10" }} />
                </Tabelas1> :
                <>
                  <div className="bemSelecionado">
                    <Row>
                      <Col xs={11} className="tituloBem">Plano selecionado <FiEdit2 style={{ cursor: "pointer" }} onClick={() => setOpen(true)}></FiEdit2></Col>
                      <Col className="tituloBem direita"><div className="direita1"><FiX style={{ cursor: "pointer" }} onClick={fecharBem}></FiX></div></Col>
                    </Row>
                    <>
                      <Typography>
                        <Tabelas1>
                          <Table hover bordered className="fonteTI" responsive>
                            <thead>
                              <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                <td className="ac n tamanhoAC">Grupo</td>
                                <td className="ac n">Bem</td>
                                <td className="ac n">Valor do bem</td>
                                <td className="ac n">Taxa Adm.</td>
                                <td className="ac n">Próx. Assembleia</td>
                                <td className="ac n">Prazo</td>
                                <td className="ac n">Parcela</td>
                                <td className="ac n">Parcela c/ seguro</td>
                                <td className="ac n">Média Lance</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.grupo==='69'?'EM FORMAÇÃO':DadosVenda.grupo}</td>
                                <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.codB + " - "} {DadosVenda.descricaoBem}</td>
                                <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.valorCredito >= 0 ? DadosVenda.valorCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</td>
                                <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.taxaAdministracao + '%'}</td>
                                <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{formatData(DadosVenda.primeiraAssembleia)}</td>
                                <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.prazo}</td>
                                <td className="ac tam">{DadosVenda.valorParcela >= 0 ? DadosVenda.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</td>
                                <td className="ac tam">{DadosVenda.valorParcelaComSeguro >= 0 ? (DadosVenda.valorParcelaComSeguro).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</td>
                                <td className="ac"><CircularProgressWithLabel size={25} variant="determinate" value={DadosVenda.mediaLance >= 0 ? parseFloat(DadosVenda.mediaLance.toFixed(2)) : 0} /></td>
                              </tr>
                            </tbody>
                          </Table>
                        </Tabelas1>
                      </Typography>
                    </>
                  </div>
                </>
              }

              <br />
              <Row C>
                <Col xs={6} sm={8}>
                  <TextField
                    InputLabelProps={{ shrink: DadosVenda.sequenciaAgrupamento > 0 ? true : false }}
                    label={model.renda}
                    required
                    disabled={DadosVenda.sequenciaAgrupamento > 0 && DadosVenda.documento ? false : true}
                    value={DadosVenda.rendaReal > 0 ? DadosVenda.rendaReal : ""}
                    onChange={handleChangeValues}
                    onBlur={validaRenda}
                    onKeyPress={(e) => e.key === 'Enter' && teclaEnter()}
                    variant="standard"
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />

                </Col>
                <Col xs={6} sm={4} className="d-flex justify-content-end">
                  <Button variant="primary" type="submit" className="btnPesquisar" onClick={passaFase1} disabled={(DadosVenda.bloqueado === false && DadosVenda.rendaAprovada === false) ? true : false}>
                    <Typography variant="button" gutterBottom> PRÓXIMO</Typography>
                  </Button>


                </Col>

              </Row>
              {mensagemRenda ?
                <Stack sx={{ width: '50%' }} spacing={2} style={{ marginTop: "0px", marginLeft: "0px" }}>
                  <Alert severity="error" style={{ color: "red" }}>{mensagemRenda}</Alert>
                </Stack>
                : <></>}

            </> : <></>}
          </> : <></>}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}
          >
            <Box sx={styleBox}>
              <Row>
                <Typography>
                  <Tabelas1>
                    <div className="titulo title1">
                      <Typography style={{ border: "none", margin: "0" }} gutterBottom>
                        <h5>PARCELAS DETALHES</h5>
                      </Typography>
                    </div>
                    <Table bordered hover responsive className="fonteTI">
                      <thead>
                        <tr>
                          <td className="ac n tamanhoAC">Grupo</td>
                          <td className="ac n">Bem</td>
                          <td className="ac n">Valor do bem</td>
                          <td className="ac n">Taxa</td>
                          <td className="ac n">Próxima Assembleia</td>
                          <td className="ac n">Prazo</td>
                          <td className="ac n">Média Lance</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.grupo==='69'?'EM FORMAÇÃO':DadosVenda.grupo} </td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.codB + " -"} {DadosVenda.descricaoBem}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.valorCredito >= 0 ? DadosVenda.valorCredito.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.taxaAdministracao}%</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{formatData(DadosVenda.primeiraAssembleia)}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.prazo}</td>
                          <td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{DadosVenda.mediaLance >= 0 ? parseFloat(DadosVenda.mediaLance.toFixed(2)) : 0}%</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tabelas1>
                </Typography>
              </Row>
              <Typography id="modal-modal-title" variant="h6" component="h4">
                <div style={{ marginTop: "10px", color: "var(--blue)" }}>
                  Redução
                </div>
                <Row className="tipoPessoa">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="tipoPessoaRadio"
                    onChange={handleChangeCheckReducao}
                    value={DadosVenda.reducao}
                  >
                    {reducaoModal.map(item => (
                      <FormControlLabel value={item} control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label={item + '%'} />
                    ))}
                  </RadioGroup>
                </Row>
              </Typography>

              <Typography variant="h6" component="h4">
                <div style={{ marginTop: "10px", color: "var(--blue)" }}>
                  Adesão
                </div>
              </Typography>
              <Typography>
                <Tabelas1>

                  {bemSelecionado.map(item => {
                    if (item.reducao === DadosVenda.reducao && item.parcelaInicial === 1)
                      return (
                        <>

                          <Table bordered responsive className="fonteTI" style={{ marginTop: "10px", marginLeft: "2px" }}>
                            <tbody>
                              <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                <td className="ac n"></td>
                                <td className="ac n">Valor Adesão</td>
                                <td className="ac n">Parcela</td>
                                <td className="ac n">Parc. com Seg.</td>
                                <td></td>

                              </tr>
                              <tr style={{ fontSize: "18px" }}>
                                <td className="ac redm"><div style={{ zIndex: "9999" }}>
                                  <FormControlLabel label={item.adesao + "%"} control={<Radio size="small" name="escolhaBem" onChange={handleChangeOption1} checked={selectedValue1 === (item.sequenciaAgrupamento).toString() || (DadosVenda.sequenciaAgrupamento).toString() === (item.sequenciaAgrupamento).toString()}
                                    value={(item.sequenciaAgrupamento)}
                                    sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} onClick={() => selecionaBemFinal(item)} />} />
                                </div></td>
                                <td className="ac tam">{item.adesao === 0 ? '-' : (item.adesao * DadosVenda.valorCredito / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td className="ac tam">{item.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td className="ac tam">{item.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td className="ac tam" onClick={() => mostrar(item.sequenciaAgrupamento)}>{mostrarModal === item.sequenciaAgrupamento && visible ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</td>

                              </tr>
                            </tbody>
                          </Table>
                          {mostrarModal === item.sequenciaAgrupamento && visible ?
                            <><Table hover bordered className="fonteTI" responsive>
                              <thead style={{ backgroundColor: "#f4f4f4e1" }}>
                                <tr style={{ backgroundColor: "#f4f4f4e1" }}>
                                  <th className="ac tamanhoAC">Parcela</th>
                                  <th className="ac">Valor S/ Seguro</th>
                                  <th className="ac">Valor C/ Seguro</th>

                                </tr>


                                {bemSelecionado.map(item2 => {
                                  if (item2.sequenciaAgrupamento === item.sequenciaAgrupamento)
                                    return (
                                      <>
                                        <tr>
                                          <td className="ac tamanhoAC">{item2.parcelaInicial} a {item2.parcelaFinal}</td>
                                          <td className="ac">{item2.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                          <td className="ac">{item2.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                      </>
                                    )
                                })}
                              </thead>
                            </Table></> : <></>

                          }

                        </>
                      )
                  })}
                  <div className="testea">
                    <Button onClick={cancelaBem} style={{ backgroundColor: "var(--blue)", marginRight: "20px" }}><Typography variant="button" gutterBottom>CANCELAR</Typography></Button>
                    <Button onClick={Bem} style={{ backgroundColor: "var(--blue)" }}><Typography variant="button" gutterBottom>SELECIONAR</Typography></Button>
                  </div>

                </Tabelas1>

              </Typography>
            </Box>
          </Modal>
        </Typography>
      </Container>
    </>
  )
}


import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import { Form, Button } from 'react-bootstrap';
import { Container, Tabelas1, MCol, MRow } from './styles';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from '../../../services/api';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../hooks/toast';
import { FiXCircle } from 'react-icons/fi';
import { FaBarcode, FaFileSignature, FaCloud } from 'react-icons/fa';
import { CgNotes } from 'react-icons/cg';
import NumberFormat from "react-number-format";
import FormHelperText from '@mui/material/FormHelperText';
import Modal from '@mui/material/Modal';
import cep from 'cep-promise';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Seguradoras } from '../../../interfaces/seguradoras';
import { SeguroGrupos } from '../../../interfaces/seguroGrupos';
import { TemplateVenda } from '../../../interfaces/templateVenda';
import { templates } from '../../../mock/templateVenda';
import { NumeroAssembleia } from "../../../interfaces/assembleias";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Col, Row } from "../styles";

const numero = require('numero-por-extenso');

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      //decimalSeparator={","}

      prefix={"R$ "}
      format={currencyFormatter}
    />
  );
}

function currencyFormatter(value: any) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return amount

}

const style = {
  // position: 'absolute' as 'absolute',
  overflow: 'scroll',
  // overflowX: 'hidden',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  alignSelf: 'center',
  maxWidth: 800,
  with: '100%',
  maxHeight: 600,
  bgcolor: 'background.paper',
  //border: '2px solid #000',
  boxShadow: 2,
  p: 1,

  header: {
    padding: '12px 0',
    borderBottom: '1px solid darkgrey'
  },
  content: {
    padding: 12,
    overflow: 'scroll'
  }
};



export function Etapa7Seguro(props: any) {

  const { addToast } = useToast();
  const testRef = useRef<HTMLDivElement>(null);
  const divAlto = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { DadosVenda, setAlteraDados, setAlteraPagina } = props
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [recibo, setRecibo] = useState('');
  const [seguros, setSeguros] = useState<Seguradoras[]>([]);
  const [seguroGrupo, setSeguroGrupo] = useState<SeguroGrupos[]>([]);
  const [assembleia, setAssembleia] = useState<NumeroAssembleia[]>([]);
  const [tempVenda, setTempVenda] = useState<TemplateVenda[]>(templates)
  const [nomeCompleto, setNomeCompleto] = useState('')
  const [idTemplate, setIdTemplate] = useState('');
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    segurosTodos()
    segurosGrupo()
    numeroAssembleia()
  }, [])

  useEffect(() => {
    const idObjeto = tempVenda.find(item => item.name === nomeCompleto)
    if (DadosVenda.contratarSeguro === 1) {


      const seguroG = seguroGrupo.find(item => item.codigoGrupo === DadosVenda.grupo)
      const percSeg = seguros.find(item => item.codigoSeguradora === seguroG?.codigoSeguradora)
      let percF: string = ""
      if (percSeg !== undefined) {
        percF = percSeg.percentualSegVida.toString() + "%"
      }

      var total = DadosVenda.valorParcelaComSeguro + DadosVenda.valorAdesao
      console.log(DadosVenda.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))

      setAlteraDados({
        ...DadosVenda,
        reciboPrimeiraParcela: DadosVenda.valorParcelaComSeguro * 100,
        reciboPrimeiraParcelaFormatado: DadosVenda.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorTotal: total,
        valorTotalF: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        segVS: "X",
        segVN: percF,
        template: idObjeto?.id,
        nomeCompletoTemplate: nomeCompleto

      })
    } else {
      setAlteraDados({ ...DadosVenda, template: idObjeto?.id, nomeCompletoTemplate: nomeCompleto })
    }
  }, [nomeCompleto])

  async function segurosTodos() {
    const { data } = await api.get('/v1/seguradoras')
    setSeguros(data)
  }

  async function numeroAssembleia() {
    const { data } = await api.get('/v1/assembleias')
    setAssembleia(data)
  }

  async function segurosGrupo() {
    const { data } = await api.get('/v1/grupos-seguradora')
    setSeguroGrupo(data)
  }

  function adicionaZero(numero: number) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    console.log(e.target.name)
    if (e.target.name === "cpfBeneficiario") {
      let resultado = e.target.value.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase(), cpfBeneficiarioFormatado: resultado })
    } else if (e.target.name === "reciboAdesao" || e.target.name === "reciboPrimeiraParcela") {
      let resultado = parseFloat(e.target.value)
      let total = 0
      console.log(resultado / 100)
      let campo = e.target.name + "Formatado"
      if (e.target.name === "reciboAdesao") {
        total = (parseFloat(e.target.value) + DadosVenda.reciboPrimeiraParcela) / 100
        console.log('total :' + total)
      } else {
        total = (parseFloat(e.target.value) + DadosVenda.reciboAdesao) / 100
        console.log('total' + total)
      }
      setAlteraDados({
        ...DadosVenda, [e.target.name]: resultado,
        [campo]: resultado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorTotal: total,
        valorTotalF: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorTotalExtenso: numero.porExtenso((total > 0 ? total : 0), numero.estilo.monetario).toUpperCase()
      })
    } else if (e.target.name === "cepBeneficiario") {
      let resultado = e.target.value.replaceAll("_", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value })
      buscaCep(resultado)
    } else {
      setAlteraDados({ ...DadosVenda, [e.target.name]: e.target.value.toUpperCase() })
    }
  };

  const handleChangeInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAlteraDados({ ...DadosVenda, emailGeral: e.target.value.toUpperCase(), [e.target.name]: e.target.value.toUpperCase() })

  };


  useEffect(() => {
    rolar();
  }, [recibo])

  function rolar() {
    if (DadosVenda.tipoPagamento === "RECIBO") {
      if (testRef.current) {
        testRef.current.scrollIntoView()
      }
    } else {
      if (divAlto.current) {
        divAlto.current.scrollIntoView()
      }
    }
  }

  function rolagemFinal() {
    if (testRef.current) {
      console.log(DadosVenda.tipoPagamento)
      testRef.current.scrollIntoView()
      console.log(testRef)
    }
  }

  function rolagemCima() {
    if (divAlto.current) {
      divAlto.current.scrollIntoView()
      console.log(divAlto)
    }
  }

  function formatData(x: string) {
    let dataAss = new Date(x)
    let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
    return (DataFormatada)
  }

  function handleChangeCheckPessoa(e: ChangeEvent<HTMLInputElement>) {
    console.log(templates)
    if (parseInt(e.target.value) === 1) {
      const seguroG = seguroGrupo.find(item => item.codigoGrupo === DadosVenda.grupo)
      const percSeg = seguros.find(item => item.codigoSeguradora === seguroG?.codigoSeguradora)
      let percF: string = ""
      if (percSeg !== undefined) {
        percF = percSeg.percentualSegVida.toString() + "%"
      }

      var total = DadosVenda.valorParcelaComSeguro + DadosVenda.valorAdesao

      setAlteraDados({
        ...DadosVenda,
        [e.target.name]: parseInt(e.target.value),
        reciboPrimeiraParcela: DadosVenda.valorParcelaComSeguro * 100,
        reciboPrimeiraParcelaFormatado: DadosVenda.valorParcelaComSeguro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorTotal: total,
        valorTotalF: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        segVS: "X",
        segVN: percF

      })
    } else {
      var total = DadosVenda.valorParcela + DadosVenda.valorAdesao

      setAlteraDados({
        ...DadosVenda,
        [e.target.name]: parseInt(e.target.value),
        reciboPrimeiraParcela: DadosVenda.valorParcela * 100,
        reciboPrimeiraParcelaFormatado: DadosVenda.valorParcela.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        valorTotal: total,
        valorTotalF: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        segVS: "X",
        segVN: "--"
      })
    }

  }

  //segueeee
  // terminou





  const tipoPagamento = async (tipo: string) => {

    if (tipo === 'RECIBO') {
      rolagemFinal()
    } else {
      rolagemCima()
    }

    let nome = 'CG_'

    if (DadosVenda.pessoa === 'juridica') {
      nome += 'PJ_'
    } else {
      nome += 'PF_'
    }

    if (DadosVenda.contratarSeguro === 1) {
      nome += 'SEG_'
    }

    if (DadosVenda.reducao > 0) {
      nome += 'RED_'
    }

    if (DadosVenda.termos > 0) {
      nome += 'TER_'
    }

    if (tipo === 'RECIBO') {
      nome += 'REC_'
    }

    setNomeCompleto(nome.substring(0, nome.length - 1) + '.docx');
    setRecibo(tipo)
    setAlteraDados({
      ...DadosVenda,
      tipoPagamento: tipo,
      indenizarLegislacao: 0,
    })
  }

  const tipoAssinatura = async (tipoAssinatura: string) => {
    /* if(tipo==='RECIBO'){
       rolagemFinal()
     }else{
       rolagemCima()
     }*/

    let nome = 'CG_'

    if (DadosVenda.pessoa === 'juridica') {
      nome += 'PJ_'
    } else {
      nome += 'PF_'
    }

    if (DadosVenda.contratarSeguro === 1) {
      nome += 'SEG_'
    }

    if (DadosVenda.reducao > 0) {
      nome += 'RED_'
    }

    if (DadosVenda.termos > 0) {
      nome += 'TER_'
    }

    console.log(DadosVenda.tipoPagamento)
    if (DadosVenda.tipoPagamento === 'RECIBO') {
      console.log('passou aqui')
      nome += 'REC_'
    }

    if (tipoAssinatura === 'MANUAL') {
      nome += 'AM_'
    }

    setNomeCompleto(nome.substring(0, nome.length - 1) + '.docx');
    // console.log(nome)

    setAlteraDados({
      ...DadosVenda,
      tipoAssinatura: tipoAssinatura,
      indenizarLegislacao: 0,
    })
  }

  async function buscaCep(x: string) {
    if (x.length === 8) {
      try {
        let resultado = await cep(x)
        console.log(resultado.street)
        if (resultado.street) {
          setAlteraDados({
            ...DadosVenda,
            cepBeneficiario: x,
            enderecoBeneficiario: resultado.street.toUpperCase(),
            bairroBeneficiario: resultado.neighborhood.toUpperCase(),
            cidadeBeneficiario: resultado.city.toUpperCase(),
            estadoBeneficiario: resultado.state.toUpperCase(),
          })
        } else {
          addToast({
            type: 'info',
            title: 'Cep não encontrato!',
            description: 'Não encontramos este cep na base de dados!'
          });
          setAlteraDados({
            ...DadosVenda,
            cep: x,
            endereco: "",
            bairro: "",
            cidade: "",
            estado: "",
          })
        }
      } catch (err) {
        addToast({
          type: 'info',
          title: 'Cep não encontrato!',
          description: 'Não encontramos este cep na base de dados!'
        });
        setAlteraDados({
          ...DadosVenda,
          cep: x,
          endereco: "",
          bairro: "",
          cidade: "",
          estado: "",
        })
      }
    }
  }

  async function passaFase6() {
    const tudo = JSON.stringify(DadosVenda)
    if (DadosVenda?.id > 0) {
      const response = await api.put(`v1/pre-vendas/${DadosVenda.id}`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 7,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "CONCLUIDO",
        posVenda: "PENDENTE",
      })

      if (response.status === 200) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,

        })
      }
    } else {
      const response = await api.post(`v1/pre-vendas`, {
        idVendedor: DadosVenda.idVendedor,
        idTabela: DadosVenda.idTabela,
        documento: DadosVenda.documento,
        dataCriacao: DadosVenda.dataAdesao,
        etapa: 7,
        conteudo: tudo,
        dataExportacao: DadosVenda.dataAdesao,
        status: "CONCLUIDO",
        posVenda: "PENDENTE",

      })

      if (response.status === 201) {
        setAlteraDados({
          ...DadosVenda,
          id: response.data[0].id,

        })
      }
    }
  }

  async function efetivarVenda() {
    if (DadosVenda.codB === 0) {
      addToast({
        type: 'error',
        title: 'Erro bem selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o bem novamente!'
      });
      setAlteraPagina(0)
      return
    }

    if (!DadosVenda.idVendedor) {
      addToast({
        type: 'error',
        title: 'Erro vendedor selecionado',
        description: 'Ao retornar a primeira pagina, por favor selecione o vendedor novamente!'
      });
      setAlteraPagina(0)
      return
    }


    if(DadosVenda.idPreVenda === 0){
      DadosVenda.idPrevenda = DadosVenda.id
    }

    if(!DadosVenda.email1){
      if(DadosVenda.email2){
        DadosVenda.email1 = DadosVenda.email2
      }else if(DadosVenda.email3){
        DadosVenda.email1 = DadosVenda.email3
      }else if(DadosVenda.email4){
        DadosVenda.email1 = DadosVenda.email4
      }else{
        DadosVenda.email1 = 'bruno@consorciogroscon.com.br';

        addToast({
          type: 'success',
          title: 'Aguarde',
          description: 'Estamos gerando a venda!'
        });
      }
    }
    setCarregando(true)
    passaFase6()
    addToast({
      type: 'success',
      title: 'Aguarde',
      description: 'Estamos gerando a venda!'
    });
    const response = await api.post(`v1/vendas`, DadosVenda)
    console.log(response.data)

    if (response.status === 201 || response.status === 504 || response.status === 200) {
      if (response.data.urlBoleto)
        window.open(response.data.urlBoleto, '_blank');

      addToast({
        type: 'success',
        title: 'Venda Realizada',
        description: 'Parabéns venda realizada com sucesso!'
      });

      history.push(`/consultaContrato`)
    }
  }

  function abrirModal() {
    setOpen(true)
    let grupo = DadosVenda.grupo;
    console.log(grupo)
    if(grupo==="69")
    grupo = "EM FORMAÇÃO"

    console.log(grupo)
    const numAss = assembleia.find(item => item.codigoGrupo === DadosVenda.grupo && item.dataAssembleia === DadosVenda.primeiraAssembleia)
    if (numAss !== undefined) {
      const termo = (DadosVenda.prazo) - (DadosVenda.planoBasico - numAss.numeroAssembleia + 2)
      setAlteraDados({
        ...DadosVenda,
        termos: termo,
        grupo: grupo,
        numAssembleiaAtual: numAss.numeroAssembleia
      })
    }
  }

  return (
    <>
      <Container>
        <Row between>
          <Col>
            Deseja contratar seguro de vida?
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="contratarSeguro"
              className="tipoPessoaRadio"
              onChange={handleChangeCheckPessoa}
              value={DadosVenda.contratarSeguro}
            >
              <Row>
                <Col>
                <FormControlLabel value="1" aria-label="teste" control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label="Sim" />
              <FormHelperText style={{ marginTop: 10, marginRight: 20 }}>(Parcela com Seguro: {DadosVenda.valorParcelaComSeguroFormatado})</FormHelperText>

                </Col>
                <Col>
                <FormControlLabel value="0" control={<Radio size="small" sx={{ color: 'var(--blue)', '&.Mui-checked': { color: 'var(--blue)' } }} />} label="Não" />
              <FormHelperText style={{ marginTop: 10, marginRight: 20 }}>(Parcela sem Seguro: {DadosVenda.valorParcelaFormatado})</FormHelperText>
         
                </Col>
              </Row>
              
                 </RadioGroup>
          </Col>
        </Row>
        <Row>

        </Row>
        {DadosVenda.contratarSeguro === 1 ? <>
          <Stack sx={{ width: '100%' }} spacing={2} style={{ marginLeft: "-20px" }}>
            <Alert severity="success" style={{ color: "green" }}>Preencher manualmente formulário de seguro e encaminhar para administradora!</Alert>
          </Stack>
        </> : <></>}

        <Row between style={{ marginTop: 35 }}>
          <Col>
            <Form.Group className="mb-3 teste right" as={Col} controlId="formGridEmail">
              <div className="botaoPesquisar">
                <Button variant="primary" type="submit" className="btnPesquisar" onClick={abrirModal}
                  disabled={
                    (DadosVenda.contratarSeguro !== "nao") &&
                      (DadosVenda.indenizarLegislacao !== "legislacao") &&
                      (
                        DadosVenda.cpfBeneficiario === "",
                        DadosVenda.nomeBeneficiario === "",
                        DadosVenda.rgBeneficiario === "",
                        DadosVenda.cepBeneficiario === "",
                        DadosVenda.enderecoBeneficiario === "",
                        DadosVenda.numeroBeneficiario === "",
                        DadosVenda.bairroBeneficiario === "",
                        DadosVenda.estadoBeneficiario === "",
                        DadosVenda.cidadeBeneficiario === "",
                        DadosVenda.telefoneBeneficiario === ""
                      )

                      ? true : false}>
                  <Typography variant="button" gutterBottom>PRÓXIMO</Typography>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={style}>
              <Typography>
                <Tabelas1>
                  <div className="title2" style={{ color: "var(--blue)" }}><div className='largura'></div><h4>RESUMO DA VENDA</h4> <FiXCircle onClick={handleClose} className='voltar1' /></div>
                  <div className="titulo title1" id="alto"><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5>INFORMAÇÕES DO CONSORCIADO</h5></Typography></div>
                  <div style={{ border: "solid rgb(236,236,236) 0.5px" }}>
                    <Row>
                      <Col sx={12} sm={4} className="fundo">
                        <b>{DadosVenda.pessoa === "fisica" ? 'CPF' : 'CNPJ'}:</b> {DadosVenda.documentoFormatado}
                      </Col>
                      <Col sx={12} sm={8} className="fundo">
                        <b>NOME:</b> {DadosVenda.nomeCliente}
                      </Col>
                    </Row>
                    <Row>
                      <Col sx={9} sm={9} className="fundo">
                        <b>ENDEREÇO:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.endereco : DadosVenda.enderecoCom}
                      </Col>
                      <Col sx={3} sm={3} className="fundo">
                        <b>NÚMERO:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.numero : DadosVenda.numeroCom}
                      </Col>
                    </Row>
                    <Row>
                      <Col sx={12} sm={5} className="fundo">
                        <b>BAIRRO:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.bairro : DadosVenda.bairroCom}
                      </Col>
                      <Col sx={4} sm={2} className="fundo">
                        <b>APTO:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.apto : DadosVenda.aptoCom}
                      </Col>
                      <Col sx={4} sm={5} className="fundo">
                        <b>COMPLEMENTO:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.complemento : DadosVenda.complementoCom}
                      </Col>
                    </Row>
                    <Row>
                      <Col sx={4} sm={3} className="fundo">
                        <b>ESTADO:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.uf : DadosVenda.estadoCom}
                      </Col>
                      <Col sx={8} sm={6} className="fundo">
                        <b>CIDADE:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.cidade : DadosVenda.cidadeCom}
                      </Col>
                      <Col sx={12} sm={3} className="fundo">
                        <b>CEP:</b> {DadosVenda.enderecoCobranca === "RES" ? DadosVenda.cep : DadosVenda.cepCom}
                      </Col>
                    </Row>
                  </div>
                </Tabelas1>
              </Typography>
            <Typography>
              <Tabelas1>
                <div className="titulo title1" ref={divAlto} style={{ marginTop: "30px" }}><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5>INFORMAÇÕES DO CONTRATO</h5></Typography></div>
                <div style={{ border: "solid rgb(236,236,236) 0.5px" }}>
                <Row>
                    <Col sx={6} sm={3} className="fundo">
                      <b>DATA VENDA:</b> {DadosVenda.dataAdesaoF}
                    </Col>
                    <Col sx={6} sm={2} className="fundo">
                      <b>GRUPO:</b> {DadosVenda.grupo==='69'?'EM FORMAÇÃO':DadosVenda.grupo}
                    </Col>
                    <Col sx={4} sm={2} className="fundo">
                      <b>PRAZO:</b> {DadosVenda.prazo}
                    </Col>
                    <Col sx={4} sm={3} className="fundo">
                      <b>TAXA ADM TOTAL:</b> {DadosVenda.taxaAdministracao}%
                    </Col>
                    <Col sx={4} sm={2} className="fundo">
                      <b>ADESÃO:</b> {DadosVenda.adesao}%
                    </Col>
                  </Row>
                  <Row>
                    <Col sx={6} sm={3} className="fundo">
                      <b>CÓDIGO BEM:</b> {DadosVenda.codB}
                    </Col>
                    <Col sx={6} sm={5} className="fundo">
                      <b>BEM:</b> {DadosVenda.descricaoBem}
                    </Col>
                    <Col sx={6} sm={4} className="fundo">
                      <b>PARCELA:</b> {DadosVenda.contratarSeguro === 0 ? DadosVenda.valorParcelaFormatado : DadosVenda.valorParcelaComSeguroFormatado}
                    </Col>
                  </Row>
                  <Row>
                    <Col sx={12} sm={6} className="fundo">
                      <b>VALOR CRÉDITO:</b> {DadosVenda.valorCreditoF}
                    </Col>
                    <Col sx={12} sm={6} className="fundo">
                      <b>PRIMEIRA ASSEMBLEIA:</b> {formatData(DadosVenda.primeiraAssembleia)}
                    </Col>
                  </Row>
                </div>

                <div className="titulo title1" style={{ marginTop: "30px" }}><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5>{!carregando ? "FORMA DE PAGAMENTO" : "CONTRATO ESTA SENDO GERADO!"}</h5></Typography></div>
                <div style={{ border: "solid rgb(236,236,236) 0.5px" }}>
                  {!carregando ? <>
                    <div className="testea">
                      <Button onClick={() => tipoPagamento("BOLETO")} style={DadosVenda.tipoPagamento === "RECIBO" ? { backgroundColor: "#677DAF", color: "rgba(255, 255, 255, 0.636)" } : { backgroundColor: "var(--blue)", color: "rgb(255, 255, 255)" }}><Typography variant="button" gutterBottom><FaBarcode style={{ marginRight: 10, alignItems: "center" }} size={20}></FaBarcode>BOLETO</Typography></Button>
                      <Button onClick={() => tipoPagamento("RECIBO")} style={DadosVenda.tipoPagamento === "BOLETO" ? { backgroundColor: "#677DAF", color: "rgba(255, 255, 255, 0.636)", marginLeft: 10 } : { backgroundColor: "var(--blue)", color: "rgb(255, 255, 255)", marginLeft: 10 }} className={DadosVenda.tipoPagamento === "RECIBO" ? "ativo" : ""}><Typography variant="button" gutterBottom><CgNotes style={{ marginRight: 10, alignItems: "center" }} size={20}></CgNotes >RECIBO</Typography></Button>
                    </div>

                    {DadosVenda.tipoPagamento === "RECIBO" ? <>
                      <div>
                        <div style={{ color: "var(--blue)", marginLeft: 20 }}><h5>RECIBO</h5></div>

                        <MRow btMargin>
                          <Col style={{ backgroundColor: "rgb(247,247,247" }}>
                            <FormControl variant="standard" style={{ width: "100%" }} >

                              <TextField
                                style={{ width: "100%", marginTop: 10 }}
                                InputLabelProps={{ shrink: DadosVenda.reciboAdesao > 0 ? true : false }}
                                label="Adesão / Administração"
                                value={DadosVenda.reciboAdesao > 0 ? DadosVenda.reciboAdesao : ""}
                                onChange={handleChangeInput}
                                variant="standard"
                                name="reciboAdesao"
                                id="formatted-numberformat-input"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </FormControl>
                          </Col>
                        </MRow>
                        <MRow>
                          <Col style={{ backgroundColor: "rgb(247,247,247" }}>
                            <FormControl variant="standard" style={{ width: "100%" }} >
                              <TextField
                                style={{ width: "100%", marginTop: 10 }}
                                InputLabelProps={{ shrink: DadosVenda.reciboPrimeiraParcela > 0 ? true : false }}
                                label="Pagamento da 1º contribuição"
                                value={DadosVenda.reciboPrimeiraParcela > 0 ? DadosVenda.reciboPrimeiraParcela : ""}
                                onChange={handleChangeInput}
                                variant="standard"
                                name="reciboPrimeiraParcela"
                                id="formatted-numberformat-input"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </FormControl>
                          </Col>
                        </MRow>

                        <MRow>
                          <Col style={{ backgroundColor: "rgb(247,247,247" }}>
                            <FormControl variant="standard" style={{ width: "100%" }} >

                              <TextField
                                style={{ width: "100%", marginTop: 10 }}
                                InputLabelProps={{ shrink: (DadosVenda.reciboPrimeiraParcela > 0 ? DadosVenda.reciboPrimeiraParcela / 100 : 0 + DadosVenda.reciboAdesao > 0 ? DadosVenda.reciboAdesao : 0) > 0 ? true : false }}
                                disabled
                                label="Recebemos a importância supra de"

                                value={(DadosVenda.reciboPrimeiraParcela > 0 ? DadosVenda.reciboPrimeiraParcela : 0) + parseInt(DadosVenda.reciboAdesao > 0 ? DadosVenda.reciboAdesao : 0)}
                                //value={(DadosVenda.reciboPrimeiraParcela+DadosVenda.reciboAdesao) > 0?new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(DadosVenda.reciboAdesao):{}}
                                variant="standard"
                                id="formatted-numberformat-input"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                              />
                            </FormControl>
                          </Col>
                        </MRow>

                        <MRow btMargin>
                          <Col style={{ backgroundColor: "rgb(247,247,247" }}>
                            <FormControl variant="standard" style={{ width: "100%" }} >
                              <TextField
                                style={{ width: "100%", marginTop: 10 }}
                                InputLabelProps={{ shrink: ((DadosVenda.reciboPrimeiraParcela > 0 ? DadosVenda.reciboPrimeiraParcela : 0) + (DadosVenda.reciboAdesao > 0 ? DadosVenda.reciboAdesao : 0)) > 0 ? true : false }}
                                disabled
                                label="Valor por extenso"
                                value={numero.porExtenso(((DadosVenda.reciboPrimeiraParcela > 0 ? DadosVenda.reciboPrimeiraParcela : 0) / 100 + (DadosVenda.reciboAdesao > 0 ? DadosVenda.reciboAdesao : 0) / 100), numero.estilo.monetario).toUpperCase()}
                                //value={(DadosVenda.reciboPrimeiraParcela+DadosVenda.reciboAdesao) > 0?new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(DadosVenda.reciboAdesao):0}
                                variant="standard"
                                id="formatted-numberformat-input"
                              />
                            </FormControl>
                          </Col>
                        </MRow></div>
                    </> : <></>}

                    {DadosVenda.tipoPagamento !== "" ? <>

                      <div className="teste">
                        <div className="titulo title1" style={{ marginTop: "30px" }}><Typography style={{ border: "none", margin: "0" }} gutterBottom><h5>ASSINATURA DO CONTRATO</h5></Typography></div>
                        <div style={{ border: "solid rgb(236,236,236) 0.5px" }}>
                          <div className="testea">
                            <Button onClick={() => tipoAssinatura("MANUAL")} style={DadosVenda.tipoAssinatura === "DIGITAL" ? { backgroundColor: "#677DAF", color: "rgba(255, 255, 255, 0.636)" } : { backgroundColor: "var(--blue)", color: "rgb(255, 255, 255)" }}><Typography variant="button" gutterBottom><FaFileSignature style={{ marginRight: 10, alignItems: "center" }} size={20}></FaFileSignature>ASSINATURA MANUAL</Typography></Button>
                            <Button onClick={() => tipoAssinatura("DIGITAL")} style={DadosVenda.tipoAssinatura === "MANUAL" ? { backgroundColor: "#677DAF", color: "rgba(255, 255, 255, 0.636)", marginLeft: 10 } : { backgroundColor: "var(--blue)", color: "rgb(255, 255, 255)", marginLeft: 10 }} className={DadosVenda.tipoPagamento === "RECIBO" ? "ativo" : ""}><Typography variant="button" gutterBottom><FaCloud style={{ marginRight: 10, alignItems: "center" }} size={20}></FaCloud >ASSINATURA DIGITAL</Typography></Button>
                          </div>
                          {DadosVenda.tipoAssinatura === "MANUAL" ? <>
                            <MRow>
                              <Col style={{ backgroundColor: "rgb(247,247,247" }}>
                                <FormControl variant="standard" style={{ width: "100%" }} >
                                  <TextField
                                    style={{ width: "100%", marginTop: 10 }}
                                    InputLabelProps={{ shrink: DadosVenda.emailGeral.length > 0 ? true : false }}
                                    label="Digite um email para enviar contrato"
                                    value={DadosVenda.emailGeral}
                                    onChange={handleChangeInput2}
                                    variant="standard"
                                    name="emailAssinaturaManual"
                                    id="formatted-numberformat-input"
                                  />
                                </FormControl>
                              </Col>
                            </MRow>
                          </> : <></>}
                        </div>
                      </div>

                      <div className="testeb" id="baixo" ref={testRef}>
                        <Button
                          disabled={DadosVenda.tipoAssinatura ? false : true}
                          style={{ backgroundColor: "var(--blue)" }}
                          onClick={efetivarVenda}
                        >
                          <Typography variant="button" gutterBottom>EFETIVAR VENDA</Typography>
                        </Button>
                      </div>
                    </> : <>
                    </>}
                  </> : <>
                    <div className="testea">
                      <Box sx={{ display: 'flex' }} style={{}}>
                        <CircularProgress />
                      </Box>
                    </div>
                  </>}
                </div>
              </Tabelas1>
            </Typography>
          </Box>
        </Modal>
      </Container>
    </>
  )
}
import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header';
import { Container, CardContainer, Tabelas, PrimeiraLetra } from './styles';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import api from '../../../../services/api';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import TextField from '@mui/material/TextField';
import { useToast } from '../../../../hooks/toast';
import { AxiosError } from 'axios';
import { CotasAssembleia } from '../../../../hooks/cotasAssembleia';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import carro from '../../../../assets/carro.jpg';
import { MdDelete } from 'react-icons/md';
import Switch from '@mui/material/Switch';
import { CotaAssembleia } from '../../../../interfaces/cotaAssembleia';

interface CotaAssembleia2 {
	dataAssembleia: string,
	codigoGrupo: number,
	codigoCota: number,
	tipoLance: string,
	percentualLance: number,
	codigoRepresentante: string,
}


const FormCadastroAssembleia: React.FC = () => {

	const history = useHistory();
	const { id } = useParams() as any
	const { addToast } = useToast();
	const [age, setAge] = React.useState<Date | null>(null);
	const [dataAss, setDataAss] = useState<string>('');
	const { cotasAssembleiaListaCard, getAll, getAllItems, cotasAssembleiaLista, deleteCotas } = CotasAssembleia();
	const [checked, setChecked] = React.useState(false);

	useEffect(() => {
		console.log('chegando aqui')
		if (dataAss) {
			console.log('chegando aqui2')
			getAll({dataAssembleiaInicial: dataAss, dataAssembleiaFinal: dataAss, somenteDataLiberada: false})
			getAllItems(dataAss, false)
		}
	}, [dataAss])

	async function deleteCota(id: number){
		await deleteCotas(id)
		getAll({dataAssembleiaInicial: dataAss, dataAssembleiaFinal: dataAss, somenteDataLiberada: false})
		getAllItems(dataAss, false)

			addToast({
				type: 'error',
				title: 'Deletado!',
				description: 'Cota deletada com sucesso!'
			})
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		console.log({dataAssembleiaInicial: dataAss, dataAssembleiaFinal: dataAss, somenteDataLiberada: false})
	};

	const [model, setModel] = useState<CotaAssembleia2>({
		dataAssembleia: '',
		codigoGrupo: 0,
		codigoCota: 0,
		tipoLance: '',
		percentualLance: 0,
		codigoRepresentante: ''
	})


	function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
		setModel({
			...model,
			[e.target.name]: e.target.value
		});
	}

	function limpaCampos() {
		console.log(cotasAssembleiaLista)
		setModel({
			...model,
			codigoCota: 0,
			codigoGrupo: 0,
			percentualLance: 0,
			tipoLance: '',
			codigoRepresentante: '',
		})
	}

	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
		e.preventDefault()
		console.log(model)
		try {
			const response = await api.post('/v2/lances-ofertados-manuais/', model)
			console.log(response)
			if (response.status === 201) {
				addToast({
					type: 'success',
					title: 'Dados Salvos',
					description: 'Dados foram salvos com sucesso!'
				})

				limpaCampos()
				getAll({dataAssembleiaInicial: dataAss, dataAssembleiaFinal: dataAss, somenteDataLiberada: false})
				getAllItems(dataAss, false)
			} else {
				addToast({
					type: 'error',
					title: 'Erro ao tentar salvar!',
					description: 'Informe este erro ao TI do consórcio2!'
				})
			}
		} catch (err) {
			const error = err as AxiosError
			let erroMensagem = error.response?.data.developerMessage
			let verificaErro = erroMensagem?.slice(0, 27)
			if (verificaErro === '[Assembleia] não encontrada') {
				addToast({
					type: 'error',
					title: 'Erro data Assembleia!',
					description: 'Data de assembleia não pertence a este grupo, verifique a data!'
				})
			} else {
				addToast({
					type: 'error',
					title: 'Erro ao tentar salvar!',
					description: 'Informe este erro ao TI do consórcio!'
				})
			}

		}
	}

	function adicionaZero(numero: number) {
		if (numero <= 9)
			return "0" + numero;
		else
			return numero;
	}

	function back() {
		history.goBack()
	}

	const handleChangeDate = (newValue: Date | null) => {
		if (newValue != undefined) {
			setAge(newValue)
			console.log(age)
			let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
			let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
			setModel({ ...model, dataAssembleia: DataFormatada })
			setDataAss(DataFormatada)
		}
	};

	async function teste(){
		const response = await api.post('/v2/lances-ofertados-manuais/importar', { dataAssembleia: dataAss})
		getAll({dataAssembleiaInicial: dataAss, dataAssembleiaFinal: dataAss, somenteDataLiberada: false})
				getAllItems(dataAss, false)
	}

	function ordernarTipoLance(dados:CotaAssembleia[]) {
		const sorteio = dados.filter(item => item.tipoLance==="SORTEIO")
		const livre = dados.filter(item => item.tipoLance==="LIVRE")
		const fixo = dados.filter(item => item.tipoLance==="FIXO")
		const livreOfertado = dados.filter(item => item.tipoLance==="LANCE_OFERTADO_LIVRE")
		const fixoOfertado = dados.filter(item => item.tipoLance==="LANCE_OFERTADO_FIXO")
		const ordenadoFinal = sorteio.concat(livre).concat(fixo).concat(livreOfertado).concat(fixoOfertado)
		console.log('dentro da função de ordenação tipo')
		console.log(ordenadoFinal)

return ordenadoFinal

}



	return (
		<>
			<Header />
			<Container>

				<CardContainer>

					<div className='title'><h2>Cadastro Cota Assembleia</h2></div>
					<Form className="form-border" onSubmit={onSubmit}>
						<Row>
							<Col>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
							<DesktopDatePicker
								label="Data da Assembleia"
								inputFormat="dd/MM/yyyy"
								value={age}
								onChange={handleChangeDate}
								className="teste"

								renderInput={(params) => <TextField {...params}
									sx={{ maxWidth: 220, alignItems: "right" }}
								//InputLabelProps={{ shrink: age !== "" ? true : false }}
								/>
								}
							/>
						</LocalizationProvider>
							</Col>
							<Col>
							<div className="buttonsCargo">
							<Button onClick={teste}>Importar Lances Newcon</Button>
							</div>
							
							</Col>
						</Row>
						
						

						<Row style={{ marginTop: 40 }}>
							<Col xs={2}>
								<Form.Group className="inputCargo">
									<Form.Label>Grupo</Form.Label>
									<Form.Control
										style={{ maxWidth: 110 }}
										type="string"
										name="codigoGrupo"
										placeholder="Grupo"
										value={model.codigoGrupo.toString()}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
									/>
								</Form.Group>
							</Col>
							<Col xs={2}>
								<Form.Group className="inputCargo">
									<Form.Label>Cota</Form.Label>
									<Form.Control
										style={{ maxWidth: 110 }}
										type="string"
										name="codigoCota"
										placeholder="Cota"
										value={model.codigoCota.toString()}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
									/>
								</Form.Group>
							</Col>
							<Col xs={2}>
								<Form.Group className="inputCargo">
									<Form.Label>% Lance</Form.Label>
									<Form.Control
										type="string"
										name="percentualLance"
										placeholder="Percentual Lance"
										value={model.percentualLance}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
									/>
								</Form.Group>
							</Col>
							<Col xs={2}>
								<Form.Group className="inputCargo">
									<Form.Label>Representante</Form.Label>
									<Form.Control
										type="string"
										name="codigoRepresentante"
										placeholder="Cód. Representante"
										value={model.codigoRepresentante}
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
									/>
								</Form.Group>
							</Col>
							<Col xs={4}>
								<Form.Group className="inputCargo">
									<Form.Label>Tipo Contemplação</Form.Label>
									<Form.Control
										as="select"
										value={model.tipoLance}
										name="tipoLance"
										onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
										aria-label="Default select example" style={{ maxWidth: 220 }}>
										<option></option>
										<option value="SORTEIO">SORTEIO</option>
										<option value="LIVRE">LIVRE</option>
										<option value="FIXO">FIXO</option>
										<option value="LANCE_OFERTADO_LIVRE">LANCE OFERTADO LIVRE</option>
										<option value="LANCE_OFERTADO_FIXO">LANCE OFERTDADO FIXO</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<div style={{ marginTop: 20 }}>Visualizar Cards</div>
								<Switch
									checked={checked}
									onChange={handleChange}
									inputProps={{ 'aria-label': 'controlled' }}
								/>	</Col>
							<Col><div className="buttonsCargo"><Button onClick={limpaCampos}>Limpar</Button>
								<Button type="submit">Adicionar</Button></div></Col>




						</Row>
					</Form>
					{checked?<>
					<Row>
						{cotasAssembleiaListaCard[0]?.grupos.map(itemGrupo =>
							<Col>
								<Card sx={{ maxWidth: 345 }} style={{ margin: '10px' }}>
									<CardMedia
										component="img"
										height="140"
										image={carro}
										alt="green iguana"
									/>
									<CardContent>
										<Typography gutterBottom variant="h5" component="div">
											{itemGrupo.codigoGrupo}
										</Typography>
										{itemGrupo.tiposLance.map(itemTipoContemplacao =>
											<>
												<Typography gutterBottom component="div">
													{itemTipoContemplacao.tipoLance}
												</Typography>
												{itemTipoContemplacao.lances.map(itemCotas =>
													<>
														<Typography variant="body2" sx={{ color: 'text.secondary' }}>
															{itemCotas.codigoCota}
															{itemTipoContemplacao.tipoLance === 'SORTEIO' ? '' : ` - ${itemCotas.percentualLance}%`}
														</Typography>
													</>
												)}
											</>
										)}
									</CardContent>
								</Card>
							</Col>

						)}
					</Row>
					</>:<>
					<Tabelas>
						<Table bordered hover className='tabela'>
							<thead>
								<tr>
									<th></th>
									<th>Grupo</th>
									<th>Cota</th>
									<th>Tipo</th>
									<th>Perc.</th>
									<th>Deletar</th>

								</tr>
							</thead>
							<tbody>
								{cotasAssembleiaLista.map((item) =>
									<tr className="linha">
										<td
											className="celula"
											key={item.id}
											id={item.id.toString()}
										>
											<PrimeiraLetra style={{ backgroundColor: `var(--blue)` }}>
												{item.tipoLance.substring(0, 1).toUpperCase()}
											</PrimeiraLetra>
										</td>
										<td>
											{item.codigoGrupo}
										</td>
										<td>
											{item.codigoCota}
										</td>
										<td>
											{item.tipoLance}
										</td>
										<td>
											{item.percentualLance}
										</td>
										<td>
											<td onClick={() => deleteCota(item.id)} className="del"><MdDelete size={20}></MdDelete></td>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Tabelas>
					</>}
				</CardContainer>
			</Container>


		</>
	)
}

export default FormCadastroAssembleia
import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { Container, Tabelas, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { FiPlus } from 'react-icons/fi';
import { Table, Button, Modal } from 'react-bootstrap';
import { ParceirosDados } from '../../hooks/parceiro';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import usePagination from '../../components/PaginationParceiro';
import { Parceiro } from '../../interfaces/parceiros';


const Parceiros: React.FC = () => {
  const history = useHistory();
  const idCargo = useRef(null);
  const { getAll, parceiroLista } = ParceirosDados();
  const [checked, setChecked] = React.useState(false);
  const [listaSearchParceiro, setListaSearchParceiro] = useState<Parceiro[]>(parceiroLista);
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(listaSearchParceiro.length / PER_PAGE);
  const _DATA = usePagination(listaSearchParceiro, PER_PAGE);
  console.log(_DATA)
  const ParceiroCor = localStorage.getItem('@Gscon:ParceiroCor')
  

  const handleChangePagination = (event: object, e:number) => {
    console.log(e)
    setPage(e);
    _DATA.jump(e);
  };

  function novoCargo() {
    history.push(`/parceiroCadastro/`)
  }

  function editParceiro(id:number){
    history.push(`/parceiroCadastro/${id}`)
  }

  function handleSearch(event: ChangeEvent<HTMLInputElement>) {
    const lista: Parceiro[] = parceiroLista.filter(item => event.target.value ? item.nome.toLowerCase().includes(event.target.value.toLowerCase()) : item);

    setListaSearchParceiro(lista);

    setPage(1)
    _DATA.jump(1);
  }

  useEffect(() => {
    getAll();
  }, [getAll])

  useEffect(() => {
    setListaSearchParceiro(parceiroLista)
  }, [parceiroLista])

  function randomDarkRGB() {
    var r = Math.floor(Math.random() * 156);
    var g = Math.floor(Math.random() * 156);
    var b = Math.floor(Math.random() * 156);
    var bgColor = "rgb(" + r + "," + g + "," + b + ")";
    return bgColor;
  }

  return (
    <>
      <Header />
      <Container>
        <div className='title' style={{background:ParceiroCor||""}}><h2>Parceiros</h2></div>
        <Tabelas>
          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>
              {_DATA.currentData().map((item) =>
                <tr className="linha">
                  <td
                    className="celula"
                    key={item.id}
                    ref={idCargo}
                    id={item.id.toString()}
                    onClick={()=>editParceiro(item.id)}  
                    
                    >
                    <PrimeiraLetra style={{ backgroundColor: `${item.cor}` }}>
                      {item.nome.substring(0, 1).toUpperCase()}
                    </PrimeiraLetra>
                    {item.nome.toUpperCase()}
                  </td>
                </tr>
              )}
               <tr>
                <td>
                  <Pagination className='pagination' count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
                </tr>
            </tbody>
          </Table>

          <Botao>
            <Button variant="primary" onClick={novoCargo} style={{background:ParceiroCor||"", border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems:"center"}}><FiPlus size={30}/></Button>
          </Botao>
        </Tabelas>


      </Container>
    </>
  )
}

export default Parceiros
import styled from "styled-components";

export const Container = styled.div`

   //width: 1400px;
      display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   margin: none;
   padding: .7em;

   body{
  font-family: sans-serif;
}



.fab{
  display: flex ;
  position: fixed;
  bottom:10px;
  right:10px;
  align-items: center;
  justify-content: center;

}

.teste{
  margin-bottom: 5px
}

.teste56{
  margin-bottom: 5px
}

.fab button{
  cursor: pointer;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  background-color: var(--blue);
  border: none;
  box-shadow: 0 1px 5px rgba(0,0,0,.4);
  font-size: 24px;
  color: white;

  
    
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.fab button:focus{
  outline: none;
}

.fab button.main{
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--blue);
  right: 0;
  bottom: 0;
  z-index: 20;
}

.fab button.main:before{
  content: '⏚';
}

.fab ul{
  position:absolute;
  bottom: 0;
  right: 0;
  padding:0;
  padding-right:5px;
  margin:0;
  list-style:none;
  z-index:10;
  
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.fab ul li{
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: -30%;
  opacity: 0;
  
  -webkit-transition: .3s ease-out;
  -moz-transition: .3s ease-out;
  transition: .3s ease-out;
}

.fab ul li label{
  width: 100px;
  margin-right:10px;
  white-space: nowrap;
  display: block;
  margin-top: 10px;
  padding: 5px 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  border-radius:3px;
  height: 30px;
  font-size: 16px;
  pointer-events: none;
  opacity:0;
  
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus{
  outline: none;
  background-color: var(--blue);
  box-shadow: 0 3px 8px rgba(0,0,0,.5);
 }
 
.fab button.main:active:before,
.fab button.main:focus:before{
  content: '↑';
}

.fab button.main:active + ul,
.fab button.main:focus + ul{
  bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li{
  margin-bottom: 10px;
  opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label{
  opacity: 1;
}

   ul {
    list-style:none;
    padding-left:0;
}

.MuiCircularProgress-colorSecondary{
  color: red;
}

.voltar1{
  display: flex;
        font-size: 20px;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 3px;
      }


   .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      margin-right: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }

   .teste{
       align-items: right;
   }

   .labelBem{
     margin-bottom: 30px;
   }

   .botaoPesquisar{
      display: flex ;
      flex-direction: row-reverse;

   }

   .btnPesquisar{
     background-color: var(--blue);
     width: 120px;
     height: 45px;
     font-size: 20px;
     margin-left: 15px;
     margin-right: 15px;
     margin-bottom: 15px;
   }


   .btnPesquisar1{
     background-color: #f3f3f3;
     color: var(--blue);
     border: none;
     width: 180px;
     height: 45px;
     font-size: 20px;
     margin-top: 20px;
     margin-bottom: 40px;
     font-weight: bold; 
   }

   .btnPesquisar2{
     background-color: #f3f3f3;
     color: var(--blue);
     border: none;
     width: 180px;
     font-size: 20px;
     font-weight: bold; 
   }



   .inputNovo{
     width: 100%
   }

   .datePick{
        width: 100%;
        margin-top: 0px;
        margin-left: 7px;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border: none;

        .Mui-focused{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;
        }
        


        .MuiTextField-root{
            width: 100%;
           
        }

        .MuiFormLabel-filled{
            font-size: 16px;
            position: absolute;
            top: -5px;
            left: -12px;
            
        }

        .MuiInputLabel-outlined{
          border-width: 0;
            font-size: 17px;
            position: absolute;
            top: 8px;
            left: -14px;
            
            
        }
        .MuiOutlinedInput-input{
          border-width: 0;
            height: 24px;
            margin-top: 30px;
            padding: 0;
            padding-bottom: 3px;
        } 
        

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          height: 24px;
          margin-top: 30px;
          padding: 0;
          padding-bottom: 3px;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline{
          border-bottom-width: 2px;
        }
 
       .Mui-focused .MuiOutlinedInput-notchedOutline{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;

        }

        .MuiOutlinedInput-root .MuiInputBase-root .MuiInputBase-colorPrimary .MuiInputBase-formControl .MuiInputBase-adornedEnd {
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;
        }
       


        fieldset{
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-color: rgba(0, 0, 0, 0.43);

            legend{
                
                span{
                    font-size: 16px;
                }
            }
        }
    }

   .tituloBemSel{
     width: 100%;
     margin-top: 90px;
     height: 40px;
     color: var(--blue);
     //background-color: var(--blue);
     text-align: center;
      align-items: center;
      vertical-align: middle;

      spam{

      }
   }

   .bemSelecionado{
     border: solid #ECECEC 0.8px;
     padding: 20px;
     margin-top: 60px;

   }

   .tituloBem{
     margin-top: 10px;
     margin-bottom: 20px;
   }

   .direita1{
     display: flex;
     //flex-direction: row;
     //align-items: right;
     float: right;
     align-content: flex-end;
    
   }


    .labelRenda{
      width: 100%;

      .inputRenda{
      border: 0;
      border-bottom: solid 0.5px #212121;
      font-size: 18px;
      margin-top: 18px;
      position: absolute;
      width: 100%;


      :focus{
        border: 0;
        border-bottom: solid 0.5px #212121;
        font-size: 18px;
        position: absolute;
      }
    }
    }

    .title-blue{
      .title-carac{
        color: var(--blue);
        font-size: 16px;
      }
      .text-carac{
        font-size: 13px;
      }
    }

    .no-margin{
      .title-carac{
        margin: 0;        
      }
      .text-carac{
        margin: 0 0 0 10px;
      }
    }



`;

export const Tabela = styled.div`


`;

export const Tabelas = styled.div`
   max-width: 1100px;
   width: 100%;

    .border{
      padding: 24px;
    }

    .MuiCircularProgress-colorSecondary{
  color: red;
}



   .tabela{
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 5px;
     vertical-align: center;
     box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);


   }

   .tabela3{
      margin-top: 10px;
     vertical-align: center;
     box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);


   }

   .tabela4{
     vertical-align: center;
     box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);


   }

   .css-19kzrtu {
      padding: 0px;
    }

   .celula{
      display: flex;
      align-items: center;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
   }
   
   .pesquisa{
      width: 100%;
   }

   .pagination{
      display: flex;
      justify-content: center;
      border-top: red;
     margin-bottom: 5px;
   }

   thead{
      //margin-bottom: 2px;
      tr, th{
         div{
            margin-bottom: 7px;
         }
         //height: 60px;
      }
   }

   .fab button{
  cursor: pointer;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  background-color: var(--blue);
  border: none;
  box-shadow: 0 1px 5px rgba(0,0,0,.4);
  font-size: 24px;
  color: white;

  
    
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.main13{
  display: flex;
  align-items: center;
  justify-content: center;

}

   .Mui-selected {
    background-color: #ffffff;
    border-bottom: solid 3px;
    border-color: var(--blue);
    font-weight: bold;
   }



   .table-bordered>:not(caption)>*>* {
    border-width: 0 0;
   }

   .table>:not(:first-child) {
      border-top: 0px solid currentColor;
      
   }
   



`;

export const Tabelas1 = styled.div`
    max-width: 1100px;
    font-size: 13px;

    overflow-x: hidden; 

    .MuiCircularProgress-colorSecondary{
    color: red;
}

.icones{
    //margin-right: 5px;
    color: #616161;
    background: #ffffff ;
    border: none;
    //width: 40px;
     }

    //border-left: solid 0.5px;
    //border-left-color: #eeebeb;
    //border-right: solid 0.5px;
    //border-right-color: #eeebeb;
    //box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);


    .botoesSimulacao{
      box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);
      border: red solid;
    }

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

   .title1{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }



    .semSeguro{
        font-size: 10px;
        color: #b03232;
    }

    .MuiSvgIcon-Root {
        color: var(--blue);
      background-color: #b03232;
    }

    .comSeguro{
        font-size: 10px;
        color: var(--blue);
    }

    .meses{
        border: none;
    }

    .nome{
        max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    }

    th, td{
        //border: none;

    }

    td, th{
       white-space: nowrap;
       padding: 0;

    }

    .quebraLinha{
        white-space: nowrap;
    }

    .teste{
      font-size: 12px;
    }

    .ac{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       max-width: 145px;
       white-space: nowrap;
       padding-right: 10px;
    }

    .ac1{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }

    .ac2{
       text-align: left;
       align-items: center;
       vertical-align: middle;
    }

    .tamanhoAC{
      //width: 100px;
       height: 40px;
       padding: none;
       
    }

   
   

   .tabela{
     vertical-align: center;
     
   }

   .celula{
      display: flex;
      align-items: center;

   }

   .del{
       cursor: pointer;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
      border-bottom: none;
   }

   .esquerda{
       padding-left: 60px;
   }


   .esquerda1{
       padding-left: 20px;
   }

   .mouse{
     cursor: pointer;
   }

   .bord{
      background-color: #f8f8f8;
      //color: red;
   }

   .marg{
     margin-bottom: 10px;
   }

   .tam1{
     width: 50px;
   }

   .tam1i{
     width: 100px;
   }

   .tam2{
     width: 200px;
   }

   .tam2i{
     width: 210px;
   }

   .tam3{
     width: 700px;
   }

   .fontTI{
     font-size: 10px;
   }

   .n{
    font-weight: bold;
    font-size: 14px;
   }

   .tam{
    font-size: 14px;
    
   }

   .redm{
     display: flex;

     justify-content: space-around;
   }

   .testea{
     display: flex;
     margin-top: 50px;
     justify-content: flex-end;
     margin-right: 10px;

   }

   .paginacao{
     display: flex;
     justify-content: center;
     margin-top: 5px;
     margin-bottom: 5px;
   }

   

`;

export const Tabelas2 = styled.div`
    max-width: 1100px;
    font-size: 10px;
    padding-left: 24px;
    padding-right: 24px;
    //border-left: solid 0.5px;
    //border-left-color: #eeebeb;
    //border-right: solid 0.5px;
    //border-right-color: #eeebeb;
    //box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);


    .botoesSimulacao{
      box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);
      border: red solid;
    }

    .MuiCircularProgress-colorSecondary{
  color: red;
}

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

   .title1{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

    .semSeguro{
        font-size: 10px;
        color: #b03232;
    }

    .MuiSvgIcon-Root {
        color: var(--blue);
      background-color: #b03232;
    }

    .comSeguro{
        font-size: 10px;
        color: var(--blue);
    }

    .meses{
        border: none;
    }

    .nome{
        max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    }

    th, td{
        border: none;

    }

    td, th{
       white-space: nowrap;
       padding: 0;

    }

    .quebraLinha{
        white-space: nowrap;
    }

    .ac{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }

    .ac1{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }

    .ac2{
       text-align: left;
       align-items: center;
       vertical-align: middle;
    }

    .tamanhoAC{
      //width: 100px;
       height: 40px;
       padding: none;
    }

   
   

   .tabela{
     vertical-align: center;
     
   }

   .celula{
      display: flex;
      align-items: center;

   }

   .del{
       cursor: pointer;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
      border-bottom: none;
   }

   .esquerda{
       padding-left: 60px;
   }


   .esquerda1{
       padding-left: 20px;
   }

   .mouse{
     cursor: pointer;
   }

   .bord{
      background-color: #f8f8f8;
      //color: red;
   }

   .marg{
     margin-bottom: 10px;
   }

   .tam1{
     width: 50px;
   }

   .tam1i{
     width: 100px;
   }

   .tam2{
     width: 200px;
   }

   .tam2i{
     width: 210px;
   }

   .tam3{
     width: 700px;
   }

   .fontTI{
     font-size: 12px;
   }

   .n{
    font-weight: bold;
    font-size: 14px;
   }

   .tam{
    font-size: 10px;
    
   }

   .redm{
     display: flex;

     justify-content: space-around;
   }

   .testea{
     display: flex;
     margin-top: 50px;
     justify-content: flex-end;
     margin-right: 10px;

   }

   .paginacao{
     display: flex;
     justify-content: center;
     margin-top: 5px;
     margin-bottom: 5px;
   }



   

`;


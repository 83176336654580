import React, { useEffect, useState } from 'react';
import { Container, Tabelas1 } from './styles';
import { Row, Col, Button, Form, Card, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { logoGroscon } from '../../../mock/logo';
import sorteio from '../../../assets/sorteio.jpg';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import carro from '../../../assets/carro.jpg';
import caminhao from '../../../assets/caminhao.jpg';
import imovel from '../../../assets/imovel.jpg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';


import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { BsListCheck, BsPrinterFill, BsFillEyeFill, BsFillCloudArrowUpFill, BsGlobe } from 'react-icons/bs';

import moment from 'moment';
import { CotasAssembleia } from '../../../hooks/cotasAssembleia';
import { CotaAssembleiaCard } from '../../../interfaces/cotaAssembleiaCard';
import { Height } from '@mui/icons-material';
import { padding } from 'polished';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 700,
	height: '90%',
	bgcolor: 'background.paper',
	border: 'none',
	boxShadow: 24,
	overflow: 'auto',
	p: 4,
	padding: 2,
};

interface DadosGrupo {
	codigoGrupo: number,
	tiposLance: [
		{
			tipoLance: string,
			lances: [
				{
					id: number,
					dataAssembleia: string,
					percentualLance: number,
					codigoCota: number,
					codigoRepresentante: string,
				}
			]
		}
	]
}

interface OrdenarTipoLance {
	tipoLance: string,
	lances: [
		{
			id: number,
			dataAssembleia: string,
			percentualLance: number,
			codigoCota: number,
			codigoRepresentante?: string,
		}
	]
}

interface Lances {
			id: number,
			dataAssembleia: string,
			percentualLance: number,
			codigoCota: number,
			codigoRepresentante?: string,
}



const Resultados = (props: any) => {
	const { addToast } = useToast();
	moment.locale('pt-br');
	const { filtros, dataAssembleiaFormatada } = props
	const { cotasAssembleiaListaCard, getAll, getAllItems, cotasAssembleiaLista, deleteCotas } = CotasAssembleia();
	const ParceiroNome: string = localStorage.getItem('@Gscon:ParceiroNome') || ""
	const ParceiroImagem: any = localStorage.getItem('@Gscon:ParceiroImagem') || ""
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(true);
	const handleCloseModal = () => setOpenModal(false);
	const [dadosModal, setDadosModal] = useState<DadosGrupo>();
	const [juncao, setJuncao] = useState<OrdenarTipoLance>();
	const [visible, setVisible] = useState<boolean>(false)
	const [visible2, setVisible2] = useState<boolean>(false)
	const ParceiroCor = localStorage.getItem('@Gscon:ParceiroCor')||'#133483'

	function fecharModal(){
		setVisible(false)
		setVisible2(false)
		handleCloseModal()
	}

	function ordernarTipoLance(dados: [OrdenarTipoLance]) {
		const sorteio = dados.filter(item => item.tipoLance === "SORTEIO")
		const livre = dados.filter(item => item.tipoLance === "LIVRE")
		const fixo = dados.filter(item => item.tipoLance === "FIXO")
		const livreOfertado = dados.filter(item => item.tipoLance === "LANCE_OFERTADO_LIVRE")
		const fixoOfertado = dados.filter(item => item.tipoLance === "LANCE_OFERTADO_FIXO")
		const ordenadoFinal = sorteio.concat(livre).concat(fixo).concat(livreOfertado).concat(fixoOfertado)
		console.log('dentro da função de ordenação tipo')
		console.log(ordenadoFinal)

		return ordenadoFinal

	}

	function juntarTipoLance(dados: [OrdenarTipoLance]) {
		const sorteio = dados.filter(item => item.tipoLance === "SORTEIO")
		const livre = dados.filter(item => item.tipoLance === "LIVRE")
		const fixo = dados.filter(item => item.tipoLance === "FIXO")
		const livreOfertado = dados.filter(item => item.tipoLance === "LANCE_OFERTADO_LIVRE")
		const fixoOfertado = dados.filter(item => item.tipoLance === "LANCE_OFERTADO_FIXO")
		const ordenadoFinal = sorteio.concat(livre).concat(fixo).concat(livreOfertado).concat(fixoOfertado)
		console.log('dentro da função de ordenação tipo')
		console.log(ordenadoFinal)

		return ordenadoFinal

	}


	

	function retornaLances(tipoLance: string,lances: [Lances]){
		let resposta = (``);
		lances.map((cotas, index) =>
		resposta += `${tipoLance === "LANCE_OFERTADO_LIVRE" ? leftPad(index + 1,3) : tipoLance === "LANCE_OFERTADO_FIXO" ? "" : leftPad(cotas.codigoCota, 3)}${tipoLance === "SORTEIO" ?  "| Vendedor: "+cotas.codigoRepresentante : "- " + cotas.percentualLance.toFixed(4) + "% | Vendedor: "+cotas.codigoRepresentante}\n\n`
	)
		return (resposta)
	}

	function retiraLanceFixo(dados: [any]) {
		dados.filter(item => item.tipoLance !== "LANCE_OFERTADO_FIXO")

		console.log('teste se chegou aqui')
		console.log(dados)
		return dados
	}

	useEffect(() => {
		console.log('chegando aqui')
		if (filtros) {
			console.log('chegando aqui2')
			getAll(filtros)
			getAllItems(filtros.dataAssembleiaInicial, true)
		}
	}, [filtros])


	function consultaContratoImpressao() {
		const dataAtual = moment().format('DD/MM/YYYY HH:mm:ss')
		const pdf = new jsPDF("p", "mm", "a4");
		const nome = localStorage.getItem('@Gscon:user')?.toUpperCase()
		let countpage = 0;



		cotasAssembleiaListaCard.map(itemAssembleia => {
			itemAssembleia.grupos.map((grupos, index) =>
			(countpage === 0 ? "" : pdf.addPage(),
				countpage++,
				autoTable(pdf, {
					showHead: 'everyPage',
					theme: 'striped',
					head: [[`GRUPO - ${leftPad(grupos.codigoGrupo, 3)}`, 'Quantidade', `DATA ASSEMBLEIA ${formatData(itemAssembleia.dataAssembleia)}`]],
					body: ordernarTipoLance(grupos.tiposLance).map(tiposLance => tiposLance.tipoLance !== "LANCE_OFERTADO_FIXO" ?
						[`${tiposLance.tipoLance === "LANCE_OFERTADO_LIVRE" ? "LANCE LIVRE OFERTADOS" : tiposLance.tipoLance}`,
						tiposLance.lances.length,
						retornaLances(tiposLance.tipoLance, tiposLance.lances)
						/*tiposLance.lances.map((cotas, index) =>
						(
							`${tiposLance.tipoLance === "LANCE_OFERTADO_LIVRE" ? leftPad(index + 1,3) : tiposLance.tipoLance === "LANCE_OFERTADO_FIXO" ? "" : leftPad(cotas.codigoCota, 3)}${tiposLance.tipoLance === "SORTEIO" ? '' : "- " + cotas.percentualLance.toFixed(4) + "% | Vendedor: "+cotas.codigoRepresentante}\n`
						
						))
						]*/] : [
							'LANCE FIXO OFERTADOS', tiposLance.lances.length, 'Quantidade de lance fixo ofertado foi de ' + tiposLance.lances.length
							]),
					margin: { top: 27, bottom: 30, left: 10, right: 10 },
					willDrawPage: function (data) {
						pdf.setFontSize(8);
						pdf.addImage(logoGroscon, "JPEG", 10, 10, 50, 11);
						pdf.setFontSize(7);
						pdf.text(`USUÁRIO: ${nome}`, 200, 17, { align: 'right' });
						pdf.text(`DATA : ${dataAtual}`, 200, 12, { align: 'right' });
						pdf.setFontSize(13);
						pdf.setFont("arial", "bold")
						pdf.text("RESULTADO DE ASSEMBLEIA", 117, 15, { align: 'center' });


					},
					didDrawPage: function (data) {
						pdf.setFontSize(10);
						pdf.setFont("times", "normal");
						pdf.text(`Página ${pdf.getNumberOfPages()}`, 10, 285, { align: 'left' });
						pdf.text(ParceiroNome, 200, 285, { align: 'right' });
						pdf.setDrawColor(0, 0, 0);
						pdf.line(10, 280, 200, 280);
					},
					headStyles: { 
					    halign: 'center', 		
						fillColor: ParceiroCor },
					columnStyles: {
						0: { halign: 'center', fontSize: 8, cellWidth: 40,  },
						1: { halign: 'center', fontSize: 8, cellWidth: 30 },
						2: { halign: 'left', fontSize: 8, cellWidth: 120},
						3: { halign: 'center', fontSize: 8, cellWidth: 25 }, 4: { halign: 'center', fontSize: 8 }, 5: { halign: 'center', fontSize: 8 }, 6: { halign: 'center', fontSize: 8 }
					},


				}))
			)
		})

		window.open(pdf.output('bloburl'));
	}

	function leftPad(value: number, totalWidth: number) {
		var length = totalWidth - value.toString().length + 1;
		return Array(length).join('0') + value;
	};

	function formatData(x: string) {
		let dataAss = new Date(x)
		let DataFormatada = ((adicionaZero(dataAss.getDate() + 1)) + "/" + (adicionaZero(dataAss.getMonth() + 1)) + "/" + (adicionaZero(dataAss.getFullYear())))
		return (DataFormatada)
	}

	function adicionaZero(numero: number) {
		if (numero <= 9)
			return "0" + numero;
		else
			return numero;
	}

	function teste(x: DadosGrupo) {
		x.tiposLance.map(function (item) {
			
		}  )
		setDadosModal(x)
		handleOpenModal()
	}

	function quebrarLinha(n: number) {
		if (openModal) {
			console.log('ta batendo aqui')
			return (n % 4 === 0)

		}
	}

	function mostrar() {
		setVisible(visible === true ? false : true)
	}

	function mostrar2() {
		setVisible2(visible2 === true ? false : true)
	}


	return (
		<>
			<Container>
				<Row md={4} style={{ maxWidth: '1000px' }}>
					{cotasAssembleiaListaCard.map(itemAssembleia =>
						<>


							{itemAssembleia.grupos.map(itemGrupo =>
								<Col>
									<Card style={{ width: '13rem', height: '29rem', marginBottom: '10px' }}>
										<Card.Img style={{ width: '206px', height: '110px' }} variant="top" src={itemGrupo.codigoGrupo === 55 ? caminhao : itemGrupo.codigoGrupo === 56 ? imovel : carro} />
										<Card.Body>
											<Card.Title>
												<Typography gutterBottom variant="subtitle2" component="div">
													<Row>
														<Col md={9}>
															Data {formatData(itemAssembleia.dataAssembleia)}
														</Col>
														<Col md={3}>
															<div className="btnPesquisar1" onClick={() => teste(itemGrupo)}>+</div>

														</Col>


													</Row>



												</Typography>

												<Typography gutterBottom variant="h5" component="div">

													GRUPO {itemGrupo.codigoGrupo}
												</Typography>
											</Card.Title>
											<Card.Text>
												{itemGrupo.tiposLance.map(itemTipoContemplacao =>
													(itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_LIVRE" || itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_FIXO") ? <></> :
														<>
															<Typography gutterBottom component="div" style={{ marginTop: '12px' }}>
																{itemTipoContemplacao.tipoLance === "SORTEIO" ? itemTipoContemplacao.tipoLance : `LANCE ` + itemTipoContemplacao.tipoLance}
															</Typography>
															{itemTipoContemplacao.lances.map(itemCotas =>
																<>
																	<Typography variant="body2" sx={{ color: 'text.secondary' }}>
																		{leftPad(itemCotas.codigoCota, 3)}
																		{itemTipoContemplacao.tipoLance === 'SORTEIO' ? '' : ` - ${itemCotas.percentualLance.toFixed(4)}%`}
																	</Typography>
																</>
															)}
														</>
												)}
											</Card.Text>

										</Card.Body>
									</Card>
									<div style={{ width: 300, marginLeft: 30 }}>

									</div>
								</Col>
							)}
						</>
					)}
				</Row>
				{/* 
				<div className="fab">
					<button className="main13" onClick={consultaContratoImpressao}>
						<BsPrinterFill className="teste56" />
					</button>
				</div>*/}
			</Container>

			<Modal
				open={openModal}
				onClose={fecharModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				
			>
				<Box sx={style}>
					<Row>
						<Typography>
							<Tabelas1>
								<div className="titulo title1">
									<Typography style={{ border: "none", margin: "0" }} gutterBottom>
										<h5>RESULTADO DE ASSEMBLEIA</h5>
									</Typography>
								</div>
								<Table bordered responsive className="fonteTI">
									<thead style={{borderRightColor: '#f5f5f5', borderLeftColor: '#f5f5f5' }}>
										<tr>
											<td className="ac70 n tamanhoAC" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Grupo</td>
											<td className="ac80 n quebraLinha" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Data de Assembleia</td>
											<td className="ac90 n tamanhoAC" style={{ backgroundColor: '#f5f5f5', height:'10px' }}></td>
											<td className="ac100 n tamanhoAC" style={{ backgroundColor: '#f5f5f5', height:'10px' }}></td>
										</tr>
									</thead>
									<tbody style={{border: '#DEE2E6', borderRightColor: '#ffffff', borderLeftColor: '#ffffff'}}>
										<tr>
											<td className="ac70" style={{ color: "f5f5f5", textAlign: "center", alignItems: "center", verticalAlign: "middle", height: 40 }}>{dadosModal?.codigoGrupo} </td>
											<td className="ac80" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{formatData(dadosModal?.tiposLance[0].lances[0].dataAssembleia || "")}</td>
											<td className="ac90"></td>
											<td className='ac100 quebraLinha' style={{ color: '#ffffff', textAlign: "center", alignItems: "center", verticalAlign: "middle", borderRight: 'none', cursor: 'pointer'  }}>
														
																
																aa
												
														</td>
										</tr>
									</tbody>
								</Table>
							</Tabelas1>
						</Typography>
					</Row>










					<Typography id="modal-modal-description" border={'1px solid red'} sx={{ border: 'none', zIndex: 9999, pad: '0px' }} style={{borderColor:'#fffffff'}}>
						
												{dadosModal?.tiposLance.map(itemTipoContemplacao =>
									(itemTipoContemplacao.tipoLance === "SORTEIO") ?
										<>
											<Tabelas1>
												<Table bordered responsive className="fonteTI" style={{borderBottom: 'solid 1px #DEE2E6'}}>
													<thead style={{borderRightColor: '#f5f5f5', borderLeftColor: '#f5f5f5'}}>
													<tr>
															<td className="ac70 n" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Tipo</td>
															<td className="ac80 n quebraLinha" style={{ backgroundColor: '#f5f5f5' }}>Cotas Contempladas ({itemTipoContemplacao.lances.length})</td>
															<td className="ac90 n" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Vendedor</td>												
															<td className="ac100 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6' }}></td>
														</tr>
													</thead>
													<tbody style={{border: '#DEE2E6', borderRightColor: '#ffffff', borderLeftColor: '#ffffff'}}>
														{itemTipoContemplacao.lances.map((itemCotas, index) =>
														<tr style={{border: "#ffffff"}}>
															{index===0?<>
															<td rowSpan={itemTipoContemplacao.lances.length} className="ac70" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", height: '10px' }}>{itemTipoContemplacao.tipoLance === "SORTEIO" ? itemTipoContemplacao.tipoLance : `LANCE ` + itemTipoContemplacao.tipoLance} </td>
															</>:<></>}
															
															<td className='ac80' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", borderColor: '#ffffff' }}>
																
														
																	{leftPad(itemCotas.codigoCota, 3)}

								
																
															</td>
															<td className='ac90 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>
															
													
												

																 {itemCotas.codigoRepresentante}
															
														</td>
														
														<td className='ac100 quebraLinha' style={{ color: '#ffffff', textAlign: "center", alignItems: "center", verticalAlign: "middle", borderRight: 'none', cursor: 'pointer'  }}>
														
																
																aa
												
														</td>
														
														
															
														</tr>
														)}
													</tbody>
												</Table>
											</Tabelas1>
										</> : <></>
								)}
								{dadosModal?.tiposLance.map(itemTipoContemplacao =>
									(itemTipoContemplacao.tipoLance === "LIVRE") ?
										<>
											<Tabelas1>
												<Table bordered className="fonteTI" style={{borderBottom: 'solid 1px #DEE2E6'}}>
												<thead style={{borderRightColor: '#f5f5f5', borderLeftColor: '#f5f5f5' }}>
														<tr>
															<td className="ac70 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6', height:'10px' }}>Tipo</td>
															<td className="ac80 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6' }}>Cotas Contempladas ({itemTipoContemplacao.lances.length})</td>
															<td className="ac90 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6' }}>Vendedor</td>
															<td className="ac100 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6' }}></td>
														</tr>
													</thead>
													<tbody style={{border: '#DEE2E6', borderRightColor: '#ffffff', borderLeftColor: '#ffffff'}}>
													{itemTipoContemplacao.lances.map((itemCotas, index) =>
														<tr style={{border: "#ffffff"}} >
															{index===0?<>
															<td rowSpan={itemTipoContemplacao.lances.length} className="ac70 tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{`LANCE ` + itemTipoContemplacao.tipoLance} </td>
																													
																
															</>:<></>}
															
															<td className='ac80 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", borderRight: 'none'  }}>
																																

																	{leftPad(itemCotas.codigoCota, 3) + " - "}
																	{itemCotas.percentualLance.toFixed(4)}%
																
															</td>
															
															<td className='ac90 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", borderRight: 'none'  }}>
																											

																 {itemCotas.codigoRepresentante}

															
														</td>
														
														{index===0?<>
														<td onClick={() => mostrar()} rowSpan={itemTipoContemplacao.lances.length} className='ac100 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", borderRight: 'none', cursor: 'pointer'  }}>
														
																
																	{visible ? <FiArrowUp ></FiArrowUp> : <FiArrowDown></FiArrowDown>}
												
														</td>
														</>:<></>}
																
															
															
															
															
														</tr>)}

														{visible ? <>
															{dadosModal?.tiposLance.map(itemTipoContemplacao =>
															
																(itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_LIVRE") ?
																	<tr>
																		<td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", borderColor: '#ffffff', marginBottom: 10 }}>{`OFERTADOS `}({itemTipoContemplacao.lances.length})</td>
																		<td colSpan={3} className='quebraLinha' style={{textAlign: "center", alignItems: "center", verticalAlign: "middle", whiteSpace: "pre-wrap", borderColor: '#ffffff' }}>
																			<Row style={{paddingLeft: 10, marginBottom: 10, marginTop: 20}}>
																				<Col sm={1}>
																				</Col>
																				<Col sm={4}>
																				<b>% Lances</b>
																				</Col>
																				<Col sm={6}>
																				<div style={{marginLeft:40}} ><b>Vendedor</b></div>
																				</Col>
																			</Row>
																			
																			{itemTipoContemplacao.lances.map((itemCotas, index) =>
																			<>
																				<Row style={{paddingLeft: 15, borderColor: 'red'}}>
																					<Col sm={1} style={{display: 'grid', placeItems:'center', borderBottom: 'solid 0.5px', borderBottomColor: '#DEE2E6', alignItems: 'right'}} >
																					<div style={{display: 'grid', placeItems:'center', backgroundColor: '#133483', color: '#ffffff', borderRadius: '50%', width:'15px', height: '15px', marginTop: 2, marginBottom: 2, fontSize: 10}}>{leftPad(index + 1, 2)}</div>
																					</Col>
																					
																					<Col sm={4} style={{display: 'grid', placeItems:'center', borderBottom: 'solid 0.5px', borderBottomColor: '#DEE2E6'}}>
																					{itemCotas.percentualLance.toFixed(4)}%
																					</Col>
																					
																					<Col sm={6} style={{ display: 'grid', placeItems:'center', borderBottom: 'solid 0.5px', borderBottomColor: '#DEE2E6'}}>
																					<div style={{marginLeft:40}} >{itemCotas.codigoRepresentante}</div>
																					</Col>
																				</Row>
																				{index===itemTipoContemplacao.lances.length-1?<><div style={{marginTop: '10px'}}></div></>:<></>}
																				
																				
																			</>
																		)}</td><td style={{marginTop: 20}}></td>
																	</tr> : <></>)}</>:<></>}
															
																	
													</tbody>
												</Table>
											</Tabelas1>
										</> : <></>
								)}

								{dadosModal?.tiposLance.map(itemTipoContemplacao =>
									(itemTipoContemplacao.tipoLance === "FIXO") ?
										<>
											<Tabelas1>
												<Table bordered className="fonteTI" style={{width: '100%', borderBottom: 'solid 1px #DEE2E6'}}>
													<thead style={{borderRightColor: '#f5f5f5', borderLeftColor: '#f5f5f5' }}>
													<tr>
															<td className="ac70 n" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Tipo</td>
															<td className="ac80 n" style={{ backgroundColor: '#f5f5f5' }}>Cotas Contempladas ({itemTipoContemplacao.lances.length})</td>
															<td className="ac90 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6' }}>Vendedor</td>
															<td className="ac100 n" style={{ backgroundColor: '#f5f5f5', border: '#DEE2E6' }}></td>
														</tr>
													</thead>
													<tbody style={{border: '#DEE2E6', borderRightColor: '#ffffff', borderLeftColor: '#ffffff'}}>
														{itemTipoContemplacao.lances.map((itemCotas, index) =>
														<tr style={{border: "#ffffff"}}>
														
															{index===0?<>	
															<td rowSpan={itemTipoContemplacao.lances.length} className="ac70 tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{`LANCE ` + itemTipoContemplacao.tipoLance} </td>
															</>:<></>}
															<td className='ac80 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>
														
																
																

																	{leftPad(itemCotas.codigoCota, 3) + " - "}{itemCotas.percentualLance.toFixed(4)}% 

														
																	
																		
															

															
															
															</td>
															<td className='ac90 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>
															{itemCotas.codigoRepresentante}
															</td>
															{index===0?<>
															<td rowSpan={itemTipoContemplacao.lances.length} onClick={() => mostrar2()} className='ac100 quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", cursor: 'pointer' }}>
															{visible2 ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</td>
															</>:<></>}
															
															
														</tr>
														)}
														{visible2 ? <>
															{dadosModal?.tiposLance.map(itemTipoContemplacao =>
															(itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_FIXO") ?															
															<>
									
														<tr>
															<td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}></td>
															<td className='quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>Quantidade de Ofertas({itemTipoContemplacao.lances.length})</td>
														</tr>
														</>:<></>)}</>:<></>}
													</tbody>
												</Table>
											</Tabelas1>
										</> : <></>
								)}

								{dadosModal?.tiposLance.map(itemTipoContemplacao =>
									(itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_LIVREwee") ?
										<>
											<Tabelas1>
												<Table bordered hover responsive className="fonteTI">
													<thead style={{borderRightColor: '#f5f5f5', borderLeftColor: '#f5f5f5' }}>
														<tr style={{height:10}}>
															<td className="ac n tamanhoAC" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Tipo</td>
															<td className="ac n" style={{ backgroundColor: '#f5f5f5' }}>Lances livre ofertados ({itemTipoContemplacao.lances.length})</td>
															<td className="ac tam" style={{ backgroundColor: '#f5f5f5', cursor: 'pointer' }} onClick={() => mostrar()}>{visible ? <FiArrowUp></FiArrowUp> : <FiArrowDown></FiArrowDown>}</td>
														</tr>
													</thead>
													<tbody style={{border: '#DEE2E6', borderRightColor: '#ffffff', borderLeftColor: '#ffffff'}}>
														{visible ? <>
															<tr>
																<td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{`LANCE ` + itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_LIVRE" ? "LANCE LIVRE" : "LANCE LIVRE"} </td>
																<td className='quebraLinha' style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>{itemTipoContemplacao.lances.map((itemCotas, index) =>
																	<>

																		{leftPad(index + 1, 3) + " - "}
																		{itemCotas.percentualLance.toFixed(4)}% {itemTipoContemplacao.lances.length === index + 1 ? '' : quebrarLinha(index + 1) === true ? <br /> : ', '}
																	</>
																)}</td>
															</tr>

														</> : <></>}

													</tbody>
												</Table>
											</Tabelas1>
										</> : <></>
								)}

								{dadosModal?.tiposLance.map(itemTipoContemplacao =>
									(itemTipoContemplacao.tipoLance === "LANCE_OFERTADO_FIXOeerr") ?
										<>

											<Tabelas1>
												<Table bordered hover responsive className="fonteTI">
													<thead style={{borderRightColor: '#f5f5f5', borderLeftColor: '#f5f5f5' }}>
														<tr>
															<td className="ac n tamanhoAC" style={{ backgroundColor: '#f5f5f5', height:'10px' }}>Tipo</td>
															<td className="ac n" style={{ backgroundColor: '#f5f5f5' }}>Lances fixo ofertados ({itemTipoContemplacao.lances.length})</td>
														</tr>
													</thead>
													<tbody style={{border: '#DEE2E6', borderRightColor: '#ffffff', borderLeftColor: '#ffffff'}}>
														<tr>
															<td className="tamanhoAC" style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>{`LANCE FIXO`} </td>
															<td style={{ textAlign: "center", alignItems: "center", verticalAlign: "middle" }}>
																{itemTipoContemplacao.lances.length} LANCES OFERTADOS	</td>
														</tr>
													</tbody>
												</Table>
											</Tabelas1>

										</> : <></>
								)}




							

						
						<Form.Group style={{ display: 'flex', marginTop: '30px', justifyContent: 'flex-end' }} className="buttonsCargo">
							<Button style={{ backgroundColor: 'var(--blue)' }} onClick={fecharModal}>Fechar</Button>
						</Form.Group>

					</Typography>
				</Box>
			</Modal>

		</>
	)
}

export default Resultados
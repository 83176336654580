import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer } from './styles';
import { Button, Form } from 'react-bootstrap';
import api from '../../../services/api';

interface Cargo {
	id?: number,
	nome: string,
	ativo: boolean,
}

const FormCargo: React.FC = () => {

	const history = useHistory();
	const { id } = useParams() as any

	console.log('id', id)

	useEffect(() => {
		if (id !== undefined) {
			findCargo(id)
		}
	}, [])

	const [model, setModel] = useState<Cargo>({
		ativo: false,
		nome: ''
	})


	function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
		setModel({
			...model,
			[e.target.name]: e.target.value
		});
	}

	function handleChangeCheck(e: ChangeEvent<HTMLInputElement>) {
		setModel({
			...model,
			[e.target.name]: e.target.checked
		});
	}

	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
		e.preventDefault()
		if (id !== undefined) {
			const response = await api.put(`/v1/cargos/${id}`, { nome: model.nome, ativo: model.ativo, id: id })
		} else {
			const response = await api.post(`/v1/cargos/`, { nome: model.nome, ativo: model.ativo })
		}
		back();
	}

	async function findCargo(id: string) {

		const response = await api.get(`/v1/cargos/${id}`);
		setModel({
			nome: response.data[0].nome,
			ativo: response.data[0].ativo,
		})

	}

	function back() {
		history.goBack()
	}


	return (
		<>
			<Header />
			<Container>
				<CardContainer>
					<div className='title'><h2>Cargo Cadastro</h2></div>
					<Form className="form-border" onSubmit={onSubmit}>
						<Form.Group className="inputCargo">
							<Form.Label>Nome Cargo</Form.Label>
							<Form.Control

								type="text"
								name="nome"
								placeholder="Cargo"
								value={model.nome}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
							/>
						</Form.Group>
						{/*<Form.Group className="checkCargo">
							<Form.Check
								type="checkbox"
								label="Ativo"
								name="ativo"
								checked={model.ativo}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeCheck(e)}
							/>
						</Form.Group>*/}
						<Form.Group className="buttonsCargo">
							<Button onClick={back}>Cancelar</Button>
							<Button type="submit">Salvar</Button>
						</Form.Group>
					</Form>
				</CardContainer>
			</Container>
		</>
	)
}

export default FormCargo
import styled from "styled-components";
import { Col, Row } from "../styles";

export const Container = styled.div`

   //width: 1400px;
   margin: none;

   

   .labelCampo{
    font-size:12px;
    margin-bottom:8px;
   }

   .teste{
       align-items: right;
   }

   .labelBem{
     margin-bottom: 30px;
   }

   .botaoPesquisar{
     display: flex;
     //margin-right: 28px;
     float: right;
     margin-bottom: 30px;
   }

   .btnPesquisar{
     background-color: var(--blue);
     width: 120px;
     height: 45px;
     font-size: 20px;
   }

   .btnPesquisar1{
     color: var(--blue);
     background-color: transparent;
     font-size: 20px;
     border: none;
   }

   .inputNovo{
     width: 100%
   }

   .datePick{
        width: 100%;
        margin-top: 0px;
        margin-left: 7px;

        .MuiTextField-root{
            width: 100%;
            
        }

        .MuiFormLabel-filled{
            font-size: 16px;
            position: absolute;
            top: -5px;
            left: -12px;
        }

        .MuiInputLabel-outlined{
            font-size: 17px;
            position: absolute;
            top: 8px;
            left: -14px;
            
        }
        .MuiOutlinedInput-input{
            height: 24px;
            margin-top: 30px;
            padding: 0;
            padding-bottom: 3px;
        } 
        

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          //height: 24px;
          //margin-top: 30px;
          //padding: 0;
          //padding-bottom: 3px;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;

        }

        fieldset{
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-color: rgba(0, 0, 0, 0.43);

            legend{
                
                span{
                    font-size: 16px;
                }
            }
        }
    }

    .ativo{
      background-color: red; //var(--blue);
      border: solid red 2px;
    }


`;

export const Tabela = styled.div`
  table{
    display: table;
    border: 0;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;

  }

  td, th {
    border: 0;
    padding: .7em;
  
    text-align: center;
    display: table-cell;
    vertical-align: inherit;
    font-size: 13px;  
}

.testea{
     display: flex;
     margin-top: 50px;
     justify-content: flex-end;
     margin-right: 10px;
   }
`;

export const Tabelas1 = styled.div`
    max-width: 800px;
    font-size: 13px;
    //border-left: solid 0.5px;
    //border-left-color: #eeebeb;
    //border-right: solid 0.5px;
    //border-right-color: #eeebeb;
    //box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);

    .fundo{
      background-color: rgb(247,247,247);
    }

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

   .title1{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 800px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

   .title2{
      color: white;
      width: 100%;
      max-width: 800px;
      display: flex;
      align-items: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
      margin-bottom: 30px;
      justify-content: space-between;
   }

   .voltar1{
      font-size: 30px;
      cursor: pointer;
      margin-top: -30px;
    }

    .largura{
      width: 30px;
    }

    .semSeguro{
      font-size: 10px;
      color: #b03232;
    }

    .MuiSvgIcon-Root {
      color: var(--blue);
      background-color: #b03232;
    }

    .comSeguro{
      font-size: 10px;
      color: var(--blue);
    }

    .meses{
      border: none;
    }

    .nome{
      max-width: 30ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th, td{
      border: none;
    }

    td, th{
      white-space: nowrap;
      padding: 0;
    }

    .quebraLinha{
      white-space: nowrap;
    }

    .ac{
      text-align: center;
      align-items: center;
      vertical-align: middle;
    }

    .ac1{
      text-align: center;
      align-items: center;
      vertical-align: middle;
    }

    .ac2{
      text-align: left;
      align-items: center;
      vertical-align: middle;
    }

    .tamanhoAC{
      //width: 100px;
      height: 40px;
      padding: none;
    }

   
   

   .tabela{
     vertical-align: center;     
   }

   .celula{
    display: flex;
    align-items: center;
   }

   .del{
    cursor: pointer;
   }

   .linha{
    /* height: 60px; */
    vertical-align: center;
    border-bottom: none;
   }

   .esquerda{
    padding-left: 60px;
   }


   .esquerda1{
    padding-left: 20px;
   }

   .mouse{
     cursor: pointer;
   }

   .bord{
    background-color: #f8f8f8;
    //color: red;
   }

   .marg{
    margin-bottom: 10px;
   }

   .tam1{
    width: 50px;
   }

   .tam1i{
    width: 100px;
   }

   .tam2{
    width: 200px;
   }

   .tam2i{
    width: 210px;
   }

   .tam3{
    width: 700px;
   }

   .fontTI{
    font-size: 12px;
   }

   .n{
    font-weight: bold;
    font-size: 14px;
   }

   .tam{
    font-size: 14px;
   }

   .redm{
     display: flex;
     justify-content: space-around;
   }

   .testea{
     display: flex;
     margin-top: 20px;
     margin-bottom: 20px;
     justify-content: center;
     margin-right: 10px;
   }

   .testeb{
     display: flex;
     margin-top: 20px;
     margin-bottom: 20px;
     justify-content: flex-end;
     margin-right: 10px;
   }

   .paginacao{
     display: flex;
     justify-content: center;
     margin-top: 5px;
     margin-bottom: 5px;
   }
`;

export const MCol = styled(Col)`
  background-color: rgb(247,247,247);
  font-size: 13px;
`
export const MRow = styled(Row)`
  /* padding-left: 14px;  */
  padding-top: 10px; 
  /* padding-right: 14px;  */
  ${props => 
  props.btMargin ? `
  margin-bottom: 10px
  ` : ''}
`
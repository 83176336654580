import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer } from './styles';
import { Button, Form } from 'react-bootstrap';
import { ParceirosDados } from '../../../hooks/parceiro';

import api from '../../../services/api';
import { Parceiro } from '../../../interfaces/parceiros';

interface Modelo {
	id?: number,
	nome: string,
	abreviacao: string,
	arquivo?: File,
	cor?: string,
}

const FormParceiro: React.FC = () => {

	const history = useHistory();
	const { id } = useParams() as any
	const { getOne, parceiro, deleteParceiro } = ParceirosDados();

	console.log('id', id)
	console.log('parceiro', parceiro)

	useEffect(() => {
		if (id !== undefined) {
			getOne(id)
		}
	}, [])

	function teste() {
		console.log(parceiro)
	}

	const [model, setModel] = useState<Modelo>({
		nome: '',
		abreviacao: '',
		cor: ''
	})


	function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
		console.log(e)
		setModel({
			...model,
			[e.target.name]: e.target.value
		});
	}

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let files: FileList | null = e.currentTarget.files;
		if (files) {
			console.log(files[0] || 'null')
			setModel({
				...model,
				arquivo: files[0]
			});
		}
	};


	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
		e.preventDefault()
		console.log(model.arquivo?.arrayBuffer)
		let arquivoCarregado = new FormData()
		if (model.arquivo)
			arquivoCarregado.append('arquivo', model.arquivo, model.arquivo.name)
		arquivoCarregado.append('nome', model.nome||'')
		arquivoCarregado.append('abreviacao', model.abreviacao||'')
		arquivoCarregado.append('cor', model.cor||'')
		console.log(arquivoCarregado)
		const response = await api.post(`/v2/parceiros`, arquivoCarregado)
		console.log(response)
		back();
	}

	function back() {
		history.goBack()
	}

	function delParceiro(id:number){
		const response = deleteParceiro(id)
		console.log(response)
		history.push(`/parceiros/`)
	}


	return (
		<>
			<Header />
			<Container>
				<CardContainer>
					<div className='title'><h2 onClick={teste}>Parceiro Cadastro</h2></div>
					<Form className="form-border" onSubmit={onSubmit}>
						<Form.Group className="inputCargo">
							<Form.Label>Nome Parceiro</Form.Label>
							<Form.Control
								type="text"
								name="nome"
								placeholder="Nome Parceiro"
								value={parseInt(id) ? parceiro?.nome : model.nome}
								disabled={parseInt(id) ? true : false}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
							/>
						</Form.Group>
						<Form.Group className="inputCargo">
							<Form.Label>Abreviação Contrato</Form.Label>
							<Form.Control
								type="text"
								name="abreviacao"
								placeholder="Abreviação Contrato"
								value={parseInt(id) ? parceiro?.abreviacao : model.abreviacao}
								disabled={parseInt(id) ? true : false}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
							/>
						</Form.Group>
						<Form.Group className="inputCargo">
							<Form.Label>Logo</Form.Label>
							<Form.Control
								type="file"
								name="arquivo"
								placeholder="Logo"
								disabled={parseInt(id) ? true : false}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
							/>
						</Form.Group>
						<Form.Group className="inputCargo">
							<Form.Label>Cor Sistema</Form.Label>
							<Form.Control
								type="color"
								name="cor"
								placeholder="Cor Sistema"
								value={parseInt(id) ? parceiro?.cor : model.cor}
								disabled={parseInt(id) ? true : false}
								onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e)}
							/>
						</Form.Group>
						{parseInt(id) ?
							<Form.Group className="buttonsCargo">
								<Button onClick={()=>delParceiro(parseInt(id))}>Deletar</Button>
							</Form.Group>
							:
							<Form.Group className="buttonsCargo">
								<Button>Cancelar</Button>
								<Button type="submit">Salvar</Button>
							</Form.Group>
						}
					</Form>
				</CardContainer>
			</Container>
		</>
	)
}

export default FormParceiro
import logoImg from '../../assets/logo.svg';
import notificacoesImg from '../../assets/notificacoes.svg';
import sairImg from '../../assets/sair.svg';
import { MenuSideBar } from '../MenuSideBar';
import Button from '@mui/material/Button';
import { Container, Content, LabelHeader, LeftGroup, RightGroup } from './styles';
import { MenuHeader } from '../MenuHeader';
import { useAuth } from '../../hooks/auth';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ConsultaUsuario } from '../../interfaces/usuarioConsulta';
import { FiBell, FiDownload } from 'react-icons/fi';
import { AiOutlinePoweroff } from 'react-icons/ai';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';


import api from '../../services/api';
import { IconButton } from '@mui/material';

export function Header() {

  const nome = localStorage.getItem('@Gscon:user')
  const [usuarios, setUsuarios] = useState<ConsultaUsuario[]>([]);
  const [usuario, setUsuario] = useState<ConsultaUsuario>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ParceiroId = parseInt(localStorage.getItem('@Gscon:ParceiroID')||"9")
  const ParceiroCor = localStorage.getItem('@Gscon:ParceiroCor')
  const ParceiroImagem:string = localStorage.getItem('@Gscon:ParceiroImagem')||""
  const ParceiroContrato = localStorage.getItem('@Gscon:ParceiroContrato')
  let root = document.documentElement;

  root.style.setProperty('--blue', ParceiroCor)


  useEffect(() => {
    buscaUsuarios();
  }, [])

  /*seEffect(() => {
    buscaDadosUsuario();
  }, [usuarios])*/

  async function buscaUsuarios() {
    const { status, data } = await api.get('/v1/usuarios')
    let resultado:ConsultaUsuario[] = data
    const dadosUsuario = resultado.find(item => item.nome === nome);
    setUsuario(dadosUsuario)
    setUsuarios(data)

    console.log(usuario)
  }

  /*async function buscaDadosUsuario() {
    if(usuarios){
    
    }
  }*/

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);



  const { signOut } = useAuth();

  return (
    <>
      <Container style={{background:ParceiroCor||""}}>
        <LeftGroup>
          <MenuHeader />
          <Link to="/"><img className='img-fluid shadow-4' style={{ resize: 'vertical' }} src={ParceiroId?ParceiroImagem||logoImg:logoImg} alt="Gscon" /></Link>
          {
            windowSize.innerWidth < 600 ?
              <></> :
              <LabelHeader>
                <Typography> Bem vindo, {usuario?.dadoUsuario.nomePrincipal?.toUpperCase()} 
                {usuario?.dadoUsuario?.tipoPessoa==="PESSOA_FISICA"?usuario?.dadoUsuario.nomeSecundario?.toUpperCase():''}</Typography>
              </LabelHeader>
          }
        </LeftGroup>

        <RightGroup>
        <Tooltip title="Download de arquivos">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <FiDownload color='#ffffff' size={20} />
          </IconButton>
        </Tooltip>
          <IconButton sx={{margin: 0}}>
            <FiBell color='#ffffff' size={20} />
          </IconButton>
          <IconButton onClick={signOut} style={{zIndex: 10998}}>
            <AiOutlinePoweroff color='#ffffff' size={20}  onClick={signOut} style={{zIndex: 10999}} />
          </IconButton>
        </RightGroup>
      </Container>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
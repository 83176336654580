import React, { ChangeEvent, useState, useEffect, ReactElement } from "react";
import { ConsultaUsuario } from "../../interfaces/usuarioConsulta";
import { Container } from "./styles";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { Col, Row } from "react-bootstrap";


export function Menu(){
    const nome = localStorage.getItem('@Gscon:user')
    const [cargo, setCargo] = useState(0)
    const [usuario, setUsuario] = useState('0')

    const ParceiroId = localStorage.getItem('@Gscon:ParceiroID')
    const ParceiroCor = localStorage.getItem('@Gscon:ParceiroCor')
    const ParceiroImagem = localStorage.getItem('@Gscon:ParceiroImagem')
    const ParceiroContrato = localStorage.getItem('@Gscon:ParceiroContrato')

    useEffect(()=>{
        buscaUsuarios()
    },[])

    function buscaUsuarios() {
        api.get('/v1/usuarios').then((response) => {
          const users: ConsultaUsuario[] = response.data
          const dadosUsuario = users.find(item => item.nome === nome);
          const user: ConsultaUsuario | undefined = dadosUsuario
          setCargo(dadosUsuario?.dadoUsuario.cargo?.id||0)
          setUsuario(dadosUsuario?.dadoUsuario.codigo.toString()||'0')
          console.log('dados do usuario')
          console.log(dadosUsuario?.dadoUsuario.codigo)

        }).catch((error) => {
            console.log('Ocorreu erro consulta dado-usuario')
        })
    }



    return(
        <Container>
            <strong>Menu</strong>
            <Row>
            <Col>
            <Link to="/Vendas">
            <button>
                Vendas
            </button>
            </Link>
            </Col>
            <Col>
            <Link to="/consultaContrato">
            <button style={{background:ParceiroCor||""}} type="button">
                Consulta contratos
            </button>
            </Link>
            </Col>
            </Row>
            <Row>
            <Col>
            <Link to="/Simulacao">
            <button style={{background:ParceiroCor||""}} type="button">
                Simulação
            </button>
            </Link>
            </Col>
            <Col>
            <Link to="/contemplacoes">
            <button style={{background:ParceiroCor||""}} type="button">
                Resultado Assembleias
            </button>
            </Link>
            </Col>
            </Row>
            
            
            

            

            {cargo===3?<>
                
                <strong style={{marginTop: 20, color:ParceiroCor||""}}>BI</strong>
                <Row>
                <Col>
                <Link to="/BiVendas">
                <button style={{background:ParceiroCor||""}} type="button">
                    Vendas
                </button>
                </Link>
                </Col>
                <Col>
                <Link to="/BiContemplacao">
                <button style={{background:ParceiroCor||""}} type="button">
                    Contemplação
                </button>
                </Link>
                </Col>
                </Row>
                <Row>
                <Col>
                
                <Link to="/BiInadimplencia">
                <button style={{background:ParceiroCor||""}} type="button">
                    Inadimplência
                </button>
                </Link>
                </Col>
                </Row>            
            </>:<></>}
            {usuario==='002366'?<>
                <strong style={{marginTop: 20, color:ParceiroCor||""}}>BI</strong>
                <Link to="/BiVendas2366">
                <button style={{background:ParceiroCor||""}} type="button">
                    Vendas
                </button>
                </Link>
                <Link to="/BiInadimplencia2366">
                <button style={{background:ParceiroCor||""}} type="button">
                Inadimplência
                </button>
                </Link>

            
            </>:<></>}

           
        </Container>
    );
}
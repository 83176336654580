import React, { ChangeEvent, useEffect, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer, Tabelas, PrimeiraLetra } from './styles';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import api from '../../../services/api';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { ptBR } from 'date-fns/locale';
import TextField from '@mui/material/TextField';
import { AxiosError } from 'axios';
import { useToast } from '../../../hooks/toast';
import { LiberacaoAssembleias } from '../../../hooks/liberacaoAssembleia';
import { MdDelete } from 'react-icons/md';

interface Assembleia{
  dataAssembleia: string
}

const LiberacaoAssembleia: React.FC = () => {

  const history = useHistory();
	const { id } = useParams() as any
	const { addToast } = useToast();
	const [age, setAge] = React.useState<Date | null>(null);
	const [dataAss, setDataAss] = useState<string>('');
	const { getAll, deleteLiberacaoAssembleia, liberacaoAssembleiaLista } = LiberacaoAssembleias();

	useEffect(() => {
			getAll()
	}, [])

	async function deleteCota(id: number){
		await deleteLiberacaoAssembleia(id)
		getAll()

			addToast({
				type: 'error',
				title: 'Deletado!',
				description: 'Data deletada com sucesso!'
			})
	}


	async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    const dtAssembleia:Assembleia = {dataAssembleia: dataAss}
		e.preventDefault()
		try {
			const response = await api.post('/v2/liberacao-assembleias/', dtAssembleia)
			console.log(response)
			if (response.status === 201) {
				addToast({
					type: 'success',
					title: 'Dados Salvos',
					description: 'Dados foram salvos com sucesso!'
				})
				getAll()
        setDataAss('')
        setAge(null)
			} else {
				addToast({
					type: 'error',
					title: 'Erro ao tentar salvar!',
					description: 'Informe este erro ao TI do consórcio2!'
				})
			}
		} catch (err) {
			const error = err as AxiosError
			let erroMensagem = error.response?.data.developerMessage
			let verificaErro = erroMensagem?.slice(0, 27)
			if (verificaErro === '[Assembleia] não encontrada') {
				addToast({
					type: 'error',
					title: 'Erro data Assembleia!',
					description: 'Data de assembleia não tem grupos cadastrados, verifique a data!'
				})
			} else {
				addToast({
					type: 'error',
					title: 'Erro ao tentar salvar!',
					description: 'Informe este erro ao TI do consórcio!'
				})
			}

		}
	}

	function adicionaZero(numero: number) {
		if (numero <= 9)
			return "0" + numero;
		else
			return numero;
	}

	function back() {
		history.goBack()
	}

	const handleChangeDate = (newValue: Date | null) => {
		if (newValue != undefined) {
			setAge(newValue)
			let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
			setDataAss(DataFormatada)
		}
	};

	return (
		<>
			<Header />
			<Container>
			
				<CardContainer>
					<div className='title'><h2>Liberação Assembléia</h2></div>
					<Form className="form-border" onSubmit={onSubmit}>
          <div></div>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <DesktopDatePicker
                  label="Data da Assembleia"
                  inputFormat="dd/MM/yyyy"
                  value={age}
                  onChange={handleChangeDate}
                  className="teste"

                  renderInput={(params) => <TextField {...params}
                    sx={{ maxWidth: 220, alignItems: "right" }}
                    //InputLabelProps={{ shrink: age !== "" ? true : false }}
                  />
                  }
                />
              </LocalizationProvider>

						<Form.Group className="buttonsCargo">
							<Button type="submit">Adicionar</Button>
						</Form.Group>
					</Form>
				
        <Tabelas>
						<Table bordered hover className='tabela'>
							<thead>
								<tr>
									<th>Id</th>
									<th>Data Liberada</th>
                  <th>Deletar</th>


								</tr>
							</thead>
							<tbody>
								{liberacaoAssembleiaLista.map((item) =>
									<tr className="linha">

										<td>
											{item.id}
										</td>
										<td>
											{item.dataAssembleia}
										</td>
										<td>
											<td onClick={() => deleteCota(item.id)} className="del"><MdDelete size={20}></MdDelete></td>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</Tabelas>
          </CardContainer>
			</Container>
		</>
	)
}

export default LiberacaoAssembleia
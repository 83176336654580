import styled from 'styled-components';

const background = ["#c62828", "#ad1457", "#512da8", "#7b1fa2", "#0d47a1", "#0097a7", "#00897b", "#388e3c", "#455a64"]


export const Container = styled.div`

   display: flex;
   align-items: center;
   margin-top: 30px;

   .fab{
  display: flex ;
  position: fixed;
  bottom:10px;
  right:10px;
  align-items: center;
  justify-content: center;

}

.fab button.main{
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--blue);
  right: 0;
  bottom: 0;
  z-index: 20;
}

.fab button{
  cursor: pointer;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  background-color: var(--blue);
  border: none;
  box-shadow: 0 1px 5px rgba(0,0,0,.4);
  font-size: 24px;
  color: white;

  
    
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.fab button:focus{
  outline: none;
}

.fab button.main{
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--blue);
  right: 0;
  bottom: 0;
  z-index: 20;
}

.fab button.main:before{
  content: '⏚';
}

.fab ul{
  position:absolute;
  bottom: 0;
  right: 0;
  padding:0;
  padding-right:5px;
  margin:0;
  list-style:none;
  z-index:10;
  
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.fab ul li{
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: -30%;
  opacity: 0;
  
  -webkit-transition: .3s ease-out;
  -moz-transition: .3s ease-out;
  transition: .3s ease-out;
}

.fab ul li label{
  width: 100px;
  margin-right:10px;
  white-space: nowrap;
  display: block;
  margin-top: 10px;
  padding: 5px 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,.2);
  border-radius:3px;
  height: 30px;
  font-size: 16px;
  pointer-events: none;
  opacity:0;
  
  -webkit-transition: .2s ease-out;
  -moz-transition: .2s ease-out;
  transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus{
  outline: none;
  background-color: var(--blue);
  box-shadow: 0 3px 8px rgba(0,0,0,.5);
 }
 
.fab button.main:active:before,
.fab button.main:focus:before{
  content: '↑';
}

.fab button.main:active + ul,
.fab button.main:focus + ul{
  bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li{
  margin-bottom: 10px;
  opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label{
  opacity: 1;
}

   ul {
    list-style:none;
    padding-left:0;
}

.MuiCircularProgress-colorSecondary{
  color: red;
}

.teste56{
  margin-bottom: 5px
}

   .btnPesquisar{
     background-color: var(--blue);
     width: 120px;
     height: 45px;
     font-size: 20px;
     margin-left: 15px;
     margin-right: 15px;
     margin-bottom: 15px;
   }

   .btnPesquisar1{
     background-color: var(--blue);
     width: 20px;
     height: 20px;
     color: #ffffff;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 100%;
     cursor: pointer;


   }

   Form{
      label{
      color:red;
      font-size: 40px;
      }
   }

   .ac{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       color: #f5f5f5;
    }

   .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }

   input{
       width: 100%;
       height: 100%;
       border: none;
   }
`;


export const Teste = styled.div`
   display: flex;
   flex: 1;
   align-items: center;
   width: 50px;
`;

export const Section = styled.section`
   display: flex;
   
   margin-top: 48px;
   width: 1000px;
   

   strong {
       display: flex;
       flex-wrap: wrap;
       flex: 1;
       justify-content: right;
       color: #999591;
       font-size: 40px;
       line-height: 26px;
       border-bottom: 1px solid #797571;
       display: block;
       padding-bottom: 16px;
   }

`
export const Titulo = styled.div`
      display: flex;
      flex: 1;
      align-items: center;
`

export const Cargos = styled.div`
     display: flex;
     flex-direction: column;
     

     a{
        display: flex;
        justify-content: left;
        flex: 1;
        width: 1000px;
        align-items: center;
        padding: 16px 14px;
        border-radius: 10px;
        margin-top: 10px;
        position: relative;
        background: #969cb3;
        color: #f4ede8;
     }

     span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: #363f5f;
         font-size: 30px;
         width: 45px;
         height: 45px;

         border-radius: 50%;
         background: #f4ede8;
     }

     strong{
         margin-left: 20px;
     }

     input {
         margin-top: 15px;
     }
`
export const Tabelas = styled.div`
   max-width: 1000px;
   width: 100%;
   display: flex;
   flex-direction: column;
   overflow-x: hidden;

   .table-responsive{
      overflow-x: hidden;
   }

   .Mui-selected {
    background-color: #ffffff;
    border-bottom: solid 3px;
    border-color: var(--blue);
    font-weight: bold;
   }

   .tabela{
     vertical-align: center;
     box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);

     
   }



   .linha{
      /* height: 60px; */
      vertical-align: center;
      //text-align: center;
      //font-size: 13px;
   }

   .linha1{
      vertical-align: middle;
      font-size: 13px;
   }

   .linha2{
     text-align: center;
      ul{
      max-width: 450px;
      width: 100%;
      margin-left: 30%;
      margin-right: 30%;
      align-items: center;
      }
      li{
         float: left;
      }

   }

   .id{
      align-items: center;
      width: 200px;
   }

   .ativar{
      text-align: center;
   }
   
   .pesquisa{
      width: 100%;
      margin-top: 7px;
   }

   .status{
      margin-top: 15px;
   }

   .status1{
      font-size: 15px;
   }

   .tituloTabela{
      font-size: 15px;
   }

   .ativar{
      height: 10px;
   }

   .botoesPesquisar{
      align-items: flex-end;
   }

   .tr{
      tr:hover {background-color: none;}
   }

   tr:hover {
    background-color: #ffffff; /* Assuming you want the hover color to be white */
   }


   thead{
      //margin-bottom: 2px;
      tr, th{
         div{
            margin-bottom: 7px;
         }
         //height: 60px;
      }
   }

   .teste1{
      color: white;
      border: none;
   }



   .table-bordered>:not(caption)>*>* {
    border-width: 0 0;
   }

   .table>:not(:first-child) {
      border-top: 0px solid currentColor;
   }
   

   .MuiInput-root{
      width: 100%;
   }

   .PrimeiraLinha{
      height: 30px;
   }

   .PrimeiraLinha1{
      height: 42px;
   }

   .PrimeiroTH{
      border-bottom: red;
   }

   .datePick{
        width: 100%;
        margin-top: 1px;
        margin-left: 7px;

        .MuiTextField-root{
            width: 100%;
            
        }

        .MuiFormLabel-filled{
            font-size: 16px;
            position: absolute;
            top: -5px;
            left: -12px;
        }

        .MuiInputLabel-outlined{
            font-size: 17px;
            position: absolute;
            top: 8px;
            left: -14px;
            
        }
        .MuiOutlinedInput-input{
            height: 24px;
            margin-top: 20px;
            padding: 0;
            padding-bottom: 3px;
        } 
        

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          //height: 24px;
          //margin-top: 30px;
          //padding: 0;
          //padding-bottom: 3px;
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;

        }

        fieldset{
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-color: rgba(0, 0, 0, 0.43);

            legend{
                
                span{
                    font-size: 16px;
                }
            }
        }
    }

    .datePick1{
        width: 80%;
        //margin-top: 1px;
        margin-left: 7px;

        .MuiTextField-root{
            width: 80%;
            
        }

        .MuiFormLabel-filled{
            font-size: 15px;
            position: absolute;
            top: -5px;
            left: -12px;
        }

        .MuiInputLabel-outlined{
            font-size: 0px;
            position: absolute;
            top: -10px;
            left: -14px;
            
        }
        .MuiOutlinedInput-input{
            height: 24px;
            margin-top: 3px;
            padding: 0;
            padding-bottom: 3px;
        } 
        

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
          //height: 24px;
          //margin-top: 30px;
          //padding: 0;
          //padding-bottom: 3px;
        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
          border-width: 0;
          border-color: #1976d2;
          border-bottom-width: 2px;

        }

        fieldset{
            border-radius: 0;
            border-width: 0;
            border-bottom-width: 1px;
            border-color: rgba(0, 0, 0, 0.43);

            legend{
                
                span{
                    font-size: 15px;
                }
            }
        }
    }

    .buttonsCargo{
        display: flex;
        margin-top: 5px;
        margin-right: 10px;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);
            min-width: 100px;
            border: none;
        }

        .botaoContrario{
            margin-left: 10px;
            color: var(--blue);
            background-color: white;
            min-width: 100px;
            border: none;
        }
        
       
    }

    .icone{
       font-size: 22px;
    }

`;

export const Botao = styled.div`
   margin-left: 30px;

   .btn, .btn-primary{
      background: var(--blue);;
   }
 
`;

export const PrimeiraLetra = styled.div`
   vertical-align: center;   
   width: 40px;
   height: 40px;
   border-radius: 20px;
   font-size: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 20px;
   color: #ffffff;
`;

export const Tabelas1 = styled.div`
    max-width: 1100px;
    font-size: 13px;

    overflow-x: hidden; 

    .MuiCircularProgress-colorSecondary{
    color: red;
}

.icones{
    //margin-right: 5px;
    color: #616161;
    background: #ffffff ;
    border: none;
    //width: 40px;
     }

    //border-left: solid 0.5px;
    //border-left-color: #eeebeb;
    //border-right: solid 0.5px;
    //border-right-color: #eeebeb;
    //box-shadow: 2px 2px 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);


    .botoesSimulacao{
      box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);
      border: red solid;
    }

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

   .title1{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding-top: 10px;
   }

    .semSeguro{
        font-size: 10px;
        color: #b03232;
    }

    .MuiSvgIcon-Root {
        color: var(--blue);
      background-color: #b03232;
    }

    .comSeguro{
        font-size: 10px;
        color: var(--blue);
    }

    .meses{
        border: none;
    }

    .nome{
        max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
    }

    th, td{
        //border: none;

    }

    td, th{
       white-space: nowrap;
       padding: 0;

    }

    .quebraLinha{
        white-space: nowrap;
    }

    .ac{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       max-width: 170px;
       white-space: pre-wrap;
       height:'10px';
    }

    .ac10{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       max-width: 400px;
       white-space: pre-wrap;
    }

    .ac60{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       width: 600px;
       white-space: pre-wrap;
    }

    .ac70{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       width: 33%;
       white-space: pre-wrap;
    }

    .ac80{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       width: 33%;
       white-space: pre-wrap;
    }

    .ac90{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       width: 33%;
       white-space: pre-wrap;
    }

    .ac100{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       width: 40px;
       white-space: pre-wrap;
    }


    .ac20{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       max-width: 20px;
       white-space: pre-wrap;
    }
    
    .ac30{
       text-align: center;
       align-items: center;
       vertical-align: middle;
       width: 60px;
       white-space: pre-wrap;
    }

    .ac1{
       text-align: center;
       align-items: center;
       vertical-align: middle;
    }

    .ac2{
       text-align: left;
       align-items: center;
       vertical-align: middle;
    }

    .tamanhoAC{
       width: 140px;
       height: 40px;
       padding: none;
       
    }

   

   .tabela{
     vertical-align: center;
     
   }

   .celula{
      display: flex;
      align-items: center;

   }

   .del{
       cursor: pointer;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
      border-bottom: none;
   }


   .mouse{
     cursor: pointer;
   }

   .bord{
      background-color: #f8f8f8;
      //color: red;
   }

   .marg{
     margin-bottom: 10px;
   }

   .tam1{
     width: 50px;
   }

   .tam1i{
     width: 100px;
   }

   .tam2{
     width: 200px;
   }

   .tam2i{
     width: 210px;
   }

   .tam3{
     width: 700px;
   }

   .fontTI{
     font-size: 10px;
   }

   .n{
    font-weight: bold;
    font-size: 14px;
   }

   .tam{
    font-size: 14px;
    
   }

   .redm{
     display: flex;

     justify-content: space-around;
   }

   .testea{
     display: flex;
     margin-top: 50px;
     justify-content: flex-end;
     margin-right: 10px;

   }

   .paginacao{
     display: flex;
     justify-content: center;
     margin-top: 5px;
     margin-bottom: 5px;
   }

   

`;


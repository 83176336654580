export const dadosAssembleia = 
    {
        vencimento01: {
            '01':{
                dataAss: "23/01/2025",
                dataLot: "22/01/2025",
                dataVen: "21/01/2025"
            },
            '02':{
                dataAss: "24/02/2025",
                dataLot: "22/02/2025",
                dataVen: "21/02/2025"
            },
            '03':{
                dataAss: "24/03/2025",
                dataLot: "22/03/2025",
                dataVen: "21/03/2025"
            },
            '04':{
                dataAss: "24/04/2025",
                dataLot: "23/04/2025",
                dataVen: "22/04/2025"
            },
            '05':{
                dataAss: "26/05/2025",
                dataLot: "24/05/2025",
                dataVen: "21/05/2025"
            },
            '06':{
                dataAss: "24/06/2024",
                dataLot: "22/06/2024",
                dataVen: "21/06/2024"
            },
            '07':{
                dataAss: "25/07/2024",
                dataLot: "24/07/2024",
                dataVen: "22/07/2024"
            },
            '08': {
                dataAss: "26/08/2024",
                dataLot: "24/08/2024",
                dataVen: "21/08/2024",
            },
            '09': {
                dataAss: "26/09/2024",
                dataLot: "25/09/2024",
                dataVen: "23/09/2024"
            },
            '10': {
                dataAss: "24/10/2024",
                dataLot: "23/10/2024",
                dataVen: "21/10/2024"
            },
            '11':{
                dataAss: "25/11/2024",
                dataLot: "23/11/2024",
                dataVen: "21/11/2024"
            },
            '12':{
                dataAss: "19/12/2024",
                dataLot: "18/12/2024",
                dataVen: "18/12/2024"
            }
        },
        vencimento02: {
            '01':{
                dataAss: "13/01/2025",
                dataLot: "11/01/2025",
                dataVen: "10/01/2025"
            },
            '02':{
                dataAss: "13/02/2025",
                dataLot: "12/02/2025",
                dataVen: "10/02/2025"
            },
            '03':{
                dataAss: "13/03/2025",
                dataLot: "12/03/2025",
                dataVen: "10/03/2025"
            },
            '04':{
                dataAss: "14/04/2025",
                dataLot: "12/04/2025",
                dataVen: "10/04/2025"
            },
            '05':{
                dataAss: "15/05/2025",
                dataLot: "14/05/2025",
                dataVen: "12/05/2025"
            },
            '06':{
                dataAss: "13/06/2024",
                dataLot: "12/06/2024",
                dataVen: "10/06/2024"
            },
            '07':{
                dataAss: "15/07/2024",
                dataLot: "13/07/2024",
                dataVen: "10/07/2024"
            },
            '08': {
                dataAss: "15/08/2024",
                dataLot: "14/08/2024",
                dataVen: "12/08/2024",
            },
            '09': {
                dataAss: "12/09/2024",
                dataLot: "11/09/2024",
                dataVen: "10/09/2024"
            },
            '10': {
                dataAss: "17/10/2024",
                dataLot: "16/10/2024",
                dataVen: "10/10/2024"
            },
            '11':{
                dataAss: "14/11/2024",
                dataLot: "13/11/2024",
                dataVen: "11/11/2024"
            },
            '12':{
                dataAss: "12/12/2024",
                dataLot: "11/12/2024",
                dataVen: "10/12/2024"
            }
        }
    }

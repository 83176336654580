import React, { useRef, useState, useEffect, ChangeEvent, Children } from 'react';
import { Container, Tabelas, Tabelas1, Botao, PrimeiraLetra } from './styles';
import { Header } from '../../components/Header';
import { Table, Button, Form, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Boletoi } from '../../interfaces/boleto'
import { Remessa } from '../../interfaces/remessa'
import usePagination from '../../components/PaginationBoleto';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { FiClock } from 'react-icons/fi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Typography from '@mui/material/Typography';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { ptBR } from 'date-fns/locale';
import TextField from '@mui/material/TextField';

import moment from 'moment';
import Resultados from './resultados';

interface PropsResultado {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: PropsResultado) {
    const { children, value, index, ...other } = props;
  
  
    return (
          {children}
    );
  }

  const modelFiltros = {
    codigoGrupo: "",
    dataAssembleiaInicial: "",
    dataAssembleiaFinal: "",
    somenteDataLiberada: true
}




const Contemplacoes: React.FC = () => {
    moment.locale('pt-br');
    const history = useHistory();
    const { addToast } = useToast();
    const [assembleia, setAssembleia] = useState<string>('');
      const [age1, setAge1] = React.useState<Date | null>(null);
      const [age2, setAge2] = React.useState<Date | null>(null);
      const [filtros, setAlteraFiltros] = useState(modelFiltros);
      const [assembleiaFormatada, setAssembleiaFormatada] = useState<string>('')
      const [visualiza, setVisualiza] = React.useState(false)

      function adicionaZero(numero: number) {
        if (numero <= 9)
          return "0" + numero;
        else
          return numero;
      }


    const resultadoAssembleia = () => {
      console.log(filtros)
        if(filtros.dataAssembleiaFinal!=="" || filtros.dataAssembleiaInicial!==""){
          setVisualiza(true)
        }else{
          addToast({
            type: 'error',
            title: 'Verifique',
            description: 'Preencha Data Inicial e Final para buscar dados'
          });
        }
       

    }

     

    const handleChangeDate = (newValue: Date | null) => {
        if (newValue != undefined) {
          const agora = Date.now()
          const hoje = new Date(agora)
          const idade = Math.floor(Math.ceil(Math.abs(newValue.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
          const idadeF = idade + " ANOS";
          let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
          let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
          setAge1(newValue)
          setAlteraFiltros({ ...filtros, dataAssembleiaInicial: DataFormatada })
          //setAlteraDados({ ...DadosVenda, age1: newValue, dataNascimento: DataFormatada, dataNascimentoF: DataFormatada1, idade: idadeF })
        }
    
        if (newValue === null) {
          setAlteraFiltros({ ...filtros, dataAssembleiaInicial: '' })
          setAge1(null)
        }
      };
    
      const handleChangeDate2 = (newValue: Date | null) => {
        if (newValue != undefined) {
          const agora = Date.now()
          const hoje = new Date(agora)
          const idade = Math.floor(Math.ceil(Math.abs(newValue.getTime() - hoje.getTime()) / (1000 * 3600 * 24)) / 365.25);
          const idadeF = idade + " ANOS";
          let DataFormatada = ((adicionaZero(newValue.getFullYear())) + "-" + (adicionaZero(newValue.getMonth() + 1)) + "-" + (adicionaZero(newValue.getDate())))
          let DataFormatada1 = newValue.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
          setAge2(newValue)
          setAlteraFiltros({ ...filtros, dataAssembleiaFinal: DataFormatada })
          setAssembleiaFormatada(DataFormatada1)

          if (newValue === null) {
            setAlteraFiltros({ ...filtros, dataAssembleiaFinal: '' })
            setAge2(null)
          }
          //setAlteraDados({ ...DadosVenda, age1: newValue, dataNascimento: DataFormatada, dataNascimentoF: DataFormatada1, idade: idadeF })
        }}

   /* function handleSearchTodos(){
        resultadoAssembleia(1)
    }*/

    function limparTodos() {
        setAge1(null)
        setAge2(null)
        setAlteraFiltros(modelFiltros)
        setVisualiza(false)
        
      }

      function handleChangeSelected(e: ChangeEvent<HTMLSelectElement>) {
        setAlteraFiltros({ ...filtros, codigoGrupo: e.target.value })
        }



    return (
        <>
            <Header />
            <Container>
                <div className='title'><h2>Resultado da Assembleia</h2></div>
                <Tabelas>
                <div className="tabela">
                <Form className="form-border">
                <Row between style={{ marginTop: 23, display: 'flex' }}>
                   <Col xs={12} sm={4}>
                      <div className="inputNovo">
                        <Form.Group>
                          <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{fontSize: 17}}>
                            Grupo
                          </InputLabel>
                          <NativeSelect
                            style={{ width: "100%" }}
                            className="inputNovo"
                            onChange={handleChangeSelected}
                            value={filtros.codigoGrupo}
                            inputProps={{
                              name: 'codigoGrupo',
                              id: 'uncontrolled-native',
                            }}
                          >
                            <option value="">TODOS</option>
                            <option value="55">55</option>
                            <option value="56">56</option>
                            <option value="63">63</option>
                            <option value="65">65</option>
                            <option value="66">66</option>
                            <option value="67">67</option>
                            <option value="68">68</option>
                          </NativeSelect>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col min='140px' xs={12} sm={4}>
                      <div className='datePick'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                          <DesktopDatePicker
                            label="Data Inicio"
                            inputFormat="dd/MM/yyyy"
                            value={age1}
                            onChange={handleChangeDate}
                            className="teste"
                            renderInput={(params) => <TextField {...params} name="dataInicio"
                              sx={{ maxWidth: '100%', alignItems: "right", marginTop: 1 }}
                              InputLabelProps={{ shrink: filtros.dataAssembleiaInicial !== "" ? true : false }}
                            />
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                    <Col xs={12} sm={4}>
                      <div className='datePick'>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                          <DesktopDatePicker
                            label="Data Fim"
                            inputFormat="dd/MM/yyyy"
                            value={age2}
                            onChange={handleChangeDate2}
                            className="teste"

                            renderInput={(params) => <TextField {...params}
                              sx={{ maxWidth: '100%', alignItems: "right", marginTop: 1 }}
                              InputLabelProps={{ shrink: filtros.dataAssembleiaFinal !== "" ? true : false }}
                            />
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                  </Row>

                  <div className="botaoPesquisar" style={{ marginTop: "20px" }}>
                    <Button variant="primary" className="btnPesquisar" onClick={()=>resultadoAssembleia()} style={{ marginLeft: "15" }}>
                      <Typography variant="button" gutterBottom> Pesquisar</Typography>
                    </Button>
                    <Button variant="primary" className="btnPesquisar" onClick={limparTodos} style={{ marginRight: "15", marginBottom: "15" }}>
                      <Typography variant="button" gutterBottom>LIMPAR</Typography>
                    </Button>
                  </div>

                </Form>
                
              </div>
              </Tabelas>
              {visualiza?<Resultados filtros={filtros}/>:<></>}
            </Container>
            
            

        </>
    )
}

export default Contemplacoes
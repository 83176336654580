import api from './api';
import { LiberacaoAssembleia } from '../interfaces/liberacaoAssembleia';

const getAll = () => api.get<LiberacaoAssembleia[]>(`/v2/liberacao-assembleias`)

const deleteDataLiberada = (id: number) => api.delete(`/v2/liberacao-assembleias/${id}`)

export const GetCotasAssembleia = {
    getAll,
    deleteDataLiberada,
}
import { Container } from './styles';
import { Dashboard } from "../Dashboard";
import { Header } from "../Header";
import { Footer } from "../Footer"
import { MenuSideBar } from '../MenuSideBar';
import { useEffect } from 'react';
import api from '../../services/api';

export function Principal() {

  async function dadosParceiro() {
    const tokenParceiroId = localStorage.getItem('@Gscon:ParceiroID')
    const response = await api.get(`/v2/parceiros/${tokenParceiroId}`)
    console.log('chegou nos dados do parceiro')
    console.log(response.data[0])
    localStorage.setItem('@Gscon:ParceiroID',response.data[0].id)
    localStorage.setItem('@Gscon:ParceiroCor',response.data[0].cor)
    localStorage.setItem('@Gscon:ParceiroImagem',response.data[0].logoDownloadLink)
    localStorage.setItem('@Gscon:ParceiroContrato',response.data[0].abreviacao)
    localStorage.setItem('@Gscon:ParceiroNome', response.data[0].nome)
  }

  console.log('chegou nos dados dashboard')
  localStorage.setItem('@Gscon:457789', 'teste789');
  useEffect(() => {
    ('passou por aqui')
    dadosParceiro()
  }, [])

  return (
    <>
      <Container>
        <Header />
        <Dashboard />
        <Footer />
      </Container>
    </>
  );
}
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    margin: 20px;
    flex-direction: column;
    position:relative;
    align-items:center;

    



    .buttonsCargo{
        display: flex;
        margin-top: 35px;
        justify-content:flex-end;
        
        Button{
            margin-left: 10px;
            background-color: var(--blue);;
            min-width: 100px;
            border: none;
        }
        
       
    }

    .checkCargo{
        margin-top: 20px;
    }

    .form-border{
        border-width: 1px;
        border-color: #cccccc;
        border-style: solid;
        padding: 20px;
        border-radius: 2px;
    }
`;

export const CardContainer = styled.div`
    display:flex;
    flex:1;
    align-items:center;
    justify-content:space-between;
    max-width: 800px;
    flex-direction: column;
   
    top:-10px;
    position: absolute;
    background-color:#ffffff;
    z-index: 50;
    margin-top: 50px;

    .title{
      background-color: var(--blue);
      color: white;
      width: 100%;
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      height: 60px;
   }
`;

export const Tabelas = styled.div`
   width: 100%;
   display: flex;

   .tabela{
     vertical-align: center;
     box-shadow: 0 2px 4px rgb(0 0 0 / 15%), 0 2px 6px rgb(0 0 0 / 20%);
   }

   .celula{
      display: flex;
      align-items: center;
   }

   .linha{
      /* height: 60px; */
      vertical-align: center;
   }
   
   .pesquisa{
      width: 100%;
   }

   .pagination{
      display: flex;
      justify-content: center;
      border-top: red;
     margin-bottom: 5px;
   }

   thead{
      //margin-bottom: 2px;
      tr, th{
         div{
            margin-bottom: 7px;
         }
         //height: 60px;
      }
   }

   .Mui-selected {
    background-color: #ffffff;
    border-bottom: solid 3px;
    border-color: var(--blue);
    font-weight: bold;
   }



   .table-bordered>:not(caption)>*>* {
    border-width: 0 0;
   }

   .table>:not(:first-child) {
      border-top: 0px solid currentColor;
   }
   



`;

export const PrimeiraLetra = styled.div`
   vertical-align: center;   
   width: 40px;
   height: 40px;
   border-radius: 20px;
   font-size: 24px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 20px;
   color: #ffffff;
`;

import React, { useState } from 'react';
import { Header } from '../../../components/Header';
import { Container, TabelasLista, Botao, PrimeiraLetra } from './styles';
import { Table, Button, Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';


export default function CadastroContemplacoes() {

    const [page, setPage] = useState(1);
    const PER_PAGE = 10;
    //const count = Math.ceil(listaSearchTabelas.length / PER_PAGE);
    //const _DATA = usePagination(listaSearchTabelas, PER_PAGE);

    return(
    <>
        <Header />
        <Container>
        <div className='title'><h2>Cadastro Contemplações</h2></div>
        {/*
        <TabelasLista>
          <Table bordered hover className='tabela'>
            <thead>
              <tr>
                <th colSpan={5} ><TextField className='pesquisa' variant='standard' type="text" placeholder="Pesquisar" onChange={handleSearch} /></th>
              </tr>
            </thead>
            <tbody>

              {_DATA.currentData().map(function (item) {
                if (item.id !== undefined) {
                  return (
                    <tr className="linha">
                      <td
                        className="celula"
                        key={item.id}
                        ref={idTabela}
                        id={item.id.toString()}
                      >
                        <PrimeiraLetra style={{ backgroundColor: `${randomDarkRGB()}` }}>
                          {item.nome.substring(0, 1).toUpperCase()}
                        </PrimeiraLetra>
                        {item.nome.toUpperCase()}
                      </td>
                      <td className='opcoes'>
                        <Button variant="primary" onClick={() => editarTabela(item.id)} style={{ background: 'var(--blue)', border: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: "center" }}><FiEdit size={30}></FiEdit></Button>
                      </td>
                    </tr>
                  )
                }
              }

              )}
              <tr className="linha2">
                <td className='pagination'>
                  <Pagination count={count} page={page} onChange={handleChangePagination} size="large" />
                </td>
              </tr>
            </tbody>
          </Table>

          <Botao>
            <Button variant="primary" onClick={novaTabela} style={{ border: 'none', borderRadius: '50%', width: '50px', height: '50px', display: 'flex', alignItems: "center" }}><FiPlus size={30} /></Button>
          </Botao>
        </TabelasLista>
*/}

      </Container>
    </>
    )
}
import { useCallback, useState } from "react"
import { GetCotasAssembleia } from '../services/LiberacaoAssembleias';
import { LiberacaoAssembleia } from "../interfaces/liberacaoAssembleia";


const LiberacaoAssembleias = () => {
    const [liberacaoAssembleiaLista, setliberacaoAssembleiaLista] = useState<LiberacaoAssembleia[]>([]);

    const getAll = useCallback(async () => {
        const { status, data } = await GetCotasAssembleia.getAll();
        const ordenado = data.sort(function(a,b){return a.id > b.id ? -1: a.id < b.id? 1:0})


        if(status !== 200) throw new Error();

        setliberacaoAssembleiaLista(ordenado) 
    
    },[]);

    const deleteLiberacaoAssembleia = useCallback(async (id: number) => {
        const { status, data } = await GetCotasAssembleia.deleteDataLiberada(id);
        if(status !== 204) throw new Error();
        return status
    },[])

    return {
        liberacaoAssembleiaLista,
        deleteLiberacaoAssembleia,
        getAll,
    }
}

export { LiberacaoAssembleias }
import { useCallback, useState } from "react"
import { GetParceiros } from '../services/Parceiros';
import { Parceiro } from '../interfaces/parceiros';


const ParceirosDados = () => {
    const [parceiroLista, setParceiroLista] = useState<Parceiro[]>([]);
    const [parceiro, setParceiro] = useState<Parceiro>();
    
    const getAll = useCallback(async () => {
        const { status, data } = await GetParceiros.getAll();
        const ordenado = data.sort(function(a,b){return a.nome.toUpperCase() < b.nome.toUpperCase() ? -1: a.nome.toUpperCase() > b.nome.toUpperCase()? 1:0})


        if(status !== 200) throw new Error();

        setParceiroLista(ordenado) 
    
    },[]);

    const getOne = useCallback(async (id: number) => {
        const { status, data } = await GetParceiros.getOne(id);


        if(status !== 200) throw new Error();

        setParceiro(data[0])
    
    },[]);

    const deleteParceiro = useCallback(async (id:number)=> {
        const {status} = await GetParceiros.deleteParceiro(id);

        if(status !== 204) throw new Error();

        return ('Deletado com sucesso')

    },[])


    return {
        parceiroLista,
        getAll,
        getOne,
        parceiro,
        deleteParceiro
    }
}

export { ParceirosDados }
import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Etapa1_Plano } from './Etapa1_Plano';
import { Etapa2DadosCadastraisPF } from './Etapa2_DadosCadastrais_PF';
import { Etapa2DadosCadastraisPJ } from './Etapa2_DadosCadastrais_PJ';
import { Etapa3Endereco } from './Etapa3_Endereco';
import { Etapa4DadosProfissionaisPF } from './Etapa4_DadosProfissionais_PF';
import { Etapa4DadosSocioPJ } from './Etapa4_DadosSocio_PJ';
import { Etapa5DadosBancarios } from './Etapa5_DadosBancarios';
import { Etapa6CapacidadeFinanceiraPF } from './Etapa6_CapacidadeFin_PF';
import { Etapa6CapacidadeFinanceiraPJ } from './Etapa6_CapacidadeFin_PJ';
import { Etapa7Seguro } from "./Etapa7_Seguro";
import { Container, Formulario } from './styles';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DadosPreVendaTodos } from '../../hooks/dadosVenda';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}



function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function isMobile() {
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true; // está utilizando celular
  }
  else {
    return false; // não é celular
  }
}


const Vendas: React.FC = () => {
  const { dadosVenda, setDadosVenda, alteraDados } = DadosPreVendaTodos();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { idConsulta } = useParams() as any


  useEffect(() => {
    carregaDadosVenda()
  }, [])

  async function carregaDadosVenda() {
    if (idConsulta !== undefined) {
      const { data } = await api.get(`/v1/pre-vendas/${idConsulta}`)
      console.log('=================estamos aqui==============')
      const teste = JSON.parse(data[0].conteudo)
      setDadosVenda(teste)
    }
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  function teste11() {
    console.log(dadosVenda)
  }

  return (<>
    <Header />
    <Container>
      <div className="title"><Typography style={{ border: "none", margin: "0" }} variant="h4" gutterBottom><h2 onClick={teste11}>Nova Venda</h2></Typography></div>
      <Formulario>
        <Box
          sx={{
            bgcolor: 'background.paper',
            maxWidth: 1100,
            width: `${windowSize.innerWidth > 1176 ?
                `1100px`
                : `${isMobile() ?
                  windowSize.innerWidth - 20
                  : windowSize.innerHeight > 800 && windowSize.innerWidth < 1176 && windowSize.innerWidth > 855 ?
                    windowSize.innerWidth - 20
                    : windowSize.innerWidth - 36
                }px`}`
          }}>
          <AppBar position="static" >
            <Tabs
              value={value}
              onChange={handleChange}
              //indicatorColor= {'primary'}     
              //textColor="inherit"
              variant="scrollable"
              scrollButtons
            // allowScrollButtonsMobile

            //aria-label="full width tabs example"
            //style={{display:'flex'}}
            >
              <Tab label="Plano" color="red" {...a11yProps(0)} disabled={dadosVenda.etapa1} sx={{ maxWidth: '180px' }} />
              <Tab label="Dados Cadastrais" {...a11yProps(1)} disabled={dadosVenda.etapa2} sx={{ maxWidth: '180px' }} />
              <Tab label="Endereço" {...a11yProps(2)} disabled={dadosVenda.etapa3} sx={{ maxWidth: '180px' }} />
              <Tab label={dadosVenda.pessoa === "fisica" ? "Dados Profissionais" : "Dados Sócio"} {...a11yProps(3)} disabled={dadosVenda.etapa4} sx={{ maxWidth: '180px' }} />
              <Tab label="Dados Bancários" {...a11yProps(4)} disabled={dadosVenda.etapa5} sx={{ maxWidth: '180px' }} />
              <Tab label="Capacidade Financeira" {...a11yProps(5)} disabled={dadosVenda.etapa6} sx={{ maxWidth: '180px' }} />
              <Tab label="Seguro" {...a11yProps(6)} disabled={dadosVenda.etapa7} sx={{ maxWidth: '180px' }} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            disabled={true}
            onChangeIndex={handleChangeIndex}
            style={{ border: 'solid 1px #ECECEC', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;', padding: 'none' }}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <Etapa1_Plano DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              {dadosVenda.pessoa === "juridica" ?
                <Etapa2DadosCadastraisPJ DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} /> :
                <Etapa2DadosCadastraisPF DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} /> 
              }
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <Etapa3Endereco DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              {dadosVenda.pessoa === "juridica" ?
                <Etapa4DadosSocioPJ DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} /> :
                <Etapa4DadosProfissionaisPF DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} />
              }
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <Etapa5DadosBancarios DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} />
            </TabPanel>
            <TabPanel value={value} index={5} dir={theme.direction}>
              {dadosVenda.pessoa === "juridica" ?
                <Etapa6CapacidadeFinanceiraPJ DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} /> :
                <Etapa6CapacidadeFinanceiraPF DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} />
              }
            </TabPanel>
            <TabPanel value={value} index={6} dir={theme.direction}>
              <Etapa7Seguro DadosVenda={dadosVenda} setAlteraDados={setDadosVenda} setAlteraPagina={setValue} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Formulario>
    </Container>
    <Footer />
  </>)
}

export default Vendas
import React, { ChangeEvent, useEffect, useRef, useState, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../../components/Header';
import { Container, CardContainer, ViewSubMenu } from './styles';
import { Button, Form } from 'react-bootstrap';
import api from '../../../services/api';
import { UsuarioSistemaInterface } from '../../../interfaces/usuarioSistema';
import { Menu } from '../../../hooks/menu';
import { GruposUsuarios } from '../../../hooks/grupoUsuarios';
import Accordion from 'react-bootstrap/Accordion';
import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { GetUsuario } from '../../../services/Usuarios';
import { useToast } from '../../../hooks/toast';

interface MenuBInterface {
  id: number,
  menu: any,
  usuario: number
}

const FormUsuarios: React.FC = () => {
  const history = useHistory()
  const { id: idUser } = useParams() as any
  const { menuLista, getAll } = Menu();
  const { grupoUsuariosLista, getAll: getAllGrupos } = GruposUsuarios();
  const [inputAtivos, setInputAtivos] = useState(false)
  const [group, setGroup] = useState('')
  const checkRef = useRef(null)
  const [model, setModel] = useState<UsuarioSistemaInterface>(
    {
      nome: '',
      senha: '',
      dadoUsuario: { bloqueado: false },
      listMenuUsuario: []
    }
  )
  const [user, setUser] = useState();  
  const { addToast } = useToast();

  useEffect(() => {
    if (idUser !== undefined) {
      findUsuario(idUser)
    }
  }, [idUser])

  useEffect(() => {
    getAll();
    getAllGrupos();
  }, [getAll, getAllGrupos])

  useEffect(() => {
    handleChangeByGroup(group); // pode precisar de parseInt
  }, [])

  function handleChangeInputLogin(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value !== undefined) {
      setModel({
        ...model,
        [e.target.name]: e.target.value
      });
    }
  }

  function handleChangeInputSenha(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      [e.target.name]: e.target.value
    });
  }

  function handleChangeCheck(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      dadoUsuario: { bloqueado: e.target.checked }
    });
  }

  function handleChangeGroup(e: SelectChangeEvent) {
    setGroup(e.target.value);
    handleChangeByGroup(e.target.value)
  }

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {

    console.log(model)
    e.preventDefault()
    if (idUser !== undefined) {
      const response = await api.put(
        `/v1/dados-usuario/${model.id}`,
        {
          bloqueado: model.dadoUsuario.bloqueado,
          usuario: {
            id: model.id,
            nome: model.nome
          }
        }
      )
      if (response.status !== 200 && response.status !== 201) {
        alert(`Erro ao editar dados do usuário! - ${response.statusText}`)
        return;
      }
      history.push('/usuariosSistema');
    } else {
      const response = await api.post(
        `/v1/usuarios/`,
        {
          nome: model.nome,
          senha: model.senha,
          dadoUsuario: {
            bloqueado: model.dadoUsuario.bloqueado
          }
        }
      );

      if (response.status !== 200 && response.status !== 201) {
        alert(`Erro ao salvar dados do usuário! - ${response.statusText}`)
        return;
      } else {
        findUsuario(response.data[0].id)
      }
    }
  }

  async function findUsuario(id: string) {
    const response = await api.get(`/v1/usuarios/${id}`);
    const grupoUser = response.data[0].dadoUsuario.grupoUsuario ? response.data[0].dadoUsuario.grupoUsuario.id : -1;
    setModel({
      id: response.data[0].id,
      nome: response.data[0].nome,
      senha: response.data[0].senha,
      dadoUsuario: {
        id: response.data[0].dadoUsuario.id,
        bloqueado: response.data[0].dadoUsuario.bloqueado
      },
      listMenuUsuario: response.data[0].listMenuUsuario
    })
    setGroup(grupoUser);
    setInputAtivos(true)
    setUser(response.data[0].id)
  }

  function isChecked(subMenu: number): boolean {
    return model.listMenuUsuario?.some((item: { menu: any; }) => item.menu.id === subMenu)||false;
  }

  async function handleChangeCheckMenu(e: ChangeEvent<HTMLInputElement>) {
    const idSubMenu = Number(e.target.value);
    const menuDel: MenuBInterface = model.listMenuUsuario?.find(
      (item: { menu: any; }) =>
        item.menu.id === idSubMenu)
      || { id: 0, menu: {}, usuario: 0 };
    if (menuDel.id !== 0) {
      const response = await api.delete(`/v1/menus-usuario/${menuDel?.id}`)
    } else {
      const response = await api.post(`/v1/menus-usuario`,
        {
          usuario: {
            id: model.id
          },
          menu: {
            id: idSubMenu,
          }
        }
      )
    }
    findUsuario(idUser);
    e.preventDefault()
  }

  async function handleChangeByGroup(id: any) {
    const groupObj = grupoUsuariosLista?.find(item => item.id === id);
    if (groupObj !== undefined) {
      model.listMenuUsuario?.map(async (item: { id: any; } ) => {
        const responseDel = await GetUsuario.deleteMenuUsuario(item.id);
        console.log(responseDel.data)
      });
      const response = await api.put(
        `/v1/dados-usuario/${model.dadoUsuario.id}`,
        {
          usuario: {
            id: model.id
          },
          grupoUsuario: {
            id: groupObj.id,
          }
        }
      )
      groupObj.listMenuGrupoUsuario?.map(async item =>
        await api.post(`/v1/menus-usuario`,
          {
            usuario: {
              id: model.id
            },
            menu: {
              id: item.menu.id,
            }
          }
        )
      )
      findUsuario(idUser)
    }
  }

  function back() {
    history.goBack()
  }

  async function resetSenha(){
    const response = await GetUsuario.resetSenha(model.nome);
    if(response.status === 200 || response.status === 201){
      addToast({
        type: 'success',
        title: 'Enviado token para reset de senha!',
        description: 'Verifique seu email e siga as etapas de alteração ou recuperação da senha!'
      });
    }
  }

  function RenderMenu(menu: { id: string; }, indexMenu: number) {
    console.log(menuLista)
    console.log(menuLista[indexMenu].nome)
    console.log(menu.id)
    return (
      <Accordion.Item key={indexMenu} eventKey={menu.id}>
        <Accordion.Header>
          {menuLista[indexMenu].nome}
        </Accordion.Header>
        {menuLista[indexMenu].menusInferiores?.map(
          (item: any, index) =>
            RenderSubMenu(menu, indexMenu, item, index)
        )}
      </Accordion.Item>
    );
  }

  function RenderSubMenu(menu: { id: string; }, indexMenu: number, subMenu: { id: string; }, indexSubMenu: number) {
    return (
      <Accordion.Body key={indexSubMenu} style={{ padding: 0 }}>
        <ViewSubMenu>
          {menuLista[indexMenu]?.menusInferiores[indexSubMenu]?.nome}
          <Checkbox
            ref={checkRef}
            id={subMenu.id.toString()}
            value={subMenu.id}
            checked={isChecked(Number(subMenu.id))}
            onChange={handleChangeCheckMenu} style={{ alignSelf: 'flex-end' }}
          />
        </ViewSubMenu>
      </Accordion.Body>
    );
  }

 function teste(){
  console.log(menuLista)
 }

  return (
    <>
      <Header />
      <Container>
        <CardContainer>
          <div className='title'><h2 onClick={teste}>Cadastro Usuário Sistema</h2></div>
          <Form className="form-border" onSubmit={onSubmit}>
            <Form.Group className="inputCargo">
              <Form.Label>Login</Form.Label>
              <Form.Control
                disabled={inputAtivos}
                type="text"
                name="nome"
                placeholder="Login"
                value={model?.nome}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputLogin(e)}
              />
            </Form.Group>
            <Form.Group className="inputCargo">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                disabled={inputAtivos}
                type="password"
                name="senha"
                placeholder="Senha"
                value={model?.senha}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputSenha(e)}
              />
            </Form.Group>
            <Form.Group className="buttonsCargo">
              <Button onClick={() => resetSenha()}>Resetar senha</Button>
              <Button disabled={inputAtivos} onClick={back}>Cancelar</Button>
              <Button disabled={inputAtivos} type="submit">Salvar</Button>
            </Form.Group>
          </Form>
        </CardContainer>

        {user ?
          <CardContainer>
            <div className='title'><h2>Menus de Usuário Sistema</h2></div>
            <Form className="form-border">
              <Form.Group className="listGroup">
                <Form.Label>Grupo de Usuário</Form.Label>
                <Select
                  value={group.toString()}
                  onChange={handleChangeGroup}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ flex: 1, width: '100%', marginBottom: 15, height: 42 }}
                >
                  <MenuItem key={-1} value="-1">
                    <em>Selecione um grupo...</em>
                  </MenuItem>
                  {grupoUsuariosLista.map((item, index) =>
                    <MenuItem key={index} value={item.id}>{item.nome}</MenuItem>
                  )}
                </Select>
              </Form.Group>
              <Form.Group className="listMenus">
                <Form.Label>Menus</Form.Label>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px'
                  }}
                >
                  <Accordion defaultActiveKey={['0']} alwaysOpen style={{ flex: 1 }}>
                    {menuLista?.map(
                      (item: any, index) =>
                        item.menusInferiores ?
                          RenderMenu(item, index)
                          : <></>)}
                  </Accordion>
                </div>
              </Form.Group>

            </Form>
          </CardContainer>
          : <></>
        }
      </Container>
    </>
  )
}

export default FormUsuarios
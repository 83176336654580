import api from './api';
import { Parceiro } from '../interfaces/parceiros';

const getAll = () => api.get<Parceiro[]>('/v2/parceiros')

const getOne = (id: number) => api.get<Parceiro[]>(`/v2/parceiros/${id}`)

const deleteParceiro = (id: number) => api.delete(`/v2/parceiros/${id}`)

export const GetParceiros = {
    getAll,
    getOne,
    deleteParceiro
}